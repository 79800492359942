import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import clsx from "clsx";



export interface StackBoxProps {
  flex?: "min" | "expand";
  margin?: "0" | "1" | "2" | "3" | "4" | "5";
  padding?: "0" | "1" | "2" | "3" | "4" | "5";
  className?: string;
}


function Box(props: PropsWithChildren<StackBoxProps>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    flex = "min",
    margin = "0",
    padding = "0",
    className,
    children
  } = props;

  return (
    <div ref={ref} className={clsx([ "overflow-hidden", className ], {
      "flex-grow-0 flex-shrink-0": flex === "min",
      "flex-fill":                 flex === "expand",
      "m-0":                       margin === "0",
      "m-1":                       margin === "1",
      "m-2":                       margin === "2",
      "m-3":                       margin === "3",
      "m-4":                       margin === "4",
      "m-5":                       margin === "5",
      "p-0":                       padding === "0",
      "p-1":                       padding === "1",
      "p-2":                       padding === "2",
      "p-3":                       padding === "3",
      "p-4":                       padding === "4",
      "p-5":                       padding === "5"
    })}>
      {children}
    </div>
  );
}


export default forwardRef(Box);
