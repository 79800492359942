import { FormEvent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@/store";
import { SafetyEditorState, SafetyFormData } from "@/components/SafetyEditor/store/state";
import { safetyEditorSlice, updateFormField } from "@/components/SafetyEditor/store/slice";
import { useGetAssetTypeNomenclature, useGetSafetyElementaryTypes } from "@/store/api/webapi";
import { getSafetyType, getSelectedAssetType, getSelectedSafetyTypeCode } from "@/components/SafetyEditor/store/api";
import { SafetyType } from "@/components/SafetyEditor/store/types";



export const useSafetyEditorForm = () => {
  const dispatch = useDispatch();

  const { form, formErrors, isEditable } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    return {
      form:       state.form,
      formErrors: state.formErrors,
      isEditable: state.isEditable
    };
  });

  const registerFormField = (name: keyof SafetyFormData) => {
    const onChange = (event: FormEvent<HTMLInputElement>) => {
      dispatch(updateFormField({ name, value: event.currentTarget.value }));
    };

    return {
      name,
      value:        form[name],
      errorLabelId: formErrors[name],
      onChange,
      onBlur:       onChange
    };
  };

  return [ form, registerFormField, isEditable ] as const;
};

export const useSafetyType = () => {
  const { data: allSafetyElementaryTypes } = useGetSafetyElementaryTypes();

  const { selectedSafetyTypeId } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    return {
      selectedSafetyTypeId: state.selectedSafetyTypeId
    };
  });

  const selectedSafetyTypeCode = useMemo(() => {
    return getSelectedSafetyTypeCode(selectedSafetyTypeId, allSafetyElementaryTypes);
  }, [ selectedSafetyTypeId, allSafetyElementaryTypes ]);

  const safetyType = getSafetyType(selectedSafetyTypeCode);


  const isGuarantee = safetyType === SafetyType.GUARANTEE;
  const isCollateralAsset = safetyType === SafetyType.COLLATERAL_ASSET;
  const isCollateralMortgage = safetyType === SafetyType.COLLATERAL_MORTGAGE; // property
  const isCollateral = isCollateralMortgage || isCollateralAsset;

  return {
    selectedSafetyTypeId,
    selectedSafetyTypeCode,
    safetyType,
    isGuarantee,
    isCollateralAsset,
    isCollateralMortgage,
    isCollateral
  };
};

export const useAssetType = () => {
  const { data: allAssetTypes } = useGetAssetTypeNomenclature();

  const { selectedAssetTypeId } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    return {
      selectedAssetTypeId: state.selectedAssetTypeId
    };
  });

  const selectedAssetType = useMemo(() => {
    return getSelectedAssetType(selectedAssetTypeId, allAssetTypes);
  }, [ selectedAssetTypeId, allAssetTypes ]);

  return selectedAssetType;
};