import AccountCenter from "@/routes/_layout/Header/AccountCenter";
import ThemeSwitcher from "./ThemeSwitcher";



function Header(): React.JSX.Element {
  return (
    <header className="navbar navbar-expand-xxl p-3 bg-primary-alt">
      <div className="d-flex flex-fill align-items-center">

        <a className="navbar-brand" href="/">
          <img src="/favicon.svg" alt="SG" className="border border-white"/>
        </a>

        <div className="flex-fill">
          Control Credit Risk
        </div>
      </div>

      <div className="d-flex align-content-center">
        <ThemeSwitcher/>
        <AccountCenter/>
      </div>
    </header>
  );
}


export default Header;
