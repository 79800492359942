import { isDefined } from "@sgme/fp";
import {
  AssetInput,
  AssetTypeNomenclaturesResponse,
  CollateralResponse,
  GuaranteeResponse,
  SafetyElementaryTypesResponse,
  SafetyInput,
  TradeResponse
} from "@credit-control-risk/common";
import {
  COLLATERAL_ASSET_BASEL_CODES,
  COLLATERAL_PROPERTY_BASEL_CODES,
  GARANTIE_BASEL_CODES,
  SafetyChange,
  SafetyCreation,
  SafetyType,
  SafetyUpdate
} from "@/components/SafetyEditor/store/types";
import {
  FrequencyCollateralReevaluation,
  IndicatorTypeCoverage,
  LiquidityIndicator,
  SafetyEditorState,
  SafetyEditorStep,
  SafetyFormData,
  SubordinationRanking,
  ValuationMethod
} from "@/components/SafetyEditor/store/state";
import { formatJsonBoolean, parseJsonBoolean } from "@/utils/boolean";
import { formatJSONDate, parseJSONDate } from "@/utils/date";
import { numberToString, parseNumber } from "@/utils/number";
import { webapi } from "@/store/api/webapi";
import { AppDispatch } from "@/store";
import { checkMatchers, dateNotEmpty, FieldMatcher, hasError, numberNotEmpty, stringNotEmpty, yesOrNoNotEmpty } from "@/utils/matcher";
import { CountryCode } from "@/components/sg-ui/form/countries";



export const getSelectedSafetyTypeCode = (selectedSafetyTypeId: string | undefined, allSafetyElementaryTypes: SafetyElementaryTypesResponse | undefined) => isDefined(selectedSafetyTypeId)
  ? (
    allSafetyElementaryTypes?.types ?? []
  ).find(
    type => type.safetyElementaryTypeLabelCode === selectedSafetyTypeId
  )?.baselCategoryCode
  : undefined;

export const getSelectedAssetType = (selectedAssetTypeId: string | undefined, allAssetTypeNomenclaturesResponse: AssetTypeNomenclaturesResponse | undefined) => isDefined(selectedAssetTypeId)
  ? (
    allAssetTypeNomenclaturesResponse?.nomenclatures ?? []
  ).find(
    type => type.assetTypeCode === selectedAssetTypeId
  )
  : undefined;


export const getSafetyType = (selectedSafetyTypeCode: string | undefined): SafetyType | undefined => {
  if (!isDefined(selectedSafetyTypeCode)) {
    return undefined;
  }

  // @ts-ignore
  if (GARANTIE_BASEL_CODES.includes(selectedSafetyTypeCode)) {
    return SafetyType.GUARANTEE;
  }

  // @ts-ignore
  if (COLLATERAL_PROPERTY_BASEL_CODES.includes(selectedSafetyTypeCode)) {
    return SafetyType.COLLATERAL_MORTGAGE;
  }

  // @ts-ignore
  if (COLLATERAL_ASSET_BASEL_CODES.includes(selectedSafetyTypeCode)) {
    return SafetyType.COLLATERAL_ASSET;
  }

  return undefined;
};

export const canGoToPreviousStep = (state: SafetyEditorState) => state.currentStep !== SafetyEditorStep.SELECT_SAFETY_TYPE;

export const canGoToNextStep = (state: SafetyEditorState) => (
  (
    state.currentStep === SafetyEditorStep.SELECT_SAFETY_TYPE
    && isDefined(state.selectedSafetyTypeId)
    && isDefined(state.selectedSafetyType)
  )
  || (
    state.currentStep === SafetyEditorStep.SELECT_ASSET_TYPE
    && isDefined(state.selectedAssetTypeId)
  )
);

export const canSaveOrCreateSafety = (state: SafetyEditorState) => {
  // common matchers
  const allMatchers: Partial<Record<keyof SafetyFormData, FieldMatcher>> = {
    indicatorTypeCoverage:                        stringNotEmpty,
    validityStartDate:                            dateNotEmpty,
    validityEndDate:                              dateNotEmpty,
    administrativeValidityEndDate:                dateNotEmpty,
    legalValidityIndicator:                       yesOrNoNotEmpty,
    indicationCreditRiskMitigatoryEnforceability: yesOrNoNotEmpty,
    thirdPartySafetyProvider:                     stringNotEmpty
  };

  switch (state.form.indicatorTypeCoverage) {
    case "C":
      allMatchers.maxAmountCovered = numberNotEmpty;
      allMatchers.maxAmountCoveredCurrency = stringNotEmpty;
      break;

    case "F":
      allMatchers.fixeAmount = numberNotEmpty;
      allMatchers.fixeAmountCurrency = stringNotEmpty;
      break;

    case "P":
      allMatchers.percentageExposureCovered = numberNotEmpty;
      break;
  }

  // collateral
  if (state.selectedSafetyType === SafetyType.COLLATERAL_MORTGAGE || state.selectedSafetyType === SafetyType.COLLATERAL_ASSET) {
    allMatchers.subordinationRanking = stringNotEmpty;
    allMatchers.coverageAmountCollateralPrevailingOverSg = numberNotEmpty;
    allMatchers.coverageAmountCollateralPrevailingOverSgCurrency = stringNotEmpty;
    allMatchers.frequencyCollateralReevaluations = stringNotEmpty;

    allMatchers.liquidityIndicator = stringNotEmpty;
    allMatchers.valuationMethod = stringNotEmpty;
  }

  // collateral mortgage
  if (state.selectedSafetyType === SafetyType.COLLATERAL_MORTGAGE) {
    allMatchers.marketValue = numberNotEmpty;
    allMatchers.marketValueCurrency = stringNotEmpty;
  }

  // collateral asset
  // if (state.selectedSafetyType === SafetyType.COLLATERAL_ASSET) {
  //
  // }

  return (
    checkMatchers(allMatchers, state.form)
    && !hasError(state.formErrors)
    && (
      state.selectedSafetyType !== SafetyType.COLLATERAL_ASSET
      || state.selectedAssetReferences.length >= 1 // but only for product "credit card", the length can be > 1
    )
  );
};

export const safetyToSafetyFormData = (safety: GuaranteeResponse | CollateralResponse, safetyType: SafetyType): SafetyFormData => {
  if (safetyType === SafetyType.GUARANTEE) {
    const guarantee = safety as GuaranteeResponse;

    return {
      administrativeValidityEndDate:                parseJSONDate(guarantee.administrativeValidityEndDate) ?? "",
      applicableLawCountryCode:                     guarantee.applicableLawCountryCode as CountryCode ?? "",
      fixeAmount:                                   numberToString(guarantee.fixedAmount),
      fixeAmountCurrency:                           guarantee.fixedAmountCurrency ?? "",
      indicationCreditRiskMitigatoryEnforceability: parseJsonBoolean(guarantee.indicationCreditRiskMitigatoryEnforceability) ?? "",
      indicatorTypeCoverage:                        guarantee.indicatorTypeCoverage as IndicatorTypeCoverage ?? "",
      legalValidityIndicator:                       parseJsonBoolean(guarantee.indicatorCreditRiskMitigatoryActLegalValidity) ?? "",
      maxAmountCovered:                             numberToString(guarantee.maxAmountCovered),
      maxAmountCoveredCurrency:                     guarantee.maxAmountCoveredCurrency ?? "",
      percentageExposureCovered:                    numberToString(guarantee.percentageExposureCovered),
      thirdPartySafetyProvider:                     guarantee.thirdPartySafetyProvider ?? "",
      validityEndDate:                              parseJSONDate(guarantee.validityEndDate) ?? "",
      validityStartDate:                            parseJSONDate(guarantee.validityStartDate) ?? "",
      valuationMethod:                              "",
      coverageAmountCollateralPrevailingOverSg:     "",

      // not guarantee
      coverageAmountCollateralPrevailingOverSgCurrency: "",
      frequencyCollateralReevaluations:                 "",
      liquidityIndicator:                               "",
      marketValue:                                      "",
      marketValueCurrency:                              "",
      subordinationRanking:                             ""
    };
  }


  const collateral = safety as CollateralResponse;
  const allAssets = collateral.assetsResponse?.assets;
  const firstAsset = isDefined(allAssets) ? allAssets[0] : undefined;

  return {
    administrativeValidityEndDate:                    parseJSONDate(collateral.administrativeValidityEndDate) ?? "",
    applicableLawCountryCode:                         collateral.applicableLawCountryCode as CountryCode ?? "",
    coverageAmountCollateralPrevailingOverSg:         collateral.coverageAmountCollateralPrevailingOverSg ?? "",
    coverageAmountCollateralPrevailingOverSgCurrency: collateral.coverageAmountCollateralPrevailingOverSgCurrency ?? "",
    fixeAmount:                                       numberToString(collateral.fixedAmount),
    fixeAmountCurrency:                               collateral.fixedAmountCurrency ?? "",
    frequencyCollateralReevaluations:                 collateral.frequencyCollateralReevaluations as FrequencyCollateralReevaluation ?? "",
    indicationCreditRiskMitigatoryEnforceability:     parseJsonBoolean(collateral.indicationCreditRiskMitigatoryEnforceability) ?? "",
    indicatorTypeCoverage:                            collateral.indicatorTypeCoverage as IndicatorTypeCoverage ?? "",
    legalValidityIndicator:                           parseJsonBoolean(collateral.indicatorCreditRiskMitigatoryActLegalValidity) ?? "",
    liquidityIndicator:                               firstAsset?.liquidityIndicator as LiquidityIndicator ?? "",
    marketValue:                                      numberToString(firstAsset?.marketValue),
    marketValueCurrency:                              firstAsset?.marketValueCurrency ?? "",
    maxAmountCovered:                                 numberToString(collateral.maxAmountCovered),
    maxAmountCoveredCurrency:                         collateral.maxAmountCoveredCurrency ?? "",
    percentageExposureCovered:                        numberToString(collateral.percentageExposureCovered),
    thirdPartySafetyProvider:                         collateral.thirdPartySafetyProvider ?? "",
    subordinationRanking:                             collateral.subordinationRanking as SubordinationRanking ?? "",
    validityEndDate:                                  parseJSONDate(collateral.validityEndDate) ?? "",
    validityStartDate:                                parseJSONDate(collateral.validityStartDate) ?? "",
    valuationMethod:                                  firstAsset?.assetValuationMethod as ValuationMethod ?? ""
  };

};

export const isGuaranteeResponse = (safety: GuaranteeResponse | CollateralResponse): safety is GuaranteeResponse => safety.safetyGroupType === "1";
export const isCollateralResponse = (safety: GuaranteeResponse | CollateralResponse): safety is CollateralResponse => safety.safetyGroupType === "1";

export const saveSafety = async (rootId: string, safetyChange: SafetyChange, allAssets: TradeResponse[], dispatch: AppDispatch, safetyId?: string) => {
  const safetyPayload: SafetyInput = {
    administrativeValidityEndDate:                 formatJSONDate(safetyChange.administrativeValidityEndDate),
    indicationCreditRiskMitigatoryEnforceability:  formatJsonBoolean(safetyChange.indicationCreditRiskMitigatoryEnforceability),
    indicatorCreditRiskMitigatoryActLegalValidity: formatJsonBoolean(safetyChange.legalValidityIndicator),
    indicatorTypeCoverage:                         safetyChange.indicatorTypeCoverage,
    rootId,
    safetyGroupType:                               safetyChange.safetyTypeId,
    thirdPartySafetyProvider:                      safetyChange.thirdPartySafetyProvider,
    thirdPartySafetyRecipient:                     rootId,
    validityEndDate:                               formatJSONDate(safetyChange.validityEndDate),
    validityStartDate:                             formatJSONDate(safetyChange.validityStartDate)
  };

  if (safetyChange.indicatorTypeCoverage === "F") {
    safetyPayload.fixedAmount = parseNumber(safetyChange.fixeAmount);
    safetyPayload.fixedAmountCurrency = safetyChange.fixeAmountCurrency;
  }
  else if (safetyChange.indicatorTypeCoverage === "C") {
    // maxAmountCovered
    // maxAmountCoveredCurrency
  }

  if (safetyChange.indicatorTypeCoverage === "P" || safetyChange.indicatorTypeCoverage === "C") {
    safetyPayload.percentageExposureCovered = parseNumber(safetyChange.percentageExposureCovered);
  }

  const assetData: Partial<AssetInput> = {};

  if (safetyChange.safetyType === SafetyType.COLLATERAL_MORTGAGE || safetyChange.safetyType === SafetyType.COLLATERAL_ASSET) {
    safetyPayload.coverageAmountCollateralPrevailingOverSg = safetyChange.coverageAmountCollateralPrevailingOverSg;
    safetyPayload.coverageAmountCollateralPrevailingOverSgCurrency = safetyChange.coverageAmountCollateralPrevailingOverSgCurrency;
    safetyPayload.frequencyCollateralReevaluations = safetyChange.frequencyCollateralReevaluations;
    safetyPayload.subordinationRanking = safetyChange.subordinationRanking;

    assetData.assetTypeCode = "assetTypeId" in safetyChange ? safetyChange.assetTypeId : undefined;
    assetData.assetValuationMethod = safetyChange.valuationMethod;
    assetData.liquidityIndicator = safetyChange.liquidityIndicator;
  }

  if (safetyChange.safetyType === SafetyType.COLLATERAL_MORTGAGE) {
    assetData.countryLocation = safetyChange.applicableLawCountryCode ?? "LU";
    assetData.marketValue = parseNumber(safetyChange.marketValue);
    assetData.marketValueCurrency = safetyChange.marketValueCurrency;
  }

  const allAssetData = isSafetyUpdate(safetyChange)
    ? safetyChange.assets
    : safetyChange.assetSourceProductIds.map(sourceProductId => (
      { id: undefined, sourceProductId }
    ));

  if (safetyChange.safetyType === SafetyType.COLLATERAL_ASSET && allAssetData.length > 0) {
    safetyPayload.listAssets = allAssetData.map(({ id, sourceProductId }) => {
      const selectedAsset = allAssets.find(asset => asset.sourceProductId === sourceProductId);
      const assetProductType = selectedAsset?.typeLabel?.toUpperCase() as "WARRANTY" | "LOAN" | "CASH_ACCOUNT" | "CREDIT_CARD" | "OVERDRAFT" | "DEPOSIT" | "PROPERTY";

      return {
        ...assetData,

        id,
        assetRef: sourceProductId,
        assetProductType
      };
    });
  }
  else if (safetyChange.safetyType === SafetyType.COLLATERAL_MORTGAGE || safetyChange.safetyType === SafetyType.COLLATERAL_ASSET) {
    safetyPayload.listAssets = allAssetData.map(asset => (
      {
        ...asset,
        ...assetData,

        assetRef: asset.sourceProductId
      }
    ));
  }

  const newSafetyResponse = isDefined(safetyId)
    ? await dispatch(webapi.endpoints.saveSafety.initiate({ id: safetyId, safety: safetyPayload }))
    : await dispatch(webapi.endpoints.createSafety.initiate(safetyPayload));

  if ("data" in newSafetyResponse) {
    return newSafetyResponse.data.id!;
  }

  throw new Error("safety can't be saved (no data in the response)");
};

export const isSafetyCreation = (change: SafetyChange): change is SafetyCreation => !isDefined(change.safetyId);
export const isSafetyUpdate = (change: SafetyChange): change is SafetyUpdate => isDefined(change.safetyId);
