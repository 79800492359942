// export type DataTree = Record<string, DataNode>

export const ID_COLUMN_WIDTH = 200;
export const LEVEL_CONTAINER_PADDING = 16;

export type DataNode =
  | DataFolder
  | DataSelectableElement

export type DataFolder = {
  id: string
  name: string
  // count?: number
  children: DataNode[]
}

export type DataSelectableElement = {
  id: string
  name: string
}