import { AgGridReact } from "@ag-grid-community/react";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Navigate, useParams } from "react-router-dom";
import { isDefined } from "@sgme/fp";

import RootIDHeader from "@/components/RootIDHeader";
import StatusBadge from "@/components/StatusBadge";
import AuthorizationFilters from "@/routes/authorizations/components/AuthorizationFilters";
import { useGetAuthorizations } from "@/store/api/webapi";
import { search } from "@/utils/searcher";
import { PageHeader, PageMain, PageRoot } from "@/routes/_layout/page-styles";
import RouteTabs from "@/components/RouteTabs";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import { formatYesNo } from "@/utils/formatters";
import Loading from "@/components/sg-ui/Loading";
import ActionCell from "@/routes/authorizations/components/ActionCell";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";
import { GetProductNomenclatureLabel, useProductNumenclatureByCode } from "@/utils/hooks/useProductNumenclatureByCode";



function AuthorizationsRoute(): React.JSX.Element {
  const { rootId } = useParams() as {
    rootId: string
  };
  const intl = useIntl();

  const { data, error, isLoading } = useGetAuthorizations(
    { rootId, size: 200 },
    {
      // pollingInterval:           2 * 60 * 1000, // every 2 minutes
      refetchOnMountOrArgChange: true,
      skip:                      false
    }
  );

  const [ getProductNomenclatureLabel ] = useProductNumenclatureByCode();

  const [ query, setQuery ] = useState("");

  const [ selectedStatus, selectStatus ] = useState<string[]>([]);

  const loadedRows = data?.authorizations ?? [];

  const filteredRows = selectedStatus.length > 0
    ? loadedRows.filter(row => selectedStatus.includes(row.statusLabel))
    : loadedRows;

  const rows = search(query, filteredRows, SEARCHER_OPTION_KEYS);

  if (rootId === undefined) {
    return <Navigate to="/"/>;
  }

  if (isLoading) {
    return <Loading/>;
  }

  if (error) {
    return <ErrorPanel tips="reload+go-home"/>;
  }

  return (
    <PageRoot>
      <PageHeader>
        <RootIDHeader rootId={rootId} clientName="Dummy client"/>
        <RouteTabs/>
      </PageHeader>

      <PageMain className="vstack">
        <AuthorizationFilters
          query={query}
          onQueryChange={setQuery}
          status={selectedStatus}
          onStatusChange={selectStatus}
        />

        <div className="flex-fill">
          <div style={{ height: "100%", width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
            <AgGridReact
              rowData={rows}
              defaultColDef={DEFAULT_COLUMN_DEF}
              columnDefs={addHeaderIntlName("routes.authorizations.table.column", getTableColumns(rootId, getProductNomenclatureLabel), intl)}
              modules={[ ClientSideRowModelModule ]}
              enableCellTextSelection
            />
          </div>
        </div>
      </PageMain>
    </PageRoot>
  );
}


const getTableColumns = (rootId: string, getProductNomenclatureLabel: GetProductNomenclatureLabel) => (intl: IntlShape): ColDef[] => [
  {
    field:          "groupProductCodeId", // group product code label (only in french in the API, but in the user language for new authorization)
    minWidth:       270,
    valueFormatter: (params: ValueFormatterParams) => getProductNomenclatureLabel(params.value) ?? "unknowns"
  },
  {
    field:    "authorizationRef",
    minWidth: 190
  },
  {
    field:          "amount",
    type:           "rightAligned",
    minWidth:       100,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "currency",
    minWidth: 190
  },
  {
    field:          "exposureMaturityDate",
    minWidth:       190,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatDate(new Date(params.value), {
      day:   "2-digit",
      month: "short",
      year:  "2-digit"
    }) : ""
  },
  {
    field:    "increment",
    minWidth: 100
  },
  {
    field:    "statusLabel",
    minWidth: 150,
    // eslint-disable-next-line react/no-unstable-nested-components
    cellRenderer: (props: {
      value: "INACTIVE" | "ACTIVE"
    }) => <StatusBadge active={props.value === "ACTIVE"}/>
  },
  {
    field:    "currentExposure",
    minWidth: 190
  },
  {
    field:    "exposureCurrency",
    minWidth: 190
  },
  {
    field:          "protectionIndication",
    minWidth:       100,
    valueFormatter: (params: ValueFormatterParams) => formatYesNo(params?.value, intl)
  },
  {
    field:        "actions",
    minWidth:     190,
    cellRenderer: (props: {
      data: {
        authorizationRef: string,
        groupProductCodeId: string,
        contractLimitType: string, // = groupProductCodeLabel
        statusLabel: "INACTIVE" | "ACTIVE"
      }
    }) => <ActionCell
      rootId={rootId}
      authorizationId={props.data.authorizationRef}
      groupProductCode={props.data.groupProductCodeId}
      groupProductCodeLabel={props.data.contractLimitType} // yes, contract limit type = group product code label in the API
      active={props.data.statusLabel === "ACTIVE"}
    />
  }
];

const SEARCHER_OPTION_KEYS = [
  { name: "contractLimitType", weight: 2 }, // yes, contract limit type = group product code label in the API
  { name: "authorizationRef", weight: 2 },
  { name: "amount", weight: 1 },
  { name: "currency", weight: 1 },
  { name: "exposureMaturityDate", weight: 1 },
  { name: "increment", weight: 1 },
  { name: "statusLabel", weight: 1 },
  { name: "currentExposure", weight: 1 },
  { name: "exposureCurrency", weight: 1 },
  { name: "protectionIndication", weight: 1 }
];

export default AuthorizationsRoute;


