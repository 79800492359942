import { ISGWTConnectConfiguration } from "@sgwt/connect-core";



export interface AppConfig {
  sgwt_env: "development" | "devci" | "homologation" | "production";

  sgconnect: ISGWTConnectConfiguration;

  helpCenter: {
    contact: string
    subject: string
  };

  webapi: {
    url: string
  };

  matomo: {
    url: string
    siteid: string
  };
}


export function getAppConfig(): AppConfig {
  return window.sgmeConfiguration;
}


export function isProductionEnvironment(): boolean {
  return getAppConfig().sgwt_env === "production";
}


export function isDevEnvironment(): boolean {
  const env = getAppConfig().sgwt_env;
  return env === "development" || env === "devci";
}
