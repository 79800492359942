import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from "@/core/sgwtConnect/widgets";
import en from "@/locale/en.json";
import fr from "@/locale/fr.json";
import { Fragment, useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";



type SupportedLocale = "en" | "fr";
export const locales: Record<SupportedLocale, Record<string, string>> = {
  en,
  fr: { ...en, ...fr }
};


export function IntlProvider({ children }: { children: React.JSX.Element }) {
  const [ locale, setLocale ] = useState<SupportedLocale>("en");

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const bus = getWidgetBus();
    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, lang => {
        if (lang && Object.keys(locales).includes(lang.toLowerCase())) {
          setLocale(lang.toLowerCase() as SupportedLocale);
          document.querySelector("html")!.lang = lang;
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale="en"
      messages={locales[locale]}
      textComponent={Fragment}
    >
      {children}
    </ReactIntlProvider>
  );
}
