import styled from "@emotion/styled";
import HStack from "@/components/sg-ui/layout/HStack";
import Box from "@/components/sg-ui/layout/Box";
import { DataSelectableElement, ID_COLUMN_WIDTH } from "@/components/DataTreeSelector/types";



export type SelectableLevelProps = {
  element: DataSelectableElement
  selectedId: string | undefined
  toggleIdSelection: (id: string) => void
}


function SelectableLevel(props: SelectableLevelProps): React.JSX.Element {
  const { element, selectedId, toggleIdSelection } = props;

  const toggle = () => {
    toggleIdSelection(element.id);
  };

  return (
    <Styles>
      <HStack gap="1" onClick={toggle}>
        <input type="radio" checked={selectedId === element.id} readOnly/>
        <Box flex="expand">{element.name}</Box>
      </HStack>

      <aside onClick={toggle}>
        {element.id}
      </aside>
    </Styles>
  );
}


export default SelectableLevel;

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ID_COLUMN_WIDTH}px;
  padding: 8px 0 8px 12px;
  cursor: pointer;

  & > aside {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--interface-secondary, #6E777A);
  }
`;