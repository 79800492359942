import React, { FC, useEffect, useRef, useState } from "react";
import { isDefined } from "@sgme/fp";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";



/**
 * When a component need to initialize the redux state before being displayed, this HOC wraps
 * the component with another one that:
 * - use a hook to get the initialization action from the component props
 * - dispatch this action when it is ready
 * - display a spinner during the loading
 *
 * @example
 * const MyComponent = (props) => {
 *   // get data for redux which are initiated by the init hook with props
 *   const data = useSelector(selector)
 *
 *   return <div>...</div>
 * }
 *
 * const initMyComponent = (props) => {
 *   const { data } = useGetDataFromAPI(props)
 *
 *   return data === undefined
 *     ? undefined
 *     : {
 *       type: "init-action,
 *       payload: data
 *     }
 * }
 *
 * export default withInitReduxState(MyComponent, initMyComponent)
 *
 * To avoid the duplicate generation of the init action,
 * the init hook has the parameter "isAlreadyInitiated" which is true when
 * redux state for that component is already initiated.
 * It is an optional trick to return any value (undefined recommended).
 *
 * WARNING: all the internal hooks of the init hook have to be call.
 * Only the generated action can be avoided.
 */

export type GetInitReduxActionHook<Props> = (props: Props, isAlreadyInitiated: boolean) => AnyAction | undefined

export const withInitReduxState = <Props extends Record<string, unknown>>(WrappingComponent: FC<Props>, useInitAction: GetInitReduxActionHook<Props>, Spinner?: FC) => (props: Props) => {
  const dispatch = useDispatch();

  const initAction = useRef<AnyAction>();
  const [ isReady, setIsReady ] = useState(isDefined(initAction.current));

  const newInitAction = useInitAction(props, isReady);

  useEffect(() => {
    const canDispatchInitAction = initAction.current === undefined && isDefined(newInitAction);

    if (canDispatchInitAction) {
      initAction.current = newInitAction;
      dispatch(newInitAction);
      setIsReady(true); // force component refresh
    }
  }, [ dispatch, isReady, newInitAction ]);


  if (isReady) {
    return <WrappingComponent {...props} />;
  }

  return isDefined(Spinner)
    ? <Spinner/>
    : <span className="spinner blinker-sm mx-2" role="status"/>;
};