import styled from "@emotion/styled";
import classnames from "classnames";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { CardHeader, Collapse } from "reactstrap";



export type CollapsablePanelProps = {
  nameId: string
  counter?: number
  isOpened: boolean
  toggle: () => void
}


function CollapsablePanel(props: PropsWithChildren<CollapsablePanelProps>): React.JSX.Element {
  const { nameId, counter, isOpened, toggle, children } = props;

  return (
    <div className="card card-bordered mb-4">
      <StyledCardHeader className="card-header" onClick={toggle}>
        <div className="d-flex align-item-center">
          <span className={classnames("icon icon-sm me-2 text-secondary", { rotate90: isOpened })}>
            keyboard_arrow_right
          </span>
          <h3>
            <FormattedMessage id={nameId}/>
          </h3>
        </div>
      </StyledCardHeader>

      <Collapse isOpen={isOpened}>
        <CardBody className="card-body">
          {children}
        </CardBody>
      </Collapse>
    </div>
  );
}


const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;

  & > div > span {
    padding-top: 3px; // to align vertically 
    cursor: pointer;
  }
`;

const CardBody = styled.div`
  padding-top: 0;
`;

export default CollapsablePanel;