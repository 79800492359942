import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isDefined, isNotDefined } from "@sgme/fp";
import { useGetProductNomenclatures } from "@/store/api/webapi";
import { groupById } from "@/utils/fp";



export const useProductNumenclatureByCode = () => {
  const { data: productNomenclaturesResponse } = useGetProductNomenclatures();

  const intl = useIntl();

  const productNomenclatureByCode = useMemo(() => {
    const allNomenclatures = productNomenclaturesResponse?.nomenclatures ?? [];

    return groupById(allNomenclatures, nomenclature => String(nomenclature.productCode!));
  }, [ productNomenclaturesResponse ]);

  const getProductNomenclatureLabel = (stringCode: string | undefined) => {
    if (isNotDefined(stringCode)) {
      return undefined;
    }

    if (isNotDefined(productNomenclaturesResponse)) {
      // wait loading
      return "...";
    }

    // product code is not coherent in macgda (number AND string) :-(
    const code = String(Number(stringCode));

    const productNomenclature = productNomenclatureByCode[code];

    if (!isDefined(productNomenclature)) {
      return "";
    }

    return intl.locale === "fr"
      ? productNomenclature.productLabelFr
      : productNomenclature.productLabelEn;
  };

  return [ getProductNomenclatureLabel, productNomenclatureByCode ] as const;
};


export type GetProductNomenclatureLabel = ReturnType<typeof useGetProductNomenclatures>[0]

