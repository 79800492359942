import { getAppConfig } from "@/core/config";



export function HelpCenter(): React.JSX.Element {
  return (
    <aside>
      <sgwt-help-center
        application-id={import.meta.env.VITE_APP_ID as string}
        default-send-to={getAppConfig().helpCenter.contact}
        mail-subject={getAppConfig().helpCenter.subject}
        sg-connect-support="sg-connect-v2"
      />
    </aside>
  );
}
