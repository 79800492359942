import FormGroup from "@/components/sg-ui/form/FormGroup";
import NumberInput from "@/components/sg-ui/form/NumberInput";
import FormRow from "@/components/sg-ui/form/FormRow";
import SelectPicker, { SelectPickerOption } from "@/components/sg-ui/form/pickers/SelectPicker";
import CurrencyPicker from "@/components/sg-ui/form/pickers/CurrencyPicker";
import { useSafetyEditorForm } from "@/components/SafetyEditor/store/hooks";



function CollateralFormGroup() {
  const [ _, registerFormField, isEditable ] = useSafetyEditorForm();


  return (
    <FormGroup labelId="components.SafetyEditor.collateralForm.title">
      <FormRow
        labelId="components.SafetyEditor.collateralForm.subordinationRanking.label">
        <SelectPicker
          {...registerFormField("subordinationRanking")}
          options={COLLATERAL_SUBORDINATION_RANKINGS}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.collateralForm.subordinationRanking.placeholder"
        />
      </FormRow>

      <FormRow
        labelId="components.SafetyEditor.collateralForm.coverageAmountCollateralPrevailingOverSg.label">
        <NumberInput
          {...registerFormField("coverageAmountCollateralPrevailingOverSg")}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.collateralForm.coverageAmountCollateralPrevailingOverSg.placeholder"
        />
      </FormRow>

      <FormRow
        labelId="components.SafetyEditor.collateralForm.coverageAmountCollateralPrevailingOverSgCurrency.label">
        <CurrencyPicker
          {...registerFormField("coverageAmountCollateralPrevailingOverSgCurrency")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow
        labelId="components.SafetyEditor.collateralForm.frequencyCollateralReevaluations.label">
        <SelectPicker
          {...registerFormField("frequencyCollateralReevaluations")}
          options={COLLATERAL_REVALUATION_FREQUENCIES}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.collateralForm.frequencyCollateralReevaluations.placeholder"
        />
      </FormRow>
    </FormGroup>
  );
}


export default CollateralFormGroup;

const COLLATERAL_SUBORDINATION_RANKINGS: SelectPickerOption<"1" | "2" | "3" | "4" | "5">[] = [
  { value: "1", labelId: "components.SafetyEditor.collateralForm.subordinationRanking.1" },
  { value: "2", labelId: "components.SafetyEditor.collateralForm.subordinationRanking.2" },
  { value: "3", labelId: "components.SafetyEditor.collateralForm.subordinationRanking.3" },
  { value: "4", labelId: "components.SafetyEditor.collateralForm.subordinationRanking.4" },
  { value: "5", labelId: "components.SafetyEditor.collateralForm.subordinationRanking.5" }

];

const COLLATERAL_REVALUATION_FREQUENCIES: SelectPickerOption<"Q" | "H" | "B" | "M" | "T" | "S" | "A" | "2A" | "3A">[] = [
  { value: "Q", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Daily" },
  { value: "H", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Weekly" },
  { value: "B", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Bimonthly" },
  { value: "M", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Monthly" },
  { value: "T", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Quarterly" },
  { value: "S", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Half-yearly" },
  { value: "A", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Annually" },
  { value: "2A", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Every 2 years" },
  { value: "3A", labelId: "components.SafetyEditor.collateralForm.collateralRevaluationFrequencies.Every 3 years" }

];









