import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";
import { AssetTypeNomenclatureResponse } from "@credit-control-risk/common";
import DataTreeSelector from "@/components/DataTreeSelector";
import { AppState } from "@/store";
import { useGetAssetTypeNomenclature, useGetSafetyElementaryTypes } from "@/store/api/webapi";
import Loading from "@/components/sg-ui/Loading";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";
import { safetyEditorSlice, setAssetTypeQuery, toggleAssetTypeId } from "@/components/SafetyEditor/store/slice";
import { SafetyEditorState } from "@/components/SafetyEditor/store/state";
import { DataNode } from "@/components/DataTreeSelector/types";
import FormRow from "@/components/sg-ui/form/FormRow";
import { useSafetyType } from "@/components/SafetyEditor/store/hooks";
import VStack from "@/components/sg-ui/layout/VStack";
import Box from "@/components/sg-ui/layout/Box";



function AssetTypeSelector() {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { query, selectedAssetTypeId } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    const canCreate = false;

    return {
      query:               state.assetTypeQuery,
      selectedAssetTypeId: state.selectedAssetTypeId
    };
  });
  ;

  const { selectedSafetyTypeId } = useSafetyType();

  const { data: allSafetyElementaryTypes } = useGetSafetyElementaryTypes();

  const safetyTypeLabel = isDefined(allSafetyElementaryTypes)
    ? allSafetyElementaryTypes.types?.find(type => type.safetyElementaryTypeLabelCode === selectedSafetyTypeId)
    : undefined;

  const { data: allAssetTypes, isLoading: isAssetTypesLoading, error: assetTypesError } = useGetAssetTypeNomenclature();

  const assetTypes = useMemo(
    () => findDataNodesFromAssetTypes(query, intl.locale, allAssetTypes?.nomenclatures ?? []),
    [
      allAssetTypes,
      query,
      intl
    ]
  );

  if (isAssetTypesLoading) {
    return <Loading visibilityDelay={500}/>;
  }

  if (isDefined(assetTypesError)) {
    return <ErrorPanel
      titleId="components.SafetyEditor.assetTypeSelection.errorPanel.title"
      messageId="components.SafetyEditor.assetTypeSelection.errorPanel.message"
      tips="reload+go-home"
    />;
  }

  const onToggleSelectedAssetTypeId = (id: string) => {
    dispatch(toggleAssetTypeId(id));
  };

  const onQueryChange = (value: string) => {
    dispatch(setAssetTypeQuery(value));
  };

  return (
    <VStack fullHeight>
      <FormRow labelId="components.SafetyEditor.safetyForm.safetyType.code" className="my-2">
        {selectedSafetyTypeId}
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.safetyType.label" className="my-2">
        {intl.locale === "fr" ? safetyTypeLabel?.safetyElementaryTypeLabelFr : safetyTypeLabel?.safetyElementaryTypeLabelEn}
      </FormRow>

      <Box flex="expand">
        <DataTreeSelector
          queryLabelId="components.SafetyEditor.assetTypeSelection.queryLabel"
          query={query}
          onQueryChange={onQueryChange}
          data={assetTypes}
          nameLabelId="components.SafetyEditor.assetTypeSelection.nameLabel"
          idLabelId="components.SafetyEditor.assetTypeSelection.idLabel"
          selectedId={selectedAssetTypeId}
          toggleIdSelection={onToggleSelectedAssetTypeId}
          className="h-100"
        />
      </Box>
    </VStack>
  );
}


export default AssetTypeSelector;


//  █████╗ ███████╗███████╗███████╗████████╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ██╔══██╗██╔════╝██╔════╝██╔════╝╚══██╔══╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
// ███████║███████╗███████╗█████╗     ██║          ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
// ██╔══██║╚════██║╚════██║██╔══╝     ██║          ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
// ██║  ██║███████║███████║███████╗   ██║          ██║      ██║   ██║     ███████╗███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

const findDataNodesFromAssetTypes = (rawQuery: string, locale: string, inputs: AssetTypeNomenclatureResponse[]): DataNode[] => {
  const query = rawQuery.trim().toLowerCase();

  const selectedTypes = inputs.filter(type => (
    type.assetTypeLongLabelFr!.toLowerCase().includes(query)
    || type.assetTypeLongLabelEn!.toLowerCase().includes(query)
    || type.assetTypeCode!.includes(query)
  )); //
  const selectedTypeIds = selectedTypes.map(type => type.assetTypeCode!);

  const [ typeById, typeParentIdById, childrenTypesByParentId ] = inputs.reduce(
    (result, item) => {
      result[0][item.assetTypeCode!] = item;

      if (isDefined(item.assetTypeCodeParent)) {
        result[1][item.assetTypeCode!] = item.assetTypeCodeParent;

        const childIds = result[2][item.assetTypeCodeParent] ??= [];
        childIds.push(item.assetTypeCode!);
      }

      return result;
    },
    [ {}, {}, {} ] as [ Record<string, AssetTypeNomenclatureResponse>, Record<string, string | undefined>, Record<string, string[]> ]
  );

  const filteredSelectableTypes = query === ""
    ? inputs
    : selectSafetyElementaryTypes(inputs, selectedTypeIds, typeById, typeParentIdById, childrenTypesByParentId);

  const rootTypes = filteredSelectableTypes.filter(type => !isDefined(type.assetTypeCodeParent));

  const selectedChildrenTypesByParentId = filteredSelectableTypes.reduce(
    (result, item) => {
      if (isDefined(item.assetTypeCodeParent)) {
        const childIds = result[item.assetTypeCodeParent] ??= [];
        childIds.push(item.assetTypeCode!);
      }

      return result;
    },
    {} as Record<string, string[]>
  );

  return rootTypes.map(assetTypeToDataNode(locale, typeById, selectedChildrenTypesByParentId));
};


const assetTypeToDataNode = (locale: string, typeById: Record<string, AssetTypeNomenclatureResponse>, typesByParentId: Record<string, string[]>) => (type: AssetTypeNomenclatureResponse): DataNode => {
  const subtypes = typesByParentId[type.assetTypeCode!]?.map(id => typeById[id]);

  if (isDefined(subtypes)) {
    return {
      id:       type.assetTypeCode!,
      name:     locale === "fr" ? type.assetTypeLongLabelFr! : type.assetTypeLongLabelEn!,
      children: subtypes.map(assetTypeToDataNode(locale, typeById, typesByParentId))
    };
  }

  return {
    id:   type.assetTypeCode!,
    name: locale === "fr" ? type.assetTypeLongLabelFr! : type.assetTypeLongLabelEn!
  };
};

/**
 * criteria to find a type:
 * - the name of the time contains the query -> selectedIds
 * - all ancestors of selected types (selectedIds)
 * - all descendants of selected types (selectedIds if the elementary type contains other types)
 */
const selectSafetyElementaryTypes = (inputs: AssetTypeNomenclatureResponse[], selectedIds: string[], itemById: Record<string, AssetTypeNomenclatureResponse>, parentIdById: Record<string, string | undefined>, childIdsById: Record<string, string[]>): AssetTypeNomenclatureResponse[] => {
  // TODO: to avoid to scan many times the branches of the tree, keep a record of added IDs, and check if already added
  // so extract the IDs from the record keys
  // performance in the reduce with query = "c" (big search)
  // -   2 ms : x.forEach(...push)
  // -  37 ms : [ ...result, ...xxx ]
  // - 800 ms : for () { push }
  const foundIds = [
    ...selectedIds,
    ...selectedIds.reduce(
      (result, id) => {
        getAllAncestors(id, parentIdById).forEach(ancestorId => result.push(ancestorId));
        getAllDescendants(id, childIdsById).forEach(descendantId => result.push(descendantId));

        return result;
      },
      [] as string[]
    )
  ];

  const uniqFoundIds = [
    ...new Set(foundIds)
  ];

  return uniqFoundIds.map(id => itemById[id]);
};


const getAllAncestors = (id: string, parentIdById: Record<string, string | undefined>): string[] => {
  const parentId = parentIdById[id];

  if (parentId === undefined) {
    return [];
  }

  return [
    parentId,
    ...getAllAncestors(parentId, parentIdById)
  ];
};


const getAllDescendants = (id: string, childIdsById: Record<string, string[]>): string[] => {
  const childIds = childIdsById[id] ?? [];

  return childIds.reduce(
    (result, childId) => {
      getAllDescendants(childId, childIdsById).forEach(descendantId => result.push(descendantId));

      return result;
    },
    [ ...childIds ]
  );
};
