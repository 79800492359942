import { ChangeEvent, FocusEvent, FormEvent, ForwardedRef, forwardRef, MouseEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "@emotion/styled";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { isDefined } from "@sgme/fp";
import { HiddenInput, useUpdateValue } from "@/components/sg-ui/form/_utils";
import { search } from "@/utils/searcher";
import { useOutsideClick } from "@/utils/hooks/useOutsideClick";


// ███████╗███████╗██╗     ███████╗ ██████╗████████╗    ██████╗ ██╗ ██████╗██╗  ██╗███████╗██████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝    ██╔══██╗██║██╔════╝██║ ██╔╝██╔════╝██╔══██╗
// ███████╗█████╗  ██║     █████╗  ██║        ██║       ██████╔╝██║██║     █████╔╝ █████╗  ██████╔╝
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║       ██╔═══╝ ██║██║     ██╔═██╗ ██╔══╝  ██╔══██╗
// ███████║███████╗███████╗███████╗╚██████╗   ██║       ██║     ██║╚██████╗██║  ██╗███████╗██║  ██║
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝       ╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

export interface SelectPickerProps<Value extends string> {
  name: string;
  placeholderLabelId?: string;
  value?: Value;
  renderValue?: (selectedValue: Value) => React.JSX.Element;
  disabled?: boolean;
  editable?: boolean;
  options: SelectPickerOption<Value>[];
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export type SelectPickerOption<Value extends string> = {
  value: Value
  labelId: string
} | {
  value: Value
  label: string
}


function SelectPicker<Value extends string>(props: SelectPickerProps<Value>, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    placeholderLabelId,
    value:    defaultValue,
    disabled: isDisabled = false,
    editable: isEditable = true,
    options,
    onChange,
    onBlur,
    renderValue
  } = props;

  const [ innerRef, value, updateValue ] = useUpdateValue<Value | "">(externalRef, defaultValue ?? "");

  const [ isOpen, setIsOpen ] = useState(false);
  const [ query, setQuery ] = useState("");

  const intl = useIntl();

  const labelByValue = options.reduce(
    (result, option) => {
      // eslint-disable-next-line no-param-reassign
      result[option.value] = "label" in option ? option.label : intl.formatMessage({ id: option.labelId });
      return result;
    },
    {} as Record<Value, string>
  );

  const optionsWithLabel = options.map(option => (
    {
      value: option.value,
      label: labelByValue[option.value]
    }
  ));

  const foundOptions = query.length > 0
    ? search(query, optionsWithLabel, [ "value", "label" ])
    : optionsWithLabel;


  const toggle = () => {
    if (isOpen) {
      setQuery("");
    }

    setIsOpen(prevValue => !prevValue);
  };

  const onValueSelect = (selectedValue: Value | "") => {
    setQuery("");
    updateValue(selectedValue ?? "");
    setIsOpen(false);
    setQuery("");
  };

  const realRenderValue = (
    renderValue
    ?? (
      (selectedValue: Value) => <ValueView value={selectedValue} labelByValue={labelByValue}/>
    )
  );

  if (!isEditable) {
    return (
      isDefined(value) && value !== "" ? realRenderValue(value) : ""
    );
  }

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <SelectToggle
          value={value ?? ""}
          onValueChange={updateValue}
          renderValue={realRenderValue}
          isOpen={isOpen}
          onIsOpenChange={setIsOpen}
          query={query}
          onQueryChange={setQuery}
          placeholderLabelId={placeholderLabelId}
        />

        <DropdownMenu className="overflow-auto w-100" style={{ maxHeight: "200px" }}>
          {
            foundOptions.map(({ value: optionValue, label }) => (
              <MenuItem key={name} name={name} value={optionValue} onSelect={onValueSelect} renderValue={realRenderValue}/>
            ))
          }
        </DropdownMenu>
      </Dropdown>

      <HiddenInput
        ref={innerRef}
        name={name}
        type="text"
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
}


export default forwardRef(SelectPicker);


// ███████╗███████╗██╗     ███████╗ ██████╗████████╗    ████████╗ ██████╗  ██████╗  ██████╗ ██╗     ███████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝    ╚══██╔══╝██╔═══██╗██╔════╝ ██╔════╝ ██║     ██╔════╝
// ███████╗█████╗  ██║     █████╗  ██║        ██║          ██║   ██║   ██║██║  ███╗██║  ███╗██║     █████╗
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║          ██║   ██║   ██║██║   ██║██║   ██║██║     ██╔══╝
// ███████║███████╗███████╗███████╗╚██████╗   ██║          ██║   ╚██████╔╝╚██████╔╝╚██████╔╝███████╗███████╗
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝          ╚═╝    ╚═════╝  ╚═════╝  ╚═════╝ ╚══════╝╚══════╝

interface SelectToggleProps<Value extends string> {
  value: Value | "";
  onValueChange: (value: Value | "") => void;
  renderValue: (value: Value) => React.JSX.Element;

  isOpen: boolean;
  onIsOpenChange: (value: boolean) => void;

  query: string;
  onQueryChange: (value: string) => void;

  placeholderLabelId?: string;
}


function SelectToggle<Value extends string>(props: SelectToggleProps<Value>) {
  const { value, onValueChange, renderValue, isOpen, onIsOpenChange, query, onQueryChange, placeholderLabelId } = props;

  const queryRef = useRef<HTMLInputElement>(null);
  // TODO: bad use of useEffect ?
  useEffect(() => {
    queryRef.current?.focus();
  }, [ isOpen ]);

  const updateQuery = (event: ChangeEvent<HTMLInputElement>) => onQueryChange(event.target.value);

  const onQueryFocus = () => onIsOpenChange(true);

  const closeMenu = () => {
    onIsOpenChange(false);
    onQueryChange("");
  };

  useOutsideClick(queryRef, closeMenu);

  const onClearCode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onValueChange("");
    closeMenu();
  };

  if (isOpen) {
    return (
      <StyledDropdownToggle caret tag="div">
        <input
          ref={queryRef}
          type="text"
          value={query}
          onChange={updateQuery}
          onFocus={onQueryFocus}
          className="w-100 form-control"
        />
      </StyledDropdownToggle>
    );
  }

  return (
    <StyledDropdownToggle caret tag="div">
      <div className="d-flex form-control pe-0">
        <div className="flex-grow-1 d-flex justify-content-start align-items-center badges-container">
          {
            value !== ""
              ? renderValue(value)
              : <span className="text-secondary">
              {
                isDefined(placeholderLabelId)
                  ? <FormattedMessage id={placeholderLabelId}/>
                  : "Select a value"
              }
            </span>
          }
        </div>

        <div className="d-flex flex-grow-0 align-items-center align-self-start">
          {
            value !== "" && (
              <button aria-label="Clear picker" type="button"
                      className="d-flex flex-center btn-md btn-icon btn btn-flat cancel-button"
                      onClick={onClearCode}
              >
                <em className="icon" aria-hidden="true">cancel</em>
              </button>
            )
          }

          <button type="button" aria-label="Open picker list"
                  className="btn-icon d-flex flex-center btn-md btn btn-flat">
            <em className="icon" aria-hidden="true">arrow_drop_down</em>
          </button>
        </div>
      </div>
    </StyledDropdownToggle>
  );
}


const StyledDropdownToggle = styled(DropdownToggle)`
  .cancel-button {
    visibility: hidden !important;
  }

  &:hover .cancel-button {
    visibility: visible !important;
  }

  &::after {
    display: none;
  }
`;

// ███╗   ███╗███████╗███╗   ██╗██╗   ██╗    ██╗████████╗███████╗███╗   ███╗
// ████╗ ████║██╔════╝████╗  ██║██║   ██║    ██║╚══██╔══╝██╔════╝████╗ ████║
// ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║    ██║   ██║   █████╗  ██╔████╔██║
// ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║    ██║   ██║   ██╔══╝  ██║╚██╔╝██║
// ██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝    ██║   ██║   ███████╗██║ ╚═╝ ██║
// ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝     ╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝

interface MenuItemProps<Value extends string> {
  name: string;
  value: Value;
  onSelect: (newValue: Value) => void;
  renderValue: (selectedValue: Value) => React.JSX.Element;
}


function MenuItem<Value extends string>(props: MenuItemProps<Value>) {
  const { name, value, onSelect, renderValue } = props;

  return (
    <DropdownItem
      key={`${name}-${value}`}
      id={`${name}-${value}`}
      toggle={false}
      onClick={() => onSelect(value)}
    >
      {renderValue(value)}
    </DropdownItem>
  );
}


// ███████╗████████╗██████╗ ██╗███╗   ██╗ ██████╗     ██╗   ██╗ █████╗ ██╗     ██╗   ██╗███████╗    ██╗   ██╗██╗███████╗██╗    ██╗
// ██╔════╝╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝     ██║   ██║██╔══██╗██║     ██║   ██║██╔════╝    ██║   ██║██║██╔════╝██║    ██║
// ███████╗   ██║   ██████╔╝██║██╔██╗ ██║██║  ███╗    ██║   ██║███████║██║     ██║   ██║█████╗      ██║   ██║██║█████╗  ██║ █╗ ██║
// ╚════██║   ██║   ██╔══██╗██║██║╚██╗██║██║   ██║    ╚██╗ ██╔╝██╔══██║██║     ██║   ██║██╔══╝      ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
// ███████║   ██║   ██║  ██║██║██║ ╚████║╚██████╔╝     ╚████╔╝ ██║  ██║███████╗╚██████╔╝███████╗     ╚████╔╝ ██║███████╗╚███╔███╔╝
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝       ╚═══╝  ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚══════╝      ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

interface ValueViewProps<Value extends string> {
  value: Value;
  labelByValue: Record<Value, string>;
}


function ValueView<Value extends string>(props: ValueViewProps<Value>) {
  const { value, labelByValue } = props;

  return (
    <div className="d-flex gap-2 my-1 w-100 align-items-center">
      {labelByValue[value] ?? "unknown"}
    </div>
  );
}

