import { FormattedMessage } from "react-intl";
import React, { MouseEvent } from "react";
import { CategorySelectionMode } from "@/components/CategoryFilter/types";



export type CategoryButtonProps = {
  labelId: string
  categoryId: string
  selectedCategoryIds: string[]
  mode: CategorySelectionMode
  onChange: (selectedCategoryIds: string[]) => void
}

export type Category = {
  labelId: string
  id: string
}


function CategoryButton(props: CategoryButtonProps): React.JSX.Element {
  const { labelId, categoryId, selectedCategoryIds, mode, onChange } = props;

  const isSelected = selectedCategoryIds.includes(categoryId);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isSelected && (
      mode !== CategorySelectionMode.EMPTY_NOT_ALLOWED || selectedCategoryIds.length > 1
    )) {
      onChange(selectedCategoryIds.filter(id => id !== categoryId));
    }
    else if (!isSelected) {
      if (mode === CategorySelectionMode.ONLY_ONE_ALLOWED) {
        onChange([ categoryId ]);
      }
      else {
        onChange([ ...selectedCategoryIds, categoryId ]);
      }
    }

    (
      event.target as HTMLButtonElement
    ).blur();
  };

  return (
    <button type="button"
            onClick={onClick}
            className={`btn btn-primary ${isSelected === true ? "active" : "btn-outline-primary"}`}>
      <FormattedMessage id={labelId}/>
    </button>
  );
}


export default CategoryButton;