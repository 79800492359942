import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import { SgDatepicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { useCancelLink } from "@/store/api/webapi";
import { Timestamp } from "@/routes/links/shared";



export type ActionCellProps = {
  linkId: string
}


function ActionCell(props: ActionCellProps): React.JSX.Element {
  const { linkId } = props;

  const [ cancelLink ] = useCancelLink();

  const [ isConfirmationModalVisible, setConfirmationModalVisibility ] = useState(false);
  const [ confirmationDate, setConfirmationDate ] = useState<Timestamp | undefined>(Date.now());
  // const [ confirmationDate, setConfirmationDate ] = useState<string | undefined>(format(new Date(), "dd-MM-yyyy"))

  const cancel = () => {
    if (confirmationDate === undefined) {
      return;
    }

    cancelLink({ linkId, cancellationDate: format(confirmationDate, "yyyy-MM-dd") });
    setConfirmationModalVisibility(false);
  };

  const onConfirmationDateSelected = (event: CustomEvent<{ date: Date; timestamp: Timestamp; formatted: string; }>) => {
    setConfirmationDate(event.detail.timestamp);
  };

  const onConfirmationDateCleared = (event: CustomEvent<void>) => {
    setConfirmationDate(undefined);
  };

  const toggleConfirmationModalVisibility = () => setConfirmationModalVisibility(isVisible => !isVisible);

  const canConfirm = confirmationDate !== undefined;

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <button type="button" className="btn btn-sm btn-link" onClick={toggleConfirmationModalVisibility}>
        <i className="icon icon-sm">cancel</i>
      </button>

      <Modal
        isOpen={isConfirmationModalVisible}
        toggle={toggleConfirmationModalVisibility}
        contentClassName="border border-warning"
      >
        <ModalHeader toggle={toggleConfirmationModalVisibility}>
          <div className="d-flex flex-column w-100">
            <div className="bg-warning bg-opacity-10 rounded-circle d-flex align-items-center justify-content-center"
                 style={{ width: "3rem", height: "3rem" }}>
              <em className="icon icon-md text-warning line-height-1">
                warning
              </em>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>

          <h3 className="mb-4">
            <FormattedMessage id="routes.links.actions.cancel.areYouSure"/>
          </h3>

          <p>
            <FormattedMessage id="routes.links.actions.cancel.confirmationMessage" values={{ linkId }}/>
          </p>

          <div className="mt-2">
            <label htmlFor="date-picker" className="form-label">
              <FormattedMessage
                id="routes.links.actions.cancel.cancellationDate"/>
            </label>

            <SgDatepicker
              id="date-picker"
              appendTo="body"
              initialValue={confirmationDate?.toString()}
              onInputCleared={onConfirmationDateCleared}
              onDateSelected={onConfirmationDateSelected}
              disabled
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button className="btn-outline-primary" onClick={toggleConfirmationModalVisibility}>
            <FormattedMessage id="routes.links.actions.cancel.close"/>
          </Button>

          <Button className="btn-warning" onClick={cancel} disabled={!canConfirm}>
            <FormattedMessage id="routes.links.actions.cancel.cancel"/>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}


export default ActionCell;


