import { Category } from "@/components/CategoryFilter";

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export const ALL_STATUS_CATEGORIES: Category[] = [
  {
    labelId: "routes.shared.status.active",
    id: Status.ACTIVE
  },
  {
    labelId: "routes.shared.status.inactive",
    id: Status.INACTIVE
  }
]