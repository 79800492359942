export type CurrencyData = {
  "id": string,
  "flag": string,
  "desc": {
    "en": string,
    "fr": string
  },
  "keywords": string
}

export const currencies = [
  {
    "id":       "USD",
    "flag":     "us",
    "desc":     {
      "en": "US dollar",
      "fr": "US dollar"
    },
    "keywords": "Dollar des États-Unis Samoa américaines Îles Vierges des Territoire britannique de l’océan Indien Équateur El Salvador Guam Haïti Micronésie Îles Mariannes du Nord Palaos Panama Porto Rico Îles Turques-et-Caïques Îles mineures éloignées des États-Unis Wake Timor oriental"
  },
  {
    "id":       "EUR",
    "flag":     "eu",
    "desc":     {
      "en": "Euro",
      "fr": "Euro"
    },
    "keywords": "États membres de la zone euro Andorre Autriche Açores Baléares Belgique Îles Canaries Chypre Croatie Finlande France Guinée française Terres australes et antarctiques françaises Allemagne Grèce Guadeloupe Hollande Pays-Bas Saint-Siège Cité du Vatican Irlande Italie Luxembourg Madère Malte Monaco Monténégro Portugal Réunion Saint-Pierre-et-Miquelon Saint-Martin Saint-Marin Slovaquie Slovénie Espagne Cité du Vatican Estonie Lituanie Lettonie"
  },
  {
    "id":       "JPY",
    "flag":     "jp",
    "desc":     {
      "en": "Japanese yen",
      "fr": "Yen japonais"
    },
    "keywords": "Japon Japan Yen japonais Japanese yen"
  },
  {
    "id":       "GBP",
    "flag":     "gb",
    "desc":     {
      "en": "British pound",
      "fr": "Livre britannique"
    },
    "keywords": "Livre britannique British pound Livre du Royaume-Uni Royaume-Uni Angleterre Irlande du Nord Écosse Pays de Galles Îles Malouines Gibraltar Guernesey Île de Man Jersey Sainte-Hélène et Ascension Géorgie du Sud-et-les Îles Sandwich du Sud Tristan da Cunha"
  },
  {
    "id":       "CNY",
    "flag":     "cn",
    "desc":     {
      "en": "Renminbi",
      "fr": "Yuan"
    },
    "keywords": "Yuan Renminbi Chine China"
  },
  {
    "id":       "AUD",
    "flag":     "au",
    "desc":     {
      "en": "Australian dollar",
      "fr": "Dollar Australien"
    },
    "keywords": "Australia Australie Dollar Australien Australian dollar Île Christmas Îles Cocos Keeling Île Norfolk Îles Ashmore-et-Cartier Territoire antarctique australien Îles de la mer de Corail Île Heard Îles McDonald Kiribati Nauru"
  },
  {
    "id":       "CAD",
    "flag":     "ca",
    "desc":     {
      "en": "Canadian dollar",
      "fr": "Dollar canadien"
    },
    "keywords": "Dollar du Canada Dollar canadien Canadian dollar"
  },
  {
    "id":       "CHF",
    "flag":     "ch",
    "desc":     {
      "en": "Swiss franc",
      "fr": "Franc Suisse"
    },
    "keywords": "Franc Suisse Franc de Suisse Switzerland Swiss franc Liechtenstein Campione d'Italia Büsingen am Hochrhein"
  },
  {
    "id":       "HKD",
    "flag":     "cn",
    "desc":     {
      "en": "Hong Kong dollar",
      "fr": "Dollar de Honk Kong"
    },
    "keywords": "Dollar de Honk Kong Hong Kong dollar"
  },
  {
    "id":       "SGD",
    "flag":     "sg",
    "desc":     {
      "en": "Singapore dollar",
      "fr": "Dollar de Singapour"
    },
    "keywords": "Dollar de Singapour Singapore dollar"
  },
  {
    "id":       "SEK",
    "flag":     "se",
    "desc":     {
      "en": "Swedish krona",
      "fr": "Couronne suédoise"
    },
    "keywords": "Couronne suédoise Swedish krona"
  },
  {
    "id":       "KRW",
    "flag":     "kr",
    "desc":     {
      "en": "South Korean won",
      "fr": "Won sud-coréen"
    },
    "keywords": "Corée du sud South Korea Won sud-coréen South Korean won"
  },
  {
    "id":       "NOK",
    "flag":     "no",
    "desc":     {
      "en": "Norwegian krone",
      "fr": "Couronne norvégienne"
    },
    "keywords": "Couronne norvégienne Norwegian krone"
  },
  {
    "id":       "NZD",
    "flag":     "nz",
    "desc":     {
      "en": "New Zealand dollar",
      "fr": "Dollar néo-zélandais"
    },
    "keywords": "Nouvelle zélande New zealand Dollar néo-zélandais New Zealand dollar"
  },
  {
    "id":       "INR",
    "flag":     "in",
    "desc":     {
      "en": "Indian rupee",
      "fr": "Roupie indienne"
    },
    "keywords": "Inde India Roupie indienne Indian rupee"
  },
  {
    "id":       "MXN",
    "flag":     "mx",
    "desc":     {
      "en": "Mexican peso",
      "fr": "Peso mexicain"
    },
    "keywords": "Mexique Mexico Peso mexicain Mexican peso"
  },
  {
    "id":       "TWD",
    "flag":     "tw",
    "desc":     {
      "en": "New Taiwan dollar",
      "fr": "Nouveau dollar de Taïwan"
    },
    "keywords": "Nouveau dollar de Taïwan New Taiwan dollar"
  },
  {
    "id":       "ZAR",
    "flag":     "za",
    "desc":     {
      "en": "South African rand",
      "fr": "Rand sud-africain"
    },
    "keywords": "Afrique du sud South Africa Rand sud-africain South African rand"
  },
  {
    "id":       "BRL",
    "flag":     "br",
    "desc":     {
      "en": "Brazilian real",
      "fr": "Réal brésilien"
    },
    "keywords": "Brésil Brazil Réal brésilien Brazilian real"
  },
  {
    "id":       "DKK",
    "flag":     "dk",
    "desc":     {
      "en": "Danish krone",
      "fr": "Couronne danoise"
    },
    "keywords": "Danemark Denmark Couronne danoise Danish krone"
  },
  {
    "id":       "PLN",
    "flag":     "pl",
    "desc":     {
      "en": "Polish złoty",
      "fr": "Złoty"
    },
    "keywords": "Pologne Poland Złoty Polish złoty"
  },
  {
    "id":       "THB",
    "flag":     "th",
    "desc":     {
      "en": "Thai baht",
      "fr": "Baht"
    },
    "keywords": "Thaïlande Thailand Baht Thai baht"
  },
  {
    "id":       "ILS",
    "flag":     "il",
    "desc":     {
      "en": "Israeli new shekel",
      "fr": "Shekel"
    },
    "keywords": "Israël Israel Israeli new shekel"
  },
  {
    "id":       "IDR",
    "flag":     "id",
    "desc":     {
      "en": "Indonesian rupiah",
      "fr": "Roupie indonésienne"
    },
    "keywords": "Indonésie Indonesia Roupie indonésienne Indonesian rupiah"
  },
  {
    "id":       "CZK",
    "flag":     "cz",
    "desc":     {
      "en": "Czech koruna",
      "fr": "Couronne tchèque"
    },
    "keywords": "Tchécoslovaquie Czechoslovakia Couronne tchèque Czech koruna"
  },
  {
    "id":       "AED",
    "flag":     "ae",
    "desc":     {
      "en": "United Arab Emirates dirham",
      "fr": "Dirham des Émirats arabes unis"
    },
    "keywords": "Dirham des Émirats arabes unis United Arab Emirates dirham"
  },
  {
    "id":       "TRY",
    "flag":     "tr",
    "desc":     {
      "en": "Turkish lira",
      "fr": "Livre turque"
    },
    "keywords": "Turquie Turkey Livre turque Turkish lira"
  },
  {
    "id":       "HUF",
    "flag":     "hu",
    "desc":     {
      "en": "Hungarian forint",
      "fr": "Forint"
    },
    "keywords": "Hongrie Hungary Hungarian forint"
  },
  {
    "id":       "CLP",
    "flag":     "cl",
    "desc":     {
      "en": "Chilean peso",
      "fr": "Peso chilien"
    },
    "keywords": "Chili Chile Peso chilien Chilean peso"
  },
  {
    "id":       "SAR",
    "flag":     "sa",
    "desc":     {
      "en": "Saudi riyal",
      "fr": "Riyal saoudien"
    },
    "keywords": "Arabie saoudite saudi arabia Riyal saoudien Saudi riyal"
  },
  {
    "id":       "PHP",
    "flag":     "ph",
    "desc":     {
      "en": "Philippine peso",
      "fr": "Peso philippin"
    },
    "keywords": "Philippines Peso philippin Philippine peso"
  },
  {
    "id":       "MYR",
    "flag":     "my",
    "desc":     {
      "en": "Malaysian ringgit",
      "fr": "Ringgit"
    },
    "keywords": "Malaisie Malaysia Ringgit Malaysian ringgit"
  },
  {
    "id":       "COP",
    "flag":     "co",
    "desc":     {
      "en": "Colombian peso",
      "fr": "Peso colombien"
    },
    "keywords": "Colombie Colombia Peso colombien Colombian peso"
  },
  {
    "id":       "RUB",
    "flag":     "ru",
    "desc":     {
      "en": "Russian ruble",
      "fr": "Rouble russe"
    },
    "keywords": "Russie Russia Rouble russe Russian ruble"
  },
  {
    "id":       "RON",
    "flag":     "ro",
    "desc":     {
      "en": "Romanian leu",
      "fr": "Leu roumain"
    },
    "keywords": "Roumanie Romania Leu roumain Romanian leu"
  },
  {
    "id":       "PEN",
    "flag":     "pe",
    "desc":     {
      "en": "Peruvian sol",
      "fr": "Sol"
    },
    "keywords": "Pérou Peru Peruvian sol"
  },
  {
    "id":       "BHD",
    "flag":     "bh",
    "desc":     {
      "en": "Bahraini dinar",
      "fr": "Dinar bahreïni"
    },
    "keywords": "Bahreïni Bahrain Dinar bahreïni Bahraini dinar"
  },
  {
    "id":       "BGN",
    "flag":     "bg",
    "desc":     {
      "en": "Bulgarian lev",
      "fr": "Lev bulgare"
    },
    "keywords": "Bulgarie Bulgaria Lev bulgare Bulgarian lev"
  },
  {
    "id":       "ARS",
    "flag":     "ar",
    "desc":     {
      "en": "Argentine peso",
      "fr": "Peso argentin"
    },
    "keywords": "Argentine Argentina Peso argentin Argentine peso"
  },
  {
    "id":       "HRK",
    "flag":     "hr",
    "desc":     {
      "en": "Croatian kuna",
      "fr": "Kuna croate"
    },
    "keywords": "Croatie Croatia kuna croate croatian kuna"
  },
  {
    "id":       "RSD",
    "flag":     "rs",
    "desc":     {
      "en": "Serbian dinar",
      "fr": "Dinar serbe"
    },
    "keywords": "Serbie Serbia dinar serbe serbian dinar"
  }
] as const;

type Currency = (typeof currencies)[number]["id"]
type CurrencyFlag = (typeof currencies)[number]["flag"]

export const FLAG_BY_CURRENCIES = currencies.reduce(
  (result, { id, flag }) => {
    result[id] = flag;
    return result;
  },
  {} as Record<string, CurrencyFlag>
);

export const flagForCurrency = (currency: string): CurrencyFlag | undefined => FLAG_BY_CURRENCIES[currency];