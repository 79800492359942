import { RefObject, useEffect } from "react";
import { isDefined } from "@sgme/fp";


// https://www.robinwieruch.de/react-hook-detect-click-outside-component/

export const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const storeRef = ref.current;

    const handleClick = (event: MouseEvent) => {
      if (isDefined(storeRef) && storeRef.contains(event.target as Node | null)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ callback, ref.current ]);
};