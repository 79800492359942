import { ColDef } from "@ag-grid-community/core/dist/cjs/es5/entities/colDef";
import { IntlShape } from "react-intl";

export const DEFAULT_COLUMN_DEF: ColDef = {
  flex: 1,
  lockPinned: true,
  sortable: true,
  filter: true,
  resizable: true,
  enableCellChangeFlash: true,
  menuTabs: [ "filterMenuTab", "columnsMenuTab" ],
  filterParams: { newRowAction: "keep" }
};

export const addHeaderIntlName = (prefix: string, getColumnDefs: (intl: IntlShape) => ColDef[], intl: IntlShape): ColDef[] => getColumnDefs(intl).map(def => ({
  ...def,

  headerName: intl.formatMessage({ id: `${prefix}.${def.field}` })
}));