import { isDefined } from "@sgme/fp";



export const parseBoolean = (value: string | undefined) => {
  if (!isDefined(value)) {
    return undefined;
  }

  if (value === "yes") {
    return true;
  }

  if (value === "no") {
    return false;
  }

  return undefined;
};

export const parseJsonBoolean = (value: "TRUE" | "FALSE" | "O" | "N" | string | undefined) => {
  switch (value) {
    case "TRUE":
    case "O":
      return "yes";

    case "FALSE":
    case "N":
      return "no";

    default:
      return undefined;
  }
};

export const formatJsonBoolean = (value: "yes" | "no" | "" | undefined) => {
  switch (value) {
    case "yes":
      return "TRUE";

    case "no":
      return "FALSE";

    default:
      return undefined;
  }
};

export const formatYNBoolean = (value: "yes" | "no" | undefined) => {
  switch (value) {
    case "yes":
      return "Y";

    case "no":
      return "N";

    default:
      return undefined;
  }
};