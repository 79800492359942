import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import React from "react";



export type ConfirmCancelSafetyModalProps = {
  safetyId: string
  isVisible: boolean
  onToggleVisibility: () => void
  onConfirm: () => void
}


function ConfirmCancelSafetyModal(props: ConfirmCancelSafetyModalProps): React.JSX.Element {
  const {
    safetyId,
    isVisible,
    onToggleVisibility,
    onConfirm
  } = props;

  return (
    <Modal
      isOpen={isVisible}
      toggle={onToggleVisibility}
      contentClassName="border border-warning"
    >
      <ModalHeader toggle={onToggleVisibility}>
        <div className="d-flex flex-column w-100">
          <div className="bg-warning bg-opacity-10 rounded-circle d-flex align-items-center justify-content-center"
               style={{ width: "3rem", height: "3rem" }}>
            <em className="icon icon-md text-warning line-height-1">
              warning
            </em>
          </div>
        </div>
      </ModalHeader>

      <ModalBody>

        <h3 className="mb-4">
          <FormattedMessage id="routes.safeties.actions.cancel.areYouSure"/>
        </h3>

        <p>
          <FormattedMessage id="routes.safeties.actions.cancel.confirmationMessage" values={{ safetyId }}/>
        </p>
      </ModalBody>

      <ModalFooter>
        <Button className="btn-outline-primary" onClick={onToggleVisibility}>
          <FormattedMessage id="routes.safeties.actions.cancel.close"/>
        </Button>

        <Button className="btn-warning" onClick={onConfirm}>
          <FormattedMessage id="routes.safeties.actions.cancel.cancel"/>
        </Button>
      </ModalFooter>
    </Modal>
  );
}


export default ConfirmCancelSafetyModal;