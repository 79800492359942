import { NewLinkState, Step } from "@/routes/links/new-link/store/state";
import { nanoid } from "@reduxjs/toolkit";
import { isDefined } from "@sgme/fp";
import { AuthorizationResponse, TradeResponse } from "@credit-control-risk/common";



export const canGoToPreviousStep = (state: NewLinkState) => state.currentStep !== Step.PRODUCT_SELECTION;

export const canGoToNextStep = (state: NewLinkState) => (
  (
    state.currentStep < state.lastActiveStep
  )
  || (
    state.currentStep === Step.PRODUCT_SELECTION && state.product.selected !== undefined
  )
  || (
    state.currentStep === Step.AUTHORIZATION_SELECTION && state.authorization.selected !== undefined
  )
  || (
    state.currentStep === Step.SAFETY_SELECTION && (
      state.isSafetySelectionStopped === true || state.safeties.selected.length > 0 || !state.canHaveSafeties
    )
  )
);

export const canHaveSafeties = (selectedProduct: TradeResponse | undefined, selectedAuthorization: AuthorizationResponse | undefined) => {
  return !(
    // cash account product has no safeties
    (
      isDefined(selectedProduct) && selectedProduct.type === "00020"
    )
    // blanco authorization doesn't have safeties
    || (
      isDefined(selectedAuthorization) && selectedAuthorization.protectionIndication === "Y"
    )
  );
};

export const NEW_SAFETY_ID = "@@NEW-SAFETY-ID";

export const generateNewSafetyId = () => `${NEW_SAFETY_ID}/${nanoid()}`;

export const isNewSafetyId = (id: string | undefined) => isDefined(id) && id.startsWith(`${NEW_SAFETY_ID}/`);

export const NEW_AUTHORIZATION_ID = "@@NEW-AUTHORIZATION-ID";

export const generateNewAuthorizationId = () => `${NEW_AUTHORIZATION_ID}/${nanoid()}`;

export const isNewAuthorizationId = (id: string | undefined) => isDefined(id) && id.startsWith(`${NEW_AUTHORIZATION_ID}/`);