import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";
import { useCancelSafety, useGetProducts } from "@/store/api/webapi";
import ConfirmCancelSafetyModal from "@/routes/safeties/components/ConfirmCancelSafetyModal";
import { SafetyChange } from "@/components/SafetyEditor/store/types";
import SafetyEditor from "@/components/SafetyEditor";
import Button from "@/components/sg-ui/form/Button";
import { saveSafety } from "@/components/SafetyEditor/store/api";
import { useAppDispatch } from "@/store";
import Tooltip from "@/components/Tooltip";



export type ActionCellProps = {
  rootId: string
  safetyId: string
  active: boolean
}


function ActionCell(props: ActionCellProps): React.JSX.Element {
  const { rootId, safetyId, active: isActive } = props;

  const dispatch = useAppDispatch();

  const [ cancelSafety ] = useCancelSafety();

  const { data: allAssets } = useGetProducts({
    rootId,
    scope:    "ASSET",
    unlinked: false
  });

  const [ isConfirmationModalVisible, setConfirmationModalVisibility ] = useState(false);
  const [ isSafetyEditorVisible, setSafetyEditorVisibility ] = useState(false);
  const [ isEditable, setEditable ] = useState(true);

  const toggleConfirmationModalVisibility = () => setConfirmationModalVisibility(isVisible => !isVisible);
  const toggleSafetyEditorVisibility = () => setSafetyEditorVisibility(isVisible => !isVisible);

  const edit = () => {
    setEditable(true);
    setSafetyEditorVisibility(true);
  };

  const seeDetails = () => {
    setEditable(false);
    setSafetyEditorVisibility(true);
  };

  const cancel = () => {
    cancelSafety({ safetyId });
    setConfirmationModalVisibility(false);
  };

  const onSaveSafety = async (safetyChange: SafetyChange) => {
    const allTrades = allAssets?.trades;

    if (!isDefined(allTrades)) {
      return;
    }

    await saveSafety(rootId, safetyChange, allTrades, dispatch, safetyId);
  };

  return (
    <div className="d-flex justify-content-end align-items-center h-100">
      {
        isActive && (
          <>
            <Button size="small" variant="link" onClick={toggleConfirmationModalVisibility}>
              <i className="icon icon-sm">cancel</i>
            </Button>

            <ConfirmCancelSafetyModal
              safetyId={safetyId}
              isVisible={isConfirmationModalVisible}
              onToggleVisibility={toggleConfirmationModalVisibility}
              onConfirm={cancel}
            />

            {
              isDefined(allAssets)
                ? <Button size="small" variant="link" onClick={edit}>
                  <i className="icon icon-sm">edit</i>
                </Button>
                : <Tooltip labelId={"routes.safeties.actions.edit.notReady"}>
                  <Button size="small" variant="link" disabled>
                    <i className="icon icon-sm">edit</i>
                  </Button>
                </Tooltip>
            }


          </>
        )
      }


      <Button size="small" variant="link" onClick={seeDetails}>
        <span className="me-2">
          <FormattedMessage id="routes.safeties.actions.seeDetails"/>
        </span> <i className="icon icon-sm">arrow_forward</i>
      </Button>

      {
        isSafetyEditorVisible && (
          <SafetyEditor
            rootId={rootId}
            safetyId={safetyId}
            manyAssetsSelectable={false} // TODO: we don't known what is the product type (CreditCard or not)
            opened={isSafetyEditorVisible}
            editable={isEditable}
            toggle={toggleSafetyEditorVisibility}
            onEdit={isActive && isDefined(allAssets) ? edit : undefined}
            onSaveOrCreate={onSaveSafety}
          />
        )
      }
    </div>
  );
}


export default ActionCell;


