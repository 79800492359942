// uncomment the next line to logs which and why react render components
// import "./dev-why-did-you-update.js";

// ---
import "@ag-grid-community/core/dist/styles/ag-grid.css";

import { SGWTConnectError } from "@sgwt/connect-core";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { setupSgwtConnectWidget } from "@/core/sgwtConnect/sgwtConnect";
import "@/core/theme/index.css";
import { createAllRouter } from "@/routes";


import { setCurrentContext, UserContext } from "@/core/contexts/UserContext";
import StoreProvider from "@/store/provider";



const sgwtConnect = setupSgwtConnectWidget();

if (sgwtConnect.isAuthorized()) {
  renderApp();
}
else {
  const authorizationError = sgwtConnect.getAuthorizationError();

  if (authorizationError !== null) {
    renderError(authorizationError);
  }
  else {
    sgwtConnect.requestAuthorization();
  }
}

setCurrentContext({ sgConnect: sgwtConnect });


function renderApp() {
  const rootElement = document.getElementById("root")!;
  const root = createRoot(rootElement);

  const router = createAllRouter();


  root.render(
    // <StrictMode>
    <UserContext.Provider value={{ sgConnect: sgwtConnect }}>
      <StoreProvider>
        <RouterProvider router={router}/>
      </StoreProvider>
    </UserContext.Provider>
    // </StrictMode>
  );
}


function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

