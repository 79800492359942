import { ForwardedRef, forwardRef, MouseEvent, PropsWithChildren } from "react";
import clsx from "clsx";
import { SpaceSize } from "@/components/sg-ui/layout/types";



export interface HStackProps {
  horizontalAlignment?: "start" | "center" | "full" | "end";
  verticalAlignment?: "start" | "center" | "full" | "baseline" | "end";
  fullWidth?: boolean;
  fullHeight?: boolean;
  flex?: "min" | "expand";
  gap?: SpaceSize;
  margin?: SpaceSize;
  padding?: SpaceSize;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  className?: string;
}


function HStack(props: PropsWithChildren<HStackProps>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    horizontalAlignment = "full",
    verticalAlignment = "center",
    fullWidth = false,
    fullHeight = false,
    flex = "min",
    gap = "0",
    margin = "0",
    padding = "0",
    className,
    onClick,
    children
  } = props;

  return (
    <div ref={ref} onClick={onClick} className={clsx([ "d-flex flex-row flex-nowrap overflow-hidden", className ], {
      "justify-content-start":     horizontalAlignment === "start",
      "justify-content-center":    horizontalAlignment === "center",
      "justify-content-between":   horizontalAlignment === "full",
      "justify-content-end":       horizontalAlignment === "end",
      "align-items-start":         verticalAlignment === "start",
      "align-items-center":        verticalAlignment === "center",
      "align-items-baseline":      verticalAlignment === "baseline",
      "align-items-stretch":       verticalAlignment === "full",
      "align-items-end":           verticalAlignment === "end",
      "flex-grow-0 flex-shrink-0": flex === "min",
      "flex-fill":                 flex === "expand",
      "w-100":                     fullWidth,
      "h-100":                     fullHeight,
      "m-0":                       margin === "0",
      "m-1":                       margin === "1",
      "m-2":                       margin === "2",
      "m-3":                       margin === "3",
      "m-4":                       margin === "4",
      "m-5":                       margin === "5",
      "p-0":                       padding === "0",
      "p-1":                       padding === "1",
      "p-2":                       padding === "2",
      "p-3":                       padding === "3",
      "p-4":                       padding === "4",
      "p-5":                       padding === "5",
      "gap-0":                     gap === "0",
      "gap-1":                     gap === "1",
      "gap-2":                     gap === "2",
      "gap-3":                     gap === "3",
      "gap-4":                     gap === "4",
      "gap-5":                     gap === "5"
    })}>
      {children}
    </div>
  );
}


export default forwardRef(HStack);