import React from "react";
import { SGWTConnectCore } from "@sgwt/connect-core";



export interface GlobalContext {
  // clientScopeCode: string
  // setClientScope: (clientScopeCode: string) => void;
  // permissions: Permissions | null
  sgConnect: SGWTConnectCore | null;
  // appConfig: AppConfig | null
  // user: UserInfos | null
}

let currentContext: GlobalContext;

export const setCurrentContext = (context: GlobalContext) => {
  currentContext = context;
  return context;
};
export const getCurrentContext = (): Readonly<GlobalContext> => currentContext;

interface UserContextProps {
  // globalContext: GlobalContext;
  sgConnect: SGWTConnectCore | null;
}

export const UserContext = React.createContext<UserContextProps>({
  // globalContext: {
  // clientScopeCode: "",
  // setClientScope: () => undefined,
  // permissions: null,
  sgConnect: null
  // appConfig: null,
  // user: null
  // }
});