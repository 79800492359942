import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AssetTypeNomenclaturesResponse,
  AuthorizationCreationInput,
  AuthorizationPrefillResponse,
  AuthorizationResponse,
  AuthorizationsResponse,
  AuthorizationUpdateInput,
  CollateralResponse,
  CounterPartyResponse,
  FindEligibleByQuery,
  JsonStringDate,
  LinkInput,
  LinksResponse,
  ProductNomenclaturesResponse,
  RootSuggestionsResponse,
  SafetiesResponse,
  SafetyElementaryTypesResponse,
  SafetyInput,
  SafetyResponse,
  TradesResponse,
  UserResponse
} from "@credit-control-risk/common";
import { getAppConfig } from "@/core/config";
import { getCurrentContext } from "@/core/contexts/UserContext";



const BASE_URL = getAppConfig().webapi.url;

export const webapi = createApi({
  reducerPath: "webapi",
  baseQuery:   fetchBaseQuery({
    baseUrl:        BASE_URL,
    prepareHeaders: headers => {
      const { sgConnect } = getCurrentContext();
      const authHeader = sgConnect?.getAuthorizationHeader();

      headers.set("X-Client-Scope", sgConnect?.getGrantedScope() ?? "");
      headers.set("Authorization", authHeader as string);
      return headers;
    }
  }),

  endpoints: builder => (
    {
      // ██╗   ██╗███████╗███████╗██████╗ ███████╗
      // ██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
      // ██║   ██║███████╗█████╗  ██████╔╝███████╗
      // ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
      // ╚██████╔╝███████║███████╗██║  ██║███████║
      //  ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝

      getUser: builder.query<UserResponse, undefined>({
        query:        () => (
          {
            url: `users`
          }
        ),
        providesTags: [ "users" ]
      }),

      //  ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗███████╗██████╗ ██████╗  █████╗ ██████╗ ████████╗██╗███████╗███████╗
      // ██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██║██╔════╝██╔════╝
      // ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║   █████╗  ██████╔╝██████╔╝███████║██████╔╝   ██║   ██║█████╗  ███████╗
      // ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██╔══██╗██╔═══╝ ██╔══██║██╔══██╗   ██║   ██║██╔══╝  ╚════██║
      // ╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║   ███████╗██║  ██║██║     ██║  ██║██║  ██║   ██║   ██║███████╗███████║
      //  ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

      getCounterparty: builder.query<CounterPartyResponse, {
        rootId: string
      }>({
        query:        ({ rootId }) => (
          {
            url:    `counterparties`,
            params: {
              rootId
            }
          }
        ),
        providesTags: [ "counterparties" ] // TODO: tag with root id
      }),

      getCounterpartySuggestions: builder.query<RootSuggestionsResponse, {
        text: string
      }>({
        query:        ({ text }) => (
          {
            url:    `counterparties/suggestions`,
            params: {
              text
            }
          }
        ),
        providesTags: [ "counterparties/suggestions" ] // TODO: tag with text params
      }),

      // ██╗     ██╗███╗   ██╗██╗  ██╗███████╗
      // ██║     ██║████╗  ██║██║ ██╔╝██╔════╝
      // ██║     ██║██╔██╗ ██║█████╔╝ ███████╗
      // ██║     ██║██║╚██╗██║██╔═██╗ ╚════██║
      // ███████╗██║██║ ╚████║██║  ██╗███████║
      // ╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝

      getLinks: builder.query<LinksResponse, {
        rootId: string,
        page?: number,
        size?: number,
        sort?: string[]
      }>({
        query:        ({ rootId, page = 0, size = 20, sort }) => (
          {
            url:    `links`,
            params: {
              rootId,
              page,
              size,
              sort
            }
          }
        ),
        providesTags: [ "links" ]
      }),

      saveLinks: builder.mutation<LinksResponse, LinkInput>({
        query:           (input) => (
          {
            url:    "links",
            method: "POST",
            body:   input
          }
        ),
        invalidatesTags: [ "links", "authorizations", "unlinked-authorizations", "safeties", "unlinked-safeties" ]
      }),

      cancelLink: builder.mutation<void, {
        linkId: string,
        cancellationDate: JsonStringDate
      }>({
        query:           ({ linkId, cancellationDate }) => (
          {
            url:    `links/do-cancel`,
            method: "PATCH",
            body:   {
              linkId,
              cancellationDate
            }
          }
        ),
        invalidatesTags: [ "links", "authorizations", "unlinked-authorizations", "safeties", "unlinked-safeties" ]
      }),


      //  █████╗ ██╗   ██╗████████╗██╗  ██╗ ██████╗ ██████╗ ██╗███████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
      // ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██║╚══███╔╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
      // ███████║██║   ██║   ██║   ███████║██║   ██║██████╔╝██║  ███╔╝ ███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
      // ██╔══██║██║   ██║   ██║   ██╔══██║██║   ██║██╔══██╗██║ ███╔╝  ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
      // ██║  ██║╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║  ██║██║███████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
      // ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝


      getAuthorizations: builder.query<AuthorizationsResponse, {
        rootId: string,
        page?: number,
        size?: number,
        sort?: string[]
      }>({
        query:        ({ rootId, page = 0, size = 20, sort }) => (
          {
            url:    `authorizations`,
            params: {
              rootId,
              page,
              size,
              sort
            }
          }
        ),
        providesTags: [ "authorizations" ]
      }),

      getEligibleAuthorizations: builder.query<AuthorizationsResponse, FindEligibleByQuery>({
        query:        (params) => (
          {
            url: `eligible-authorizations`,
            params
          }
        ),
        providesTags: [ "eligible-authorizations" ]
      }),

      createAuthorization: builder.mutation<AuthorizationResponse, AuthorizationCreationInput>({
        query:           (input) => (
          {
            url: `authorizations`,

            method: "POST",
            body:   input
          }
        ),
        invalidatesTags: [ "authorizations", "eligible-authorizations" ]
      }),

      saveAuthorization: builder.mutation<AuthorizationResponse, { id: string, safety: AuthorizationUpdateInput }>({
        query:           (input) => (
          {
            url: `authorizations/${input.id}`,

            method: "PUT",
            body:   input.safety
          }
        ),
        invalidatesTags: [ "authorizations", "eligible-authorizations" ]
      }),

      cancelAuthorization: builder.mutation<void, {
        authorizationId: string
      }>({
        query:           ({ authorizationId }) => (
          {
            url: `authorizations/do-cancel/${authorizationId}`,

            method: "PATCH"
          }
        ),
        invalidatesTags: [ "authorizations", "eligible-authorizations" ]
      }),

      getDefaultAuthorizationValues: builder.query<AuthorizationPrefillResponse, {
        productId: string
        productType: string // "WARRANTY" | "LOAN" | "CASH_ACCOUNT" | "CREDIT_CARD" | "OVERDRAFT" | "DEPOSIT"
      }>({
        query: ({ productId, productType }) => (
          {
            url: `prefill-authorizations?productId=${productId}&productType=${productType}`,

            method: "GET"
          }
        )
      }),


      // ███████╗ █████╗ ███████╗███████╗████████╗██╗███████╗███████╗
      // ██╔════╝██╔══██╗██╔════╝██╔════╝╚══██╔══╝██║██╔════╝██╔════╝
      // ███████╗███████║█████╗  █████╗     ██║   ██║█████╗  ███████╗
      // ╚════██║██╔══██║██╔══╝  ██╔══╝     ██║   ██║██╔══╝  ╚════██║
      // ███████║██║  ██║██║     ███████╗   ██║   ██║███████╗███████║
      // ╚══════╝╚═╝  ╚═╝╚═╝     ╚══════╝   ╚═╝   ╚═╝╚══════╝╚══════╝

      getSafeties: builder.query<SafetiesResponse, {
        rootId: string,
        page?: number,
        size?: number,
        sort?: string[]
      }>({
        query:        ({ rootId, page = 0, size = 20, sort }) => (
          {
            url:    `safeties`,
            params: {
              rootId,
              page,
              size,
              sort
            }
          }
        ),
        providesTags: [ "safeties" ]
      }),

      getUnlinkedSafeties: builder.query<SafetiesResponse, {
        rootId: string
        activeOnly?: "true" | "false"
        page?: number
        size?: number
        sort?: string[]
      }>({
        query:        ({ rootId, activeOnly, page = 0, size = 20, sort }) => (
          {
            url:    `unlinked-safeties`,
            params: {
              rootId,
              activeOnly,
              page,
              size,
              sort
            }
          }
        ),
        providesTags: [ "unlinked-safeties" ]
      }),

      createSafety: builder.mutation<SafetyResponse, SafetyInput>({
        query:           (input) => (
          {
            url: `safeties`,

            method: "POST",
            body:   input
          }
        ),
        invalidatesTags: [ "safeties", "unlinked-safeties" ]
      }),

      saveSafety: builder.mutation<SafetyResponse, { id: string, safety: SafetyInput }>({
        query:           (input) => (
          {
            url: `safeties/${input.id}`,

            method: "PUT",
            body:   input.safety
          }
        ),
        invalidatesTags: [ "safeties", "unlinked-safeties" ]
      }),

      cancelSafety: builder.mutation<void, {
        safetyId: string
      }>({
        query:           ({ safetyId }) => (
          {
            url: `safeties/do-cancel/${safetyId}`,

            method: "PATCH"
          }
        ),
        invalidatesTags: [ "safeties", "unlinked-safeties" ]
      }),


      //     ███████╗ █████╗ ███████╗███████╗████████╗██╗   ██╗    ███████╗██╗     ███████╗███╗   ███╗███████╗███╗   ██╗████████╗ █████╗ ██████╗ ██╗   ██╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
      //     ██╔════╝██╔══██╗██╔════╝██╔════╝╚══██╔══╝╚██╗ ██╔╝    ██╔════╝██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝██╔══██╗██╔══██╗╚██╗ ██╔╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
      //     ███████╗███████║█████╗  █████╗     ██║    ╚████╔╝     █████╗  ██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║   ███████║██████╔╝ ╚████╔╝        ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
      //     ╚════██║██╔══██║██╔══╝  ██╔══╝     ██║     ╚██╔╝      ██╔══╝  ██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   ██╔══██║██╔══██╗  ╚██╔╝         ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
      //     ███████║██║  ██║██║     ███████╗   ██║      ██║       ███████╗███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   ██║  ██║██║  ██║   ██║          ██║      ██║   ██║     ███████╗███████║
      //     ╚══════╝╚═╝  ╚═╝╚═╝     ╚══════╝   ╚═╝      ╚═╝       ╚══════╝╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

      getSafetyElementaryTypes: builder.query<SafetyElementaryTypesResponse, void>({
        query:           () => (
          {
            url: `safety-elementary-types`,

            method: "GET",
            params: {
              size: 10000
            }
          }
        ),
        invalidatesTags: [ "safety-elementary-types" ]
      }),


      //  █████╗ ███████╗███████╗███████╗████████╗    ████████╗██╗   ██╗██████╗ ███████╗    ███╗   ██╗ ██████╗ ███╗   ███╗███████╗███╗   ██╗ ██████╗██╗      █████╗ ████████╗██╗   ██╗██████╗ ███████╗
      // ██╔══██╗██╔════╝██╔════╝██╔════╝╚══██╔══╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝    ████╗  ██║██╔═══██╗████╗ ████║██╔════╝████╗  ██║██╔════╝██║     ██╔══██╗╚══██╔══╝██║   ██║██╔══██╗██╔════╝
      // ███████║███████╗███████╗█████╗     ██║          ██║    ╚████╔╝ ██████╔╝█████╗      ██╔██╗ ██║██║   ██║██╔████╔██║█████╗  ██╔██╗ ██║██║     ██║     ███████║   ██║   ██║   ██║██████╔╝█████╗
      // ██╔══██║╚════██║╚════██║██╔══╝     ██║          ██║     ╚██╔╝  ██╔═══╝ ██╔══╝      ██║╚██╗██║██║   ██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║     ██║     ██╔══██║   ██║   ██║   ██║██╔══██╗██╔══╝
      // ██║  ██║███████║███████║███████╗   ██║          ██║      ██║   ██║     ███████╗    ██║ ╚████║╚██████╔╝██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╗███████╗██║  ██║   ██║   ╚██████╔╝██║  ██║███████╗
      // ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝    ╚═╝  ╚═══╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝

      getAssetTypeNomenclature: builder.query<AssetTypeNomenclaturesResponse, void>({
        query:           () => (
          {
            url: `asset-type-nomenclatures`,

            method: "GET",
            params: {
              size: 10000
            }
          }
        ),
        invalidatesTags: [ "asset-type-nomenclatures" ]
      }),


      //  ██████╗ ██████╗ ██╗     ██╗      █████╗ ████████╗███████╗██████╗  █████╗ ██╗     ███████╗
      // ██╔════╝██╔═══██╗██║     ██║     ██╔══██╗╚══██╔══╝██╔════╝██╔══██╗██╔══██╗██║     ██╔════╝
      // ██║     ██║   ██║██║     ██║     ███████║   ██║   █████╗  ██████╔╝███████║██║     ███████╗
      // ██║     ██║   ██║██║     ██║     ██╔══██║   ██║   ██╔══╝  ██╔══██╗██╔══██║██║     ╚════██║
      // ╚██████╗╚██████╔╝███████╗███████╗██║  ██║   ██║   ███████╗██║  ██║██║  ██║███████╗███████║
      //  ╚═════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝

      getUnlinkedCollaterals: builder.query<CollateralResponse, {
        rootId: string,
        page?: number,
        size?: number,
        sort?: string[]
      }>({
        query: ({ rootId, page = 0, size = 20, sort }) => (
          {
            url:    `unlinked-collaterals`,
            params: {
              rootId,
              page,
              size,
              sort
            }
          }
        )
      }),


      // ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗███████╗
      // ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝██╔════╝
      // ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║   ███████╗
      // ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║   ╚════██║
      // ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║   ███████║
      // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝   ╚══════╝

      getProducts: builder.query<TradesResponse, {
        rootId: string,
        scope: "ALL" | "COMMITMENT" | "ASSET",
        unlinked?: boolean
      }>({
        query: ({ rootId, scope, unlinked }) => (
          {
            url:    `products`,
            params: {
              rootId,
              scope,
              unlinked
            }
          }
        )
      }),


      // ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗    ███╗   ██╗ ██████╗ ███╗   ███╗███████╗███╗   ██╗ ██████╗██╗      █████╗ ████████╗██╗   ██╗██████╗ ███████╗
      // ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝    ████╗  ██║██╔═══██╗████╗ ████║██╔════╝████╗  ██║██╔════╝██║     ██╔══██╗╚══██╔══╝██║   ██║██╔══██╗██╔════╝
      // ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║       ██╔██╗ ██║██║   ██║██╔████╔██║█████╗  ██╔██╗ ██║██║     ██║     ███████║   ██║   ██║   ██║██████╔╝█████╗
      // ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║       ██║╚██╗██║██║   ██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║     ██║     ██╔══██║   ██║   ██║   ██║██╔══██╗██╔══╝
      // ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║       ██║ ╚████║╚██████╔╝██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╗███████╗██║  ██║   ██║   ╚██████╔╝██║  ██║███████╗
      // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝       ╚═╝  ╚═══╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝

      getProductNomenclatures: builder.query<ProductNomenclaturesResponse, void>({
        query:           () => (
          {
            url: `product-nomenclatures`,

            method: "GET",
            params: {
              size: 10000
            }
          }
        ),
        invalidatesTags: [ "product-nomenclatures" ]
      })
    }
  )
});

export const useUser = webapi.endpoints.getUser.useQuery;

export const useCounterparty = webapi.endpoints.getCounterparty.useQuery;
export const useCounterpartySuggestions = webapi.endpoints.getCounterpartySuggestions.useQuery;

export const useGetLinks = webapi.endpoints.getLinks.useQuery;
export const useSaveLinks = webapi.endpoints.saveLinks.useMutation;
export const useCancelLink = webapi.endpoints.cancelLink.useMutation;

export const useGetAuthorizations = webapi.endpoints.getAuthorizations.useQuery;
export const useGetLazyAuthorizations = webapi.endpoints.getAuthorizations.useLazyQuery;
export const useGetLazyEligibleAuthorizations = webapi.endpoints.getEligibleAuthorizations.useLazyQuery;
export const useGetEligibleAuthorizations = webapi.endpoints.getEligibleAuthorizations.useQuery;
export const useCancelAuthorization = webapi.endpoints.cancelAuthorization.useMutation;
export const useGetDefaultAuthorizationValues = webapi.endpoints.getDefaultAuthorizationValues.useQuery;

export const useGetSafeties = webapi.endpoints.getSafeties.useQuery;
export const useGetLazySafeties = webapi.endpoints.getSafeties.useLazyQuery;
export const useGetLazyUnlinkedSafeties = webapi.endpoints.getUnlinkedSafeties.useLazyQuery;
export const useGetUnlinkedSafeties = webapi.endpoints.getUnlinkedSafeties.useQuery;
export const useCreateSafety = webapi.endpoints.createSafety.useMutation;
export const useCancelSafety = webapi.endpoints.cancelSafety.useMutation;

export const useGetSafetyElementaryTypes = webapi.endpoints.getSafetyElementaryTypes.useQuery;
export const useGetAssetTypeNomenclature = webapi.endpoints.getAssetTypeNomenclature.useQuery;

export const useGetUnlinkedCollaterals = webapi.endpoints.getUnlinkedCollaterals.useQuery;
export const useGetProducts = webapi.endpoints.getProducts.useQuery;
export const useGetProductNomenclatures = webapi.endpoints.getProductNomenclatures.useQuery;
