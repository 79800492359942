import { SafetyFormData } from "@/components/SafetyEditor/store/state";



export type SafetyChange =
  | SafetyCreation
  | SafetyUpdate

export type SafetyCreation = SafetyFormData & {
  safetyId: undefined

  safetyTypeId: string,
  safetyType: SafetyType,
  assetTypeId?: string,
  assetSourceProductIds: string[]
}

export type SafetyUpdate = SafetyFormData & {
  safetyId: string,

  safetyTypeId: string,
  safetyType: SafetyType,
  assets: SafetyAssetReference[]
}

export type SafetyAssetReference = {
  id: string
  sourceProductId: string
}


/**
 * "IF Group Type of Safety is mapped with BASEL CATEGORY ID= 06 or 07 or 08 then it is a Property THEN the Country of location of an asset/property is showing
 * ELSE IF Group Type of Safety is mapped with BASEL CATEGORY ID= 02 or 03 or 05 then it is a asset THEN the the Country of location of an asset/property is not showing
 *
 * Component or blank
 * Defaulted with 'LU'"
 */

export const GARANTIE_BASEL_CODES = [ "01", "09" ] as const;
export const COLLATERAL_PROPERTY_BASEL_CODES = [ "06", "07", "08" ] as const;
export const COLLATERAL_ASSET_BASEL_CODES = [ "02", "03", "04", "05" ] as const;

export type SafetyElementaryCode = typeof GARANTIE_BASEL_CODES[number] | typeof COLLATERAL_PROPERTY_BASEL_CODES[number] | typeof COLLATERAL_ASSET_BASEL_CODES[number];

export enum SafetyType {
  GUARANTEE,
  COLLATERAL_MORTGAGE, // COLLATERAL PROPERTY
  COLLATERAL_ASSET// COLLATERAL FINANCIAL ASSET
}