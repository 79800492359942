import { DataFolder } from "@/components/DataTreeSelector/types";



export const getFolderCount = (folder: DataFolder): number => {
  return folder.children.reduce(
    (count, node) => "children" in node
      ? count + getFolderCount(node)
      : count + 1,
    0
  );
};