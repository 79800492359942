import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { useEffect, useRef } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { isDefined } from "@sgme/fp";
import CollapsablePanel from "@/components/CollapsablePanel";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import { formatDate } from "@/utils/formatters";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";
import { AppState } from "@/store";
import { newLinkSlice, selectProductId, togglePanel } from "@/routes/links/new-link/store/slice";
import { isSelectedInManySelectionSelection } from "@/utils/state/selection-many";
import { SelectableItemStatusType } from "@/utils/selectable";
import { NewLinkState, Panel, Step } from "@/routes/links/new-link/store/state";



function ProductSelector(): React.JSX.Element {
  const intl = useIntl();

  const apiRef = useRef<GridApi>();

  const dispatch = useDispatch();

  const data = useSelector((appState: AppState) => {
    const newLinkState = appState.ui[newLinkSlice.name] as NewLinkState;

    return {
      isOpened:        isSelectedInManySelectionSelection(newLinkState.openedPanels, Panel.PRODUCT),
      rows:            newLinkState.product.all,
      selectedProduct: newLinkState.product.selected,
      isLoading:       newLinkState.product.status.type !== SelectableItemStatusType.LOADED,
      isEditable:      newLinkState.currentStep !== Step.LINK_CREATION,
      hasError:        false
    };
  });

  const {
    isOpened,
    rows,
    selectedProduct,
    isEditable,
    isLoading,
    hasError
  } = data;

  const onTogglePanel = () => {
    dispatch(togglePanel(Panel.PRODUCT));
  };


  const onGridReady = (event: GridReadyEvent) => {
    apiRef.current = event.api;

    if (isLoading) {
      apiRef.current?.showLoadingOverlay();
    }
  };

  useEffect(() => {
    if (!isLoading) {
      apiRef.current?.hideOverlay();
    }
  }, [ isLoading ]);

  useEffect(() => {
    apiRef.current?.forEachNode(node => node.setRowSelectable(isEditable));
  }, [ isEditable ]);

  const onSelectionChanged = (event: RowSelectedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const productId = selectedNodes.length === 0 ? undefined : selectedNodes[0].data.sourceProductId;

    if (selectedProduct?.sourceProductId === productId) {
      return;
    }

    dispatch(selectProductId(productId));
  };

  useEffect(() => {
    apiRef.current?.deselectAll();

    if (!isDefined(selectedProduct)) {
      return;
    }

    apiRef.current?.forEachNode(node => {
      if (node.data.sourceProductId === selectedProduct?.sourceProductId) {
        node.setSelected(true, true);
      }
    });
  }, [ selectedProduct ]);

  if (hasError) {
    return <ErrorPanel tips="reload+go-home"/>;
  }

  return (
    <CollapsablePanel
      nameId="routes.new-link.product.Product"
      isOpened={isOpened}
      toggle={onTogglePanel}
    >
      <div style={{ height: 258, width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
        <AgGridReact
          rowData={rows}
          defaultColDef={{ ...DEFAULT_COLUMN_DEF, headerCheckboxSelection: false }}
          columnDefs={addHeaderIntlName("routes.new-link.product.table.column", getTableColumns, intl)}
          modules={[ ClientSideRowModelModule ]}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          enableCellTextSelection
        />
      </div>
    </CollapsablePanel>
  );
}


const getTableColumns = (intl: IntlShape): ColDef[] => [
  {
    field:                  "sourceProductId",
    minWidth:               250,
    checkboxSelection:      true,
    showDisabledCheckboxes: true
  },
  {
    field:    "type",
    minWidth: 150
  },
  {
    field:    "schemeId",
    minWidth: 150
  },
  {
    field:    "typeLabel",
    minWidth: 150
  },
  {
    field:          "notionalAmount",
    type:           "rightAligned",
    minWidth:       170,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "notionalAmountCurrency",
    minWidth: 170
  },
  {
    field:          "startDate",
    minWidth:       120,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, intl)
  },
  {
    field:          "endDate",
    minWidth:       120,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatDate(new Date(params.value), {
      day:   "2-digit",
      month: "short",
      year:  "2-digit"
    }) : ""
  }
];

export default ProductSelector;