import { useIntl } from "react-intl";



function AccountCenter(): React.JSX.Element {
  const intl = useIntl();

  return (
    <sgwt-account-center
      available-languages="fr,en"
      authentication="sg-connect-v2"
      mode="sg-markets"
      language={intl.locale}
      producer-code="sgme-ccr"
      // hide-services-link="true"
      // navigate-as={canNavigateAs}
      // navigate-as-list={json(navigateAsList)}
    />
  );
}


export default AccountCenter;