import styled from "@emotion/styled";
import HStack from "@/components/sg-ui/layout/HStack";
import Box from "@/components/sg-ui/layout/Box";
import { DataFolder, ID_COLUMN_WIDTH, LEVEL_CONTAINER_PADDING } from "@/components/DataTreeSelector/types";
import LevelContainer from "@/components/DataTreeSelector/LevelContainer";
import FolderLevel from "@/components/DataTreeSelector/FolderLevel";
import { getFolderCount } from "@/components/DataTreeSelector/api";



export type RootLevelProps = {
  folder: DataFolder
  selectedId: string | undefined
  toggleIdSelection: (id: string) => void
}


function RootLevel(props: RootLevelProps): React.JSX.Element {
  const { folder, selectedId, toggleIdSelection } = props;

  const count = getFolderCount(folder);

  return (
    <>
      <Styles>
        <HStack gap="2">
          <Box className="text-large font-weight-semibold">{folder.name}</Box>
          <span className="count">{count}</span>
          <Box flex="expand"/>
        </HStack>

        <HStack>
          <span>{folder.id}</span>
        </HStack>
      </Styles>

      {folder.children.map(child => "children" in child && (
        <LevelContainer key={child.id}>
          <FolderLevel folder={child} selectedId={selectedId} toggleIdSelection={toggleIdSelection}/>
        </LevelContainer>
      ))}
    </>
  );
}


export default RootLevel;

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ID_COLUMN_WIDTH + LEVEL_CONTAINER_PADDING}px;
  margin: 16px 0;

  & > div:nth-child(1) {
    & > div {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -.01em;
    }
  }

  & > div:nth-child(2) {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--interface-secondary, #6E777A);
  }

  .count {
    padding: 0.125rem 0.46rem;
    font-size: 14px;
    color: #000;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 20px;
  }
`;