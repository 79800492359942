import React, { FocusEvent, FormEvent, ForwardedRef, forwardRef, MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import { HiddenInput, useUpdateValue } from "@/components/sg-ui/form/_utils";
import Button from "@/components/sg-ui/form/Button";



export interface YesNoInputProps {
  name: string;
  value?: YesNoValue | string;
  disabled?: boolean;
  editable?: boolean;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;

  yesLabelId?: string;
  noLabelId?: string;
}

export enum YesNoValue {
  YES = "yes",
  NO = "no"
}


function YesNoInput(props: YesNoInputProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    value:    defaultValue,
    disabled: isDisabled = false,
    editable: isEditable = true,
    onChange, onBlur,
    yesLabelId, noLabelId
  } = props;

  const [ innerRef, value, updateValue ] = useUpdateValue(externalRef, defaultValue);

  const onClick = (buttonValue: YesNoValue) => (event: MouseEvent<HTMLButtonElement>) => {
    updateValue(buttonValue);

    (
      event.target as HTMLButtonElement
    ).blur();
  };

  if (!isEditable) {
    switch (value) {
      case "yes":
        return <span className="btn btn-primary disabled"><FormattedMessage id={yesLabelId ?? "components.sg-ui.form.YesNoInput.YES"}/></span>;
      case "no":
        return <span className="btn btn-primary disabled"><FormattedMessage id={noLabelId ?? "components.sg-ui.form.YesNoInput.NO"}/></span>;
      default:
        return "";
    }
    ;
  }

  return (
    <>
      <Button
        onClick={onClick(YesNoValue.YES)}
        color="primary"
        variant={value === "yes" ? "default" : "outlined"}
        className={value === "yes" ? "active" : ""}
      >
        <FormattedMessage id={yesLabelId ?? "components.sg-ui.form.YesNoInput.YES"}/>
      </Button>

      <Button
        onClick={onClick(YesNoValue.NO)}
        color="primary"
        variant={value === "no" ? "default" : "outlined"}
        className={`${value === "no" ? "active" : ""} ms-1`}
      >
        <FormattedMessage id={noLabelId ?? "components.sg-ui.form.YesNoInput.NO"}/>
      </Button>

      <HiddenInput
        ref={innerRef}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
}


export default forwardRef(YesNoInput);