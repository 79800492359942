import { HelpCenter } from "@/components/sgwt/HelpCenter";
import { MiniFooter } from "@/components/sgwt/MiniFooter";
import { useAppVersion } from "@/store/api/version";



function Footer() {
  const { data: appVersion } = useAppVersion();

  return (
    <footer className="bg-primary-alt">
      <HelpCenter/>
      <MiniFooter/>
      <div className="px-3" style={{ fontSize: 10 }}>
        version: {appVersion?.version ?? "-"}
      </div>
    </footer>
  );
}


export default Footer;