import { useIntl } from "react-intl";

import { PropsWithChildren, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";



export type TooltipProps = {
  labelId: string
  placement?: "top" | "bottom" | "left" | "right"
}


function Tooltip(props: PropsWithChildren<TooltipProps>): React.JSX.Element {
  const { labelId, placement = "top", children } = props;

  const ref = useRef<HTMLSpanElement>(null);

  const { formatMessage } = useIntl();
  const label = formatMessage({ id: labelId });

  return (
    <>
      <span ref={ref}>
        {children}
      </span>
      <UncontrolledTooltip
        target={ref}
        placement={placement}
      >
        {label}
      </UncontrolledTooltip>
    </>
  );
}


export default Tooltip;