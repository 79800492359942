import { Children, createRef, isValidElement, MouseEvent, PropsWithChildren, ReactNode, RefObject, useState } from "react";
import { FormattedMessage } from "react-intl";
import VStack from "@/components/sg-ui/layout/VStack";
import Box from "@/components/sg-ui/layout/Box";
import ScrollView from "@/components/sg-ui/layout/ScrollView";
import { InView } from "react-intersection-observer";
import { last } from "@/utils/fp";


// ref.current?.scrollIntoView()
// https://github.com/thebuilder/react-intersection-observer
// https://github.com/thebuilder/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
// children handling: https://www.smashingmagazine.com/2021/08/react-children-iteration-methods/


export type SectionListData = {
  labelId: string
  ref: RefObject<HTMLDivElement>
  scrollTo: (event: MouseEvent<HTMLAnchorElement>) => void
  updateVisibleView: (isInView: boolean) => void

  child: ReactNode
}

export const SectionList = (props: PropsWithChildren) => {
  const { children } = props;

  const allSections: SectionListData[] = [];

  let childCount = 0;

  const [ visibleTabIndexes, setVisibleTabIndexes ] = useState<number[]>([]);

  Children.forEach(children, (child, childIndex) => {
    if (!isValidElement(child)) {
      return;
    }

    if ("labelId" in child.props) {
      childCount++;

      const ref = createRef<HTMLDivElement>();

      allSections.push({
        labelId:           child.props.labelId,
        ref,
        scrollTo:          (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          ref.current?.scrollIntoView({ behavior: "smooth" });
        },
        updateVisibleView: (isInView: boolean) => {
          if (isInView) {
            setVisibleTabIndexes(visibleIndexes => [ ...visibleIndexes, childIndex ].sort());
          }
          else {
            setVisibleTabIndexes(visibleIndexes => visibleIndexes.filter(index => index !== childIndex));
          }
        },
        child
      });
    }
  });

  return (
    <VStack fullHeight>
      <Box>
        <ul className="nav nav-pills nav-hover bg-white border-bottom mx-3">
          {
            allSections.map((item, itemIndex) => (
              <li key={`tab-${item.labelId}`} className="nav-item">
                {
                  isChildActive(itemIndex, visibleTabIndexes, childCount)
                    ? <span className="nav-link active">
                        <FormattedMessage id={item.labelId}/>
                      </span>
                    : <a className="nav-link" href="#default" onClick={item.scrollTo}>
                      <FormattedMessage id={item.labelId}/>
                    </a>
                }
              </li>
            ))
          }
        </ul>
      </Box>

      <ScrollView padding="3" flex="expand">
        {
          allSections.map(item => (
            <InView key={item.labelId} onChange={item.updateVisibleView}>
              <div ref={item.ref} key={`content-${item.labelId}`}>
                {item.child}
              </div>
            </InView>
          ))
        }
      </ScrollView>
    </VStack>
  );
};

const isChildActive = (childIndex: number, visibleChildIndexes: number[], childCount: number) => {
  if (visibleChildIndexes.length === 0) {
    return false;
  }

  // the view show all sections, so no one is active
  if (visibleChildIndexes.length === childCount) {
    return false;
  }

  return last(visibleChildIndexes) === childIndex;
};