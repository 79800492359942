import { createAsyncThunk } from "@reduxjs/toolkit";
import { isDefined } from "@sgme/fp";
import { TradeResponse } from "@credit-control-risk/common";
import { AppDispatch, AppState } from "@/store";
import { webapi } from "@/store/api/webapi";
import { linksSaved, newLinkSlice } from "@/routes/links/new-link/store/slice";
import { NewLinkState } from "@/routes/links/new-link/store/state";
import { isNewSafetyId } from "@/routes/links/new-link/store/api";
import { saveSafety } from "@/components/SafetyEditor/store/api";
import { saveAuthorization } from "@/components/AuthorizationEditor/store/api";



export const saveLinks = createAsyncThunk<void, TradeResponse[], {
  dispatch: AppDispatch,
  state: AppState
}>(
  "new-link/saveLinks",
  async (allAssets, { getState, dispatch }) => {
    const state = getState().ui[newLinkSlice.name] as NewLinkState;

    const rootId = state.rootId;
    const product = state.product.selected;
    const authorization = state.authorization.selected;

    if (!isDefined(rootId) || !isDefined(product) || !isDefined(authorization)) {
      return;
    }

    const authorizationId = isDefined(state.newAuthorization) && state.newAuthorization.response.authorizationRef === authorization.authorizationRef
      ? await saveAuthorization(rootId, product.type!, state.newAuthorization.change, dispatch)
      : authorization?.authorizationRef;


    const allSelectedSafeties = state.safeties.selected;
    const allSelectedSafetyIds = allSelectedSafeties.map(safety => safety.id);

    const newCreatedSafetyIds = [];

    for (let i = 0; i < state.newSafeties.length; i++) {
      const safety = state.newSafeties[i];

      // only selected new safeties must be saved
      if (allSelectedSafetyIds.includes(safety.response.id)) {
        // eslint-disable-next-line no-await-in-loop
        newCreatedSafetyIds.push(await saveSafety(rootId, safety.change, allAssets, dispatch));
      }
    }

    const safetyIds = [
      ...allSelectedSafeties.map(safety => safety.id!).filter(id => !isNewSafetyId(id)),
      ...newCreatedSafetyIds
    ];

    await dispatch(webapi.endpoints.saveLinks.initiate({
      rootId,
      productType: product.type,
      productId:   product.sourceProductId,
      authorizationId,
      safetyIds
    })).unwrap()
    .then(response => {
      dispatch(linksSaved());
    });
  }
);