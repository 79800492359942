import ErrorPanel from "@/components/sg-ui/ErrorPanel";


// TODO: bad style
// https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap/main/demo/templates/error-pages/404.html

function NoMatch() {
  return (
    <ErrorPanel tips="reload+go-home"/>
  );
}


export default NoMatch;