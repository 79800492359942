import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthorizationFormData, initAuthorizationEditorState, initAuthorizationFormData } from "@/components/AuthorizationEditor/store/state";
import { AuthorizationPrefillResponse } from "@credit-control-risk/common";
import { parseDate } from "@/utils/date";
import { isDefined, isNotDefined } from "@sgme/fp";
import { isAfter } from "date-fns";

// ███████╗██╗     ██╗ ██████╗███████╗
// ██╔════╝██║     ██║██╔════╝██╔════╝
// ███████╗██║     ██║██║     █████╗
// ╚════██║██║     ██║██║     ██╔══╝
// ███████║███████╗██║╚██████╗███████╗
// ╚══════╝╚══════╝╚═╝ ╚═════╝╚══════╝

export const authorizationEditorSlice = createSlice({
  name:         "authorization-editor",
  initialState: initAuthorizationEditorState(),
  reducers:     {
    initAuthorizationForm(state, action: PayloadAction<{
      rootId: string,
      authorization: {
        id: string,
        data: AuthorizationFormData
      } | undefined,
      defaultAuthorizationValues?: AuthorizationPrefillResponse
      isEditable: boolean
      defaultProtectionIndication?: "yes" | "no" // blanco
    }>) {
      const form = (
        action.payload.authorization?.data
        ?? initAuthorizationFormData(action.payload.defaultAuthorizationValues, action.payload.defaultProtectionIndication ?? "no")
      );

      return {
        ...state,

        rootId:          action.payload.rootId,
        authorizationId: action.payload.authorization?.id,

        form,
        formErrors: {},

        isEditable: action.payload.isEditable
      };
    },

    setEditable(state) {
      return {
        ...state,

        isEditable: true
      };
    },

    reset(state) {
      return initAuthorizationEditorState();
    },

    updateFormField(state, action: PayloadAction<{
      name: string,
      value: string
    }>) {
      const form = {
        ...state.form,

        [action.payload.name]: action.payload.value
      };

      return {
        ...state,

        form,
        formErrors: checkFormErrors(form)
      };
    }
  }
});


export const {
  initAuthorizationForm,
  setEditable,
  reset,

  updateFormField
} = authorizationEditorSlice.actions;


const checkFormErrors = (form: AuthorizationFormData): Partial<Record<keyof AuthorizationFormData, string>> => {
  // validity start date<=Administrative Validity Date<=Authorization Exposure maturity date

  const validityStartDate = parseDate(form.validityStartDate);
  const administrativeValidityDate = parseDate(form.administrativeValidityDate);
  const exposureMaturityDate = parseDate(form.exposureMaturityDate);

  const validityStartDateErrorLabelId = (
    isDefined(administrativeValidityDate) && isNotDefined(validityStartDate)
      ? "components.AuthorizationEditor.date.validityStartDate.notDefined"
      : undefined
  );

  const administrativeValidityDateErrorLabelId = (
    isNotDefined(administrativeValidityDate) && isDefined(exposureMaturityDate)
      ? "components.AuthorizationEditor.date.exposureMaturityDate.notDefined" :
      isDefined(administrativeValidityDate) && isDefined(validityStartDate) && isAfter(validityStartDate, administrativeValidityDate)
        ? "components.AuthorizationEditor.date.administrativeValidityDate.mustBeGreater"
        : undefined
  );

  const exposureMaturityDateErrorLabelId = (
    isDefined(administrativeValidityDate) && isDefined(exposureMaturityDate) && isAfter(administrativeValidityDate, exposureMaturityDate)
      ? "components.AuthorizationEditor.date.exposureMaturityDate.mustBeGreater"
      : undefined
  );

  return {
    validityStartDate:          validityStartDateErrorLabelId,
    administrativeValidityDate: administrativeValidityDateErrorLabelId,
    exposureMaturityDate:       exposureMaturityDateErrorLabelId
  };
};