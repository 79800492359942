import classnames from "classnames";
import { PropsWithChildren } from "react";



export type StepProps = {
  status: "checked" | "current" | "inactive" | "idle",
}


function Step(props: PropsWithChildren<StepProps>): React.JSX.Element {
  const { status, children } = props;

  const icon = iconMap[status];

  return (
    <li className={classnames("stepper-step", {
      "fill":     status === "checked",
      "current":  status === "current",
      "inactive": status === "inactive"
    })}>
      <div className="stepper-step-inner">
        <div className="stepper-indicator">
          {icon}
        </div>
      </div>

      <span className="stepper-label">
        {children}
      </span>
    </li>
  );
}


const iconMap = {
  checked:  <em className="icon">check</em>,
  current:  <em className="icon">fiber_manual_record</em>,
  inactive: undefined,
  idle:     undefined
};

export default Step;