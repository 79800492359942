import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";



export interface FormRowProps {
  labelId: string;
  feedbackLabelId?: string;
  helpLabelId?: string;
  className?: string;
}


function FormRow(props: PropsWithChildren<FormRowProps>) {
  const { labelId, feedbackLabelId, helpLabelId, className, children } = props;

  return <FormRowStyle className={className}>
    <label className="form-label">
      <FormattedMessage id={labelId}/>
    </label>

    <div>
      {children}
    </div>

    {
      isDefined(feedbackLabelId) && (
        <div className="valid-feedback">
          <FormattedMessage id={feedbackLabelId}/>
        </div>
      )
    }

    {
      isDefined(helpLabelId) && (
        <div className="form-text">
          <FormattedMessage id={helpLabelId}/>
        </div>
      )
    }
  </FormRowStyle>;
}


// input-container-horizontal can't be used
// because it updates the style of the pickers
const FormRowStyle = styled.div`
  display: grid;
  grid-template-areas:
        ". ."
        "HELPTEXT HELPTEXT";
  grid-auto-columns: 1fr 1fr;
  gap: 8px;
  min-height: 40px;
`;

export default FormRow;