import { FocusEvent, FormEvent, ForwardedRef, forwardRef } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";



export interface NumberInputProps {
  name: string;
  placeholderLabelId?: string;
  value?: string;
  editable?: boolean;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}


function NumberInput(props: NumberInputProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    placeholderLabelId,
    value,
    editable: isEditable = true,
    onChange,
    onBlur
  } = props;

  const intl = useIntl();

  const placeholder = isDefined(placeholderLabelId)
    ? intl.formatMessage({ id: placeholderLabelId })
    : undefined;

  if (!isEditable) {
    return (
      <span>
        {
          isDefined(value) && value !== ""
            ? <FormattedNumber value={Number(value)}/>
            : ""
        }
      </span>
    );
  }

  return (
    <input
      ref={externalRef}
      name={name}
      placeholder={placeholder}
      type="number"
      className="form-control"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}


export default forwardRef(NumberInput);