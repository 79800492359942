import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentContext } from "@/core/contexts/UserContext";



const BASE_URL = window.location.origin;

export type AppVersion = {
  version: string
  buildNumber: string
  shortSha: string
}

export const versionApi = createApi({
  reducerPath: "version",
  baseQuery:   fetchBaseQuery({
    baseUrl:        BASE_URL,
    prepareHeaders: headers => {
      const { sgConnect } = getCurrentContext();
      const authHeader = sgConnect?.getAuthorizationHeader();

      headers.set("X-Client-Scope", sgConnect?.getGrantedScope() ?? "");
      headers.set("Authorization", authHeader as string);
      return headers;
    }
  }),

  endpoints: builder => (
    {
      getAppVersion: builder.query<AppVersion, void>({
        query:        () => (
          {
            url: `version.json`
          }
        ),
        providesTags: [ "version" ]
      })

    }
  )
});

export const useAppVersion = versionApi.endpoints.getAppVersion.useQuery;