import { FocusEvent, FormEvent, ForwardedRef, forwardRef } from "react";
import { FormattedDate, useIntl } from "react-intl";
import { parse } from "date-fns";
import { isDefined } from "@sgme/fp";
import { SgDatepicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { HiddenInput, useUpdateValue } from "@/components/sg-ui/form/_utils";



export interface DatePickerProps {
  name: string;
  placeholderLabelId?: string;
  value?: string;
  disabled?: boolean;
  editable?: boolean;
  errorLabelId?: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}


function DatePicker(props: DatePickerProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    placeholderLabelId,
    value:    defaultValue,
    disabled: isDisabled = false,
    editable: isEditable = true,
    errorLabelId,
    onChange,
    onBlur
  } = props;

  const [ innerRef, value, updateDate ] = useUpdateValue(externalRef, defaultValue);

  const intl = useIntl();

  const placeholder = isDefined(placeholderLabelId)
    ? intl.formatMessage({ id: placeholderLabelId })
    : "dd-MM-yyyy";

  const datePickerInitialValue = isDefined(value) && value.length > 0
    ? parse(value, "dd-MM-yyyy", new Date())
    : undefined;

  if (!isEditable) {
    return (
      <span>
        {
          isDefined(value) && value !== "" && (
            <FormattedDate value={datePickerInitialValue} dateStyle="medium"/>
          )
        }
      </span>
    );
  }

  const errorMessage = isDefined(errorLabelId)
    ? intl.formatMessage({ id: errorLabelId })
    : undefined;

  return (
    <>
      <SgDatepicker
        key={value}
        initialValue={datePickerInitialValue?.valueOf().toString()}
        locale={intl.locale}
        placeholder={placeholder}
        state={isDefined(errorLabelId) ? "invalid" : undefined}
        validationMessage={errorMessage}
        onDateSelected={event => updateDate(event.detail.formatted)}
        onInputCleared={() => updateDate("")}
      />

      <HiddenInput
        ref={innerRef}
        name={name}
        type="text"
        value={value ?? ""}
        disabled={isDisabled || !isEditable}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
}


export default forwardRef(DatePicker);