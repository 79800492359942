import { isDefined } from "@sgme/fp";
import { AuthorizationCreationInput, AuthorizationResponse } from "@credit-control-risk/common";
import { formatJSONDate, parseJSONDate } from "@/utils/date";
import { formatYNBoolean, parseJsonBoolean } from "@/utils/boolean";
import { checkMatchers, dateNotEmpty, FieldMatcher, hasError, stringNotEmpty, yesOrNoNotEmpty } from "@/utils/matcher";
import { AuthorizationEditorState, AuthorizationExposureType, AuthorizationFormData } from "@/components/AuthorizationEditor/store/state";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";
import { AppDispatch } from "@/store";
import { webapi } from "@/store/api/webapi";



export const canSaveOrCreateAuthorization = (state: AuthorizationEditorState) => {
  const allMatchers: Partial<Record<keyof AuthorizationFormData, FieldMatcher>> = {
    validityStartDate:           dateNotEmpty,
    exposureMaturityDate:        dateNotEmpty,
    administrativeValidityDate:  dateNotEmpty,
    authorizationAmount:         stringNotEmpty,
    authorizationAmountCurrency: stringNotEmpty,
    exposureDefaultType:         stringNotEmpty,
    protectionIndication:        yesOrNoNotEmpty
  };

  return checkMatchers(allMatchers, state.form) && !hasError(state.formErrors);
};

export const authorizationResponseToAuthorizationFormData = (authorization: AuthorizationResponse): AuthorizationFormData => (
  {
    validityStartDate:           parseJSONDate(authorization.validityStartDate) ?? "",
    exposureMaturityDate:        parseJSONDate(authorization.exposureMaturityDate) ?? "",
    administrativeValidityDate:  parseJSONDate(authorization.administrativeValidityDate) ?? "",
    authorizationAmount:         String(authorization.amount),
    authorizationAmountCurrency: authorization.currency ?? "",
    exposureDefaultType:         (
                                   authorization.exposureDefaultType ?? "SIN"
                                 ) as AuthorizationExposureType, // TODO: is the default value correct if no data ?
    protectionIndication:        parseJsonBoolean(authorization.protectionIndication) ?? "no", // TODO: is the default value correct if no data ?

    comments: authorization.comment ?? "",

    countryRegistration: authorization.countryRegistration ?? "LU"
  }
);

export const saveAuthorization = async (rootId: string, groupProductCodeId: string, authorizationChange: AuthorizationChange, dispatch: AppDispatch, authorizationId?: string) => {
  const safetyPayload: AuthorizationCreationInput = {
    groupProductCodeId,

    validityStartDate:          formatJSONDate(authorizationChange.validityStartDate),
    exposureMaturityDate:       formatJSONDate(authorizationChange.exposureMaturityDate),
    administrativeValidityDate: formatJSONDate(authorizationChange.administrativeValidityDate),
    amount:                     Number(authorizationChange.authorizationAmount),
    currency:                   authorizationChange.authorizationAmountCurrency,
    exposureDefaultType:        authorizationChange.exposureDefaultType,
    protectionIndication:       formatYNBoolean(authorizationChange.protectionIndication),
    comment:                    authorizationChange.comments,

    partyLocalCode: rootId,

    countryRegistration: authorizationChange.countryRegistration
  };

  const newAuthorizationResponse = isDefined(authorizationId)
    ? await dispatch(webapi.endpoints.saveAuthorization.initiate({ id: authorizationId, safety: safetyPayload }))
    : await dispatch(webapi.endpoints.createAuthorization.initiate(safetyPayload));

  if ("data" in newAuthorizationResponse) {
    return newAuthorizationResponse.data.authorizationRef!;
  }

  throw new Error("error"); // TODO:
};