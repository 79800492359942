export function MiniFooter(): React.JSX.Element {
  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
    />
  );
}
