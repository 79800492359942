import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isDefined, isNotDefined } from "@sgme/fp";
import { isAfter } from "date-fns";
import {
  DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID,
  DEFAULT_ASSET_TYPE_QUERY_BY_SAFETY_TYPE_ID,
  DEFAULT_SAFETY_TYPE_ID,
  DEFAULT_VALIDITY_END_DATE,
  initSafetyEditorState,
  initSafetyFormData,
  SafetyEditorStep,
  SafetyFormData,
  SelectedSafetyAssetReference
} from "@/components/SafetyEditor/store/state";
import { canGoToNextStep, canGoToPreviousStep } from "@/components/SafetyEditor/store/api";
import { SafetyType } from "@/components/SafetyEditor/store/types";
import { parseDate } from "@/utils/date";



export const safetyEditorSlice = createSlice({
  name:         "safety-editor",
  initialState: initSafetyEditorState(),
  reducers:     {
    initSafetyForm(state, action: PayloadAction<{
      rootId: string,
      rct: string,
      safety: {
        id: string,
        data: SafetyFormData
      } | undefined,
      safetyTypeId: string,
      safetyType: SafetyType,
      assetTypeId?: string,
      selectedAssetReferences: SelectedSafetyAssetReference[]
      areManyAssetsSelectable: boolean,
      thirdPartySafetyProvider?: string,
      isEditable: boolean
    }>) {
      const selectedSafetyTypeId = action.payload?.safetyTypeId ?? DEFAULT_SAFETY_TYPE_ID;

      const form = action.payload.safety?.data ?? initSafetyFormData(selectedSafetyTypeId, action.payload.thirdPartySafetyProvider ?? "");

      return {
        rootId:   action.payload.rootId,
        rct:      action.payload.rct,
        safetyId: action.payload.safety?.id,

        isEditable:              action.payload.isEditable,
        areManyAssetsSelectable: action.payload.areManyAssetsSelectable,

        currentStep:    isDefined(action.payload.safety) ? SafetyEditorStep.EDIT_SAFETY : SafetyEditorStep.SELECT_SAFETY_TYPE,
        lastActiveStep: SafetyEditorStep.EDIT_SAFETY,

        safetyTypeQuery:    "GAGE D'ESPECES A MONTANT FIXE", // "CASH COLLATERAL, FIXED AMOUNT", // TODO: how to use the user language ?
        selectedSafetyTypeId,
        selectedSafetyType: action.payload?.safetyType ?? SafetyType.COLLATERAL_ASSET,

        // @ts-ignore
        assetTypeQuery:          DEFAULT_ASSET_TYPE_QUERY_BY_SAFETY_TYPE_ID[selectedSafetyTypeId] ?? "",
        selectedAssetTypeId:     action.payload?.assetTypeId ?? DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID[DEFAULT_SAFETY_TYPE_ID],
        selectedAssetReferences: action.payload.selectedAssetReferences,

        form,
        formErrors: checkFormErrors(form)
      };
    },

    setEditable(state) {
      return {
        ...state,

        isEditable: true
      };
    },

    reset(state) {
      return initSafetyEditorState();
    },

    setSafetyTypeQuery(state, action: PayloadAction<string>) {
      return {
        ...state,

        safetyTypeQuery: action.payload
      };
    },

    setSafetyType(state, action: PayloadAction<{
      id: string,
      type: SafetyType
    } | undefined>) {
      const safetyTypeId = action.payload?.id;

      const form: SafetyFormData = {
        ...state.form,

        validityEndDate:          safetyTypeId === DEFAULT_SAFETY_TYPE_ID ? DEFAULT_VALIDITY_END_DATE : "", // only use default value for CASH COLLATERAL, FIXED AMOUNT
        thirdPartySafetyProvider: safetyTypeId === DEFAULT_SAFETY_TYPE_ID ? state.rct ?? "" : "" // only use default value for CASH COLLATERAL, FIXED AMOUNT,
      };

      return {
        ...state,

        selectedSafetyTypeId: action.payload?.id,
        selectedSafetyType:   action.payload?.type,

        // TODO: how to find the label for the user language, and not only the french version ?
        assetTypeQuery:      isDefined(action.payload?.id)
                               // @ts-ignore
                               ? DEFAULT_ASSET_TYPE_QUERY_BY_SAFETY_TYPE_ID[action.payload.id] ?? ""
                               : "",
        selectedAssetTypeId: isDefined(action.payload?.id)
                               // @ts-ignore
                               ? DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID[action.payload.id]
                               : undefined,

        form,
        formErrors: checkFormErrors(form)
      };
    },

    setAssetTypeQuery(state, action: PayloadAction<string>) {
      return {
        ...state,

        assetTypeQuery: action.payload
      };
    },

    toggleAssetTypeId(state, action: PayloadAction<string>) {
      if (!isDefined(action.payload) || state.selectedAssetTypeId === action.payload) {
        return {
          ...state,

          selectedAssetTypeId: undefined
        };
      }


      return {
        ...state,

        selectedAssetTypeId: action.payload
      };
    },

    // only for the creation
    setAssetIds(state, action: PayloadAction<string[]>) {
      return {
        ...state,

        selectedAssetReferences: action.payload.map(sourceProductId => (
          { id: undefined, sourceProductId }
        ))
      };
    },

    updateFormField(state, action: PayloadAction<{
      name: string,
      value: string
    }>) {
      const form = {
        ...state.form,

        [action.payload.name]: action.payload.value
      };

      return {
        ...state,

        form,
        formErrors: checkFormErrors(form)
      };
    },

    goToPreviousStep(state) {
      if (!canGoToPreviousStep(state)) {
        return state;
      }

      let previousStep = state.currentStep;

      if (state.currentStep === SafetyEditorStep.EDIT_SAFETY) {
        previousStep = state.selectedSafetyType === SafetyType.COLLATERAL_MORTGAGE || state.selectedSafetyType === SafetyType.COLLATERAL_ASSET
          ? SafetyEditorStep.SELECT_ASSET_TYPE
          : SafetyEditorStep.SELECT_SAFETY_TYPE;
      }
      else if (state.currentStep === SafetyEditorStep.SELECT_ASSET_TYPE) {
        previousStep = SafetyEditorStep.SELECT_SAFETY_TYPE;
      }

      return {
        ...state,

        currentStep: previousStep
      };
    },

    goToNextStep(state) {
      if (!canGoToNextStep(state)) {
        return state;
      }

      let nextStep = state.currentStep;

      if (state.currentStep === SafetyEditorStep.SELECT_SAFETY_TYPE) {
        nextStep = (
          state.selectedSafetyType === SafetyType.COLLATERAL_MORTGAGE || state.selectedSafetyType === SafetyType.COLLATERAL_ASSET
        )
          ? SafetyEditorStep.SELECT_ASSET_TYPE
          : SafetyEditorStep.EDIT_SAFETY;
      }
      else if (state.currentStep === SafetyEditorStep.SELECT_ASSET_TYPE) {
        nextStep = SafetyEditorStep.EDIT_SAFETY;
      }

      return {
        ...state,

        currentStep: nextStep
      };
    }
  }
});


export const {
  initSafetyForm,
  setEditable,
  reset,

  setSafetyTypeQuery,
  setSafetyType,

  setAssetTypeQuery,
  toggleAssetTypeId,
  setAssetIds,

  updateFormField,

  goToPreviousStep,
  goToNextStep
} = safetyEditorSlice.actions;


const checkFormErrors = (form: SafetyFormData): Partial<Record<keyof SafetyFormData, string>> => {
  // Safety validity start date<= Administrative end date <= Safety validity end date

  const validityStartDate = parseDate(form.validityStartDate);
  const administrativeValidityEndDate = parseDate(form.administrativeValidityEndDate);
  const validityEndDate = parseDate(form.validityEndDate);

  const validityStartDateErrorLabelId = (
    isDefined(administrativeValidityEndDate) && isNotDefined(validityStartDate)
      ? "components.SafetyEditor.date.validityStartDate.notDefined"
      : undefined
  );

  const administrativeValidityEndDateErrorLabelId = (
    isNotDefined(administrativeValidityEndDate) && isDefined(validityEndDate)
      ? "components.SafetyEditor.date.administrativeValidityEndDate.notDefined" :
      isDefined(administrativeValidityEndDate) && isDefined(validityStartDate) && isAfter(validityStartDate, administrativeValidityEndDate)
        ? "components.SafetyEditor.date.administrativeValidityEndDate.mustBeGreater"
        : undefined
  );

  const validityEndDateErrorLabelId = (
    isDefined(administrativeValidityEndDate) && isDefined(validityEndDate) && isAfter(administrativeValidityEndDate, validityEndDate)
      ? "components.SafetyEditor.date.validityEndDate.mustBeGreater"
      : undefined
  );

  return {
    validityStartDate:             validityStartDateErrorLabelId,
    administrativeValidityEndDate: administrativeValidityEndDateErrorLabelId,
    validityEndDate:               validityEndDateErrorLabelId
  };
};