import React, { ChangeEvent, FormEvent, ForwardedRef, forwardRef } from "react";
import { useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";



export interface TextareaInputProps {
  name: string;
  placeholderLabelId?: string;
  value?: string;
  format?: RegExp;
  rows?: number;
  textcase?: "uppercase" | "lowercase";
  editable?: boolean;
  onChange: (event: FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}


function TextareaInput(props: TextareaInputProps, externalRef: ForwardedRef<HTMLTextAreaElement>) {
  const {
    name,
    placeholderLabelId,
    value,
    format,
    rows: rowCount,
    textcase,
    editable: isEditable = true,
    onChange,
    onBlur
  } = props;

  const intl = useIntl();


  const placeholder = isDefined(placeholderLabelId)
    ? intl.formatMessage({ id: placeholderLabelId })
    : undefined;

  const change = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (isDefined(format)) {
      if (textcase === "uppercase") {
        event.currentTarget.value = event.currentTarget.value.toUpperCase();
      }

      // if uppercase
      if (textcase === "lowercase") {
        event.currentTarget.value = event.currentTarget.value.toLowerCase();
      }

      const proposedValue = event.currentTarget.value;

      if (!format.test(proposedValue)) {
        event.preventDefault();
        return;
      }
    }

    onChange?.(event);
  };

  return isEditable
    ? <textarea
      ref={externalRef}
      name={name}
      placeholder={placeholder}
      rows={rowCount}
      className="form-control"
      value={value}
      onChange={change}
      onBlur={onBlur}
    />
    : <span>{value}</span>;
}


export default forwardRef(TextareaInput);