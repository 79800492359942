import { ChangeEvent, useCallback } from "react";



interface SwitcherProps {
  label: string;
  id: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  editable?: boolean;
}


export function Switcher(props: SwitcherProps): React.JSX.Element {
  const {
    id,
    label,
    checked,
    disabled: isDisabled = false,
    editable: isEditable = true,
    onChange
  } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.currentTarget.checked);
    },
    [ onChange ]
  );

  return (
    <div className="form-check form-switch d-flex align-items-center">
      <input
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        disabled={isDisabled || !isEditable}
        className="form-check-input"
        id={id}
      />
      <label className="form-check-label mb-0" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}