import { useState } from "react";
import { PageRoot } from "@/routes/_layout/page-styles";
import Button from "@/components/sg-ui/form/Button";



function TestRoute(): React.JSX.Element {
  const [ isOpen, setIsOpen ] = useState(true);

  const onToggle = () => setIsOpen(prevValue => !prevValue);

  return (
    <PageRoot>
      <div className="container-xl h-100" style={{ overflow: "hidden" }}>
        {/*<SafetyEditor*/}
        {/*  rootId="32837283782"*/}
        {/*  safetyId={undefined}*/}
        {/*  opened={isOpen}*/}
        {/*  toggle={onToggle}*/}
        {/*/>*/}

        {/*<AuthorizationEditor*/}
        {/*  rootId="70017205"*/}
        {/*  authorizationId={undefined}*/}
        {/*  editable*/}
        {/*  opened={isOpen}*/}
        {/*  toggle={onToggle}*/}
        {/*  onSaveOrCreate={() => {}}*/}
        {/*/>*/}

        {/*<SectionList>*/}
        {/*  <Section labelId="one">*/}
        {/*    ONE*/}
        {/*  </Section>*/}

        {/*  <Section labelId="two">*/}
        {/*    DEUX*/}
        {/*  </Section>*/}

        {/*  <Section labelId="3">*/}
        {/*    three*/}
        {/*  </Section>*/}

        {/*  <Section labelId="quatre">*/}
        {/*    FOUR*/}
        {/*  </Section>*/}
        {/*</SectionList>*/}

        <Button loading>
          ACTION
        </Button>
      </div>
    </PageRoot>
  );
}


export default TestRoute;