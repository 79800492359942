import { IntlShape } from "react-intl";
import { isDefined } from "@sgme/fp";



export const formatYesNo = (value: "O" | "Y" | "N" | undefined, intl: IntlShape) => {
  const valueLabelId = (
    // eslint-disable-next-line no-nested-ternary
    (
      value === "O" || value === "Y"
    )
      ? "constants.yes" :
      value === "N"
        ? "constants.no"
        : undefined // only if macgda change the value
  );

  return isDefined(valueLabelId)
    ? intl.formatMessage({ id: valueLabelId })
    : "";
};

export const formatDate = (value: string | undefined, intl: IntlShape) => isDefined(value) ? intl.formatDate(new Date(value), {
  day:   "2-digit",
  month: "short",
  year:  "2-digit"
}) : "";