import { PropsWithChildren, useState } from "react";
import styled from "@emotion/styled";
import { Collapse } from "reactstrap";
import HStack from "@/components/sg-ui/layout/HStack";
import Box from "@/components/sg-ui/layout/Box";
import Chevron from "@/components/sg-ui/icon/Chevron";
import { DataFolder, ID_COLUMN_WIDTH } from "@/components/DataTreeSelector/types";
import SelectableLevel from "@/components/DataTreeSelector/SelectableLevel";
import { getFolderCount } from "@/components/DataTreeSelector/api";



export type FolderLevelProps = {
  folder: DataFolder
  defaultIsOpen?: boolean
  selectedId: string | undefined
  toggleIdSelection: (id: string) => void
}


function FolderLevel(props: PropsWithChildren<FolderLevelProps>): React.JSX.Element {
  const { folder, defaultIsOpen = true, selectedId, toggleIdSelection } = props;

  const [ isOpen, setIsOpen ] = useState(defaultIsOpen);

  const toggle = () => setIsOpen(!isOpen);

  const count = getFolderCount(folder);

  return (
    <Styles>
      <HStack gap="2" onClick={toggle}>
        <Chevron className="ms-2" isOpen={isOpen}/>
        <Box className="text-large font-weight-semibold">{folder.name}</Box>
        <span className="count">{count}</span>
        <Box flex="expand"/>
      </HStack>

      <HStack onClick={toggle}>
        <Box>
          {folder.id}
        </Box>
      </HStack>

      <Collapse isOpen={isOpen}>
        {
          folder.children.map(child => (
            "children" in child
              ? <FolderLevel key={child.id} folder={child} selectedId={selectedId}
                             toggleIdSelection={toggleIdSelection}/>
              : <SelectableLevel key={child.id} element={child} selectedId={selectedId}
                                 toggleIdSelection={toggleIdSelection}/>
          ))
        }
      </Collapse>
    </Styles>
  );
}


export default FolderLevel;

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ID_COLUMN_WIDTH}px;

  & > div:nth-child(1) {
    cursor: pointer;

    & > div {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -.01em;
    }
  }

  & > div:nth-child(2) {
    cursor: pointer;

    & > div {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.5;
      color: var(--interface-secondary, #6E777A);

    }
  }

  & > div:nth-child(3) {
    grid-column: 1 / 3;
    padding: 0 0 0 28px;
  }

  .count {
    padding: 0.125rem 0.46rem;
    font-size: 14px;
    color: #000;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 20px;
  }
`;