import { addYears, format } from "date-fns";
import { CountryCode } from "@/components/sg-ui/form/countries";
import { SafetyType } from "@/components/SafetyEditor/store/types";
import { FormDate } from "@/utils/matcher";



export type SafetyEditorState = {
  rootId: string | undefined
  rct: string | undefined
  safetyId: string | undefined

  isEditable: boolean
  areManyAssetsSelectable: boolean

  currentStep: SafetyEditorStep
  lastActiveStep: SafetyEditorStep

  safetyTypeQuery: string
  selectedSafetyTypeId: string | undefined
  selectedSafetyType: SafetyType | undefined // get the type from the loaded types from macgda

  assetTypeQuery: string
  selectedAssetTypeId: string | undefined
  selectedAssetReferences: SelectedSafetyAssetReference[]

  form: SafetyFormData
  formErrors: Partial<Record<keyof SafetyFormData, string>>
}

export type SelectedSafetyAssetReference = {
  id?: string
  sourceProductId: string
}

export enum SafetyEditorStep {
  SELECT_SAFETY_TYPE = 0,
  SELECT_ASSET_TYPE = 1,
  EDIT_SAFETY = 2
}

export type SafetyFormData = {
  // safety properties
  indicatorTypeCoverage: IndicatorTypeCoverage | ""
  fixeAmount: string
  fixeAmountCurrency: string
  percentageExposureCovered: string
  maxAmountCovered: string
  maxAmountCoveredCurrency: string
  validityStartDate: FormDate
  validityEndDate: FormDate
  administrativeValidityEndDate: FormDate
  thirdPartySafetyProvider: string // /^[A-Z]{0,3}([0-9]{0,4})?$/ see below
  // accountingIndicator: boolean
  legalValidityIndicator: "yes" | "no" | ""
  // eligibilityToBaselII: boolean
  indicationCreditRiskMitigatoryEnforceability: "yes" | "no" | ""
  // collateral
  subordinationRanking: SubordinationRanking | ""

  coverageAmountCollateralPrevailingOverSg: string
  coverageAmountCollateralPrevailingOverSgCurrency: string
  frequencyCollateralReevaluations: FrequencyCollateralReevaluation | ""

  valuationMethod: ValuationMethod | ""
  liquidityIndicator: LiquidityIndicator | ""

  applicableLawCountryCode: CountryCode | ""

  marketValue: string
  marketValueCurrency: string
}

export type IndicatorTypeCoverage = "F" | "P" | "C"
export type FrequencyCollateralReevaluation = "Q" | "H" | "B" | "M" | "T" | "S" | "A" | "2A" | "3A"
export type SubordinationRanking = "1" | "2" | "3" | "4" | "5"
export type ValuationMethod = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8"
export type LiquidityIndicator = "1" | "2" | "3" | "4"

export const PROVIDER_PROPERTY_REF_FORMAT = /^[A-Z]{0,3}([0-9]{0,4})?$/;

export const DEFAULT_SAFETY_TYPE_ID = "71010";
export const DEFAULT_VALIDITY_END_DATE = "31-12-2099";

// TODO: how to find the label for the user language, and not only the french version ?
export const DEFAULT_ASSET_TYPE_QUERY_BY_SAFETY_TYPE_ID = {
  "71013": "Compte",
  "62010": "Valeurs de placement",
  "66011": "Produits d'assurance",
  "67010": "Valeurs de placement",
  "21010": "Appartement",
  "21019": "Terrain seul commercial",
  "21011": "Maison",
  "21015": "Immeuble à usage commercial",
  "21012": "Bureaux",
  "21014": "Immeuble industriel",
  "21013": "Terrain seul résidentiel",
  "21017": "Entrepot",
  "21018": "Hotel",
  "63011": "Valeurs de placement",
  "66010": "Valeurs de placement",
  "63014": "Valeurs de placement",
  "62014": "Valeurs de placement",
  "71014": "Espèces",
  "71010": "Espèces"
};

export const DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID = {
  "71013": "426",
  "62010": "535",
  "66011": "560",
  "67010": "535",
  "21010": "400",
  "21019": "409",
  "21011": "401",
  "21015": "407",
  "21012": "402",
  "21014": "405",
  "21013": "408",
  "21017": "404",
  "21018": "403",
  "63011": "535",
  "66010": "535",
  "63014": "535",
  "62014": "535",
  "71014": "425",
  "71010": "425"
};

export const initSafetyEditorState = (): SafetyEditorState => (
  {
    rootId:   undefined,
    rct:      undefined,
    safetyId: undefined,

    isEditable:              true,
    areManyAssetsSelectable: false,

    currentStep:    SafetyEditorStep.SELECT_SAFETY_TYPE,
    lastActiveStep: SafetyEditorStep.EDIT_SAFETY,

    // default value "GAGE D'ESPECES A MONTANT FIXE" | "CASH COLLATERAL, FIXED AMOUNT"
    safetyTypeQuery: "GAGE D'ESPECES A MONTANT FIXE", // "CASH COLLATERAL, FIXED AMOUNT", // TODO: how to use the user language ?
    // default selected safety type = CASH COLLATERAL, FIXED AMOUNT
    selectedSafetyTypeId: DEFAULT_SAFETY_TYPE_ID,
    selectedSafetyType:   SafetyType.COLLATERAL_ASSET,


    assetTypeQuery:          DEFAULT_ASSET_TYPE_QUERY_BY_SAFETY_TYPE_ID[DEFAULT_SAFETY_TYPE_ID] ?? "",
    selectedAssetTypeId:     DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID[DEFAULT_SAFETY_TYPE_ID],
    selectedAssetReferences: [],

    // TODO: the default values can be global, or depend on the safety type
    form:       initSafetyFormData("", ""),
    formErrors: {}
  }
);

export const initSafetyFormData = (safetyTypeId: string, thirdPartySafetyProvider: string): SafetyFormData => (
  {
    // safety properties
    indicatorTypeCoverage:                        "F",
    fixeAmount:                                   "",
    fixeAmountCurrency:                           "EUR",
    percentageExposureCovered:                    "",
    maxAmountCovered:                             "",
    maxAmountCoveredCurrency:                     "EUR",
    validityStartDate:                            format(new Date(), "dd-MM-yyyy"),
    validityEndDate:                              safetyTypeId === DEFAULT_SAFETY_TYPE_ID ? DEFAULT_VALIDITY_END_DATE : "", // only use default value for CASH COLLATERAL, FIXED AMOUNT
    administrativeValidityEndDate:                format(addYears(new Date(), 1), "dd-MM-yyyy"),
    thirdPartySafetyProvider:                     safetyTypeId === DEFAULT_SAFETY_TYPE_ID ? thirdPartySafetyProvider : "", // only use default value for CASH COLLATERAL, FIXED AMOUNT,
    legalValidityIndicator:                       "yes",
    indicationCreditRiskMitigatoryEnforceability: "yes",

    // collateral
    subordinationRanking: "1",

    coverageAmountCollateralPrevailingOverSg:         "0",
    coverageAmountCollateralPrevailingOverSgCurrency: "EUR",
    frequencyCollateralReevaluations:                 "Q",

    valuationMethod:    "6",
    liquidityIndicator: "2",

    applicableLawCountryCode: "LU",

    marketValue:         "",
    marketValueCurrency: ""
  }
);

