import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { isDefined, isNotDefined } from "@sgme/fp";
import { CollateralResponse, GuaranteeResponse } from "@credit-control-risk/common";
import SafetyGroupTypeSelector from "@/components/SafetyEditor/SafetyGroupTypeSelector";
import VStack from "@/components/sg-ui/layout/VStack";
import Box from "@/components/sg-ui/layout/Box";
import AssetTypeSelector from "@/components/SafetyEditor/AssetTypeSelector";
import SafetyInternalEditor from "@/components/SafetyEditor/SafetyInternalEditor";
import { AppState } from "@/store";
import { goToNextStep, goToPreviousStep, initSafetyForm, safetyEditorSlice, setEditable } from "@/components/SafetyEditor/store/slice";
import { DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID, DEFAULT_SAFETY_TYPE_ID, SafetyEditorState, SafetyEditorStep } from "@/components/SafetyEditor/store/state";
import { SafetyAssetReference, SafetyChange, SafetyType } from "@/components/SafetyEditor/store/types";
import { useSafetyType } from "@/components/SafetyEditor/store/hooks";
import { canGoToNextStep, canGoToPreviousStep, canSaveOrCreateSafety, getSafetyType, getSelectedSafetyTypeCode, safetyToSafetyFormData } from "@/components/SafetyEditor/store/api";
import { useCounterparty, useGetSafeties, useGetSafetyElementaryTypes } from "@/store/api/webapi";
import { withInitReduxState } from "@/utils/withInitReduxState";
import HStack from "@/components/sg-ui/layout/HStack";
import Button from "@/components/sg-ui/form/Button";
import Tooltip from "@/components/Tooltip";

// TODO: integrate https://groupsg001.sharepoint.com/:x:/r/sites/GBSU-FRM-LUX/feature-team-ris/_layouts/15/Doc.aspx?sourcedoc=%7B99a461d6-9ef8-46fd-bd49-e87dc40a15f9%7D&action=edit&wdinitialsession=acf67647-4b74-4873-b6cd-d3495f4eac84&wdrldsc=4&wdrldc=1&wdrldr=ContinueInExcel


export type SafetyEditorProps = {
  rootId: string
  manyAssetsSelectable: boolean
  safetyId?: string
  excludedAssetSourceProductIds?: string[];
  opened: boolean
  editable: boolean
  toggle: () => void
  onEdit?: () => void
  onSaveOrCreate: (safety: SafetyChange) => void
}


function SafetyEditor(props: SafetyEditorProps): React.JSX.Element | null {
  const {
    rootId,
    excludedAssetSourceProductIds,
    opened:   isOpened,
    editable: editableProps,
    toggle,
    onEdit,
    onSaveOrCreate
  } = props;

  const dispatch = useDispatch();

  const data = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    const canCreate = canSaveOrCreateSafety(state);

    return {
      safetyId:      state.safetyId,
      currentStep:   state.currentStep,
      canGoPrevious: canGoToPreviousStep(state),
      canGoNext:     canGoToNextStep(state),
      canCreate,
      isLastStep:    state.currentStep === SafetyEditorStep.EDIT_SAFETY,

      selectedSafetyTypeId:    state.selectedSafetyTypeId,
      selectedAssetTypeId:     state.selectedAssetTypeId,
      selectedAssetReferences: state.selectedAssetReferences,
      form:                    state.form,
      isEditable:              state.isEditable
    };
  });

  const {
    safetyId,
    currentStep,
    canGoPrevious,
    canGoNext,
    canCreate,
    isLastStep,
    selectedSafetyTypeId,
    selectedAssetTypeId,
    selectedAssetReferences,
    form,
    isEditable
  } = data;

  useEffect(() => {
    if (!isEditable && editableProps) {
      dispatch(setEditable());
    }
  }, [ dispatch, isEditable, editableProps ]);

  const { safetyType: selectedSafetyType } = useSafetyType();

  if (!isDefined(rootId)) {
    return null;
  }

  const onGoToPreviousStep = () => {
    dispatch(goToPreviousStep());
  };

  const onGoToNextStep = () => {
    dispatch(goToNextStep());
  };

  const createOrSaveSafety = () => {
    if (!isDefined(selectedSafetyTypeId) || !isDefined(selectedSafetyType)) {
      return;
    }

    if (isDefined(safetyId)) {
      onSaveOrCreate({
        ...form,
        safetyId,
        safetyTypeId: selectedSafetyTypeId,
        safetyType:   selectedSafetyType,
        // assetTypeId:  selectedAssetTypeId,
        assets: selectedAssetReferences as SafetyAssetReference[]
      });
    }
    else {
      onSaveOrCreate({
        ...form,
        safetyId:              undefined,
        safetyTypeId:          selectedSafetyTypeId,
        safetyType:            selectedSafetyType,
        assetTypeId:           selectedAssetTypeId,
        assetSourceProductIds: selectedAssetReferences.map(ref => ref.sourceProductId)
      });
    }

    toggle();
  };

  const titleLabelId = (
    safetyId === undefined
      ? "components.SafetyEditor.title.create" :
      isEditable
        ? "components.SafetyEditor.title.edit"
        : "components.SafetyEditor.title.view"
  );

  return (
    <Offcanvas
      isOpen={isOpened}
      direction="end"
      toggle={toggle}
      backdrop={false}
      style={{ width: 1320 }}
    >
      <OffcanvasHeader toggle={toggle}>
        <h2>
          <FormattedMessage id={titleLabelId}/>
        </h2>
      </OffcanvasHeader>

      <OffcanvasBody className="p-0">
        <VStack fullHeight padding="3">
          <Box flex="expand">
            {
              currentStep === SafetyEditorStep.SELECT_SAFETY_TYPE && (
                <SafetyGroupTypeSelector/>
              )
            }

            {
              currentStep === SafetyEditorStep.SELECT_ASSET_TYPE && (
                <AssetTypeSelector/>
              )
            }

            {
              currentStep === SafetyEditorStep.EDIT_SAFETY && (
                <SafetyInternalEditor rootId={rootId} excludedAssetSourceProductIds={excludedAssetSourceProductIds}/>
              )
            }
          </Box>

          <HStack gap="2" className="mt-3">
            {
              !isDefined(safetyId) && canGoPrevious && (
                <Button onClick={onGoToPreviousStep} variant="flat" color="primary" icon="start" disabled={!canGoPrevious}>
                  <i className="icon icon-sm">arrow_back</i> PREVIOUS
                </Button>
              )
            }

            <Box flex="expand"/>

            <Button onClick={toggle} variant="flat" color="primary">
              CANCEL
            </Button>

            {
              !isDefined(safetyId) && !isLastStep && (
                <Button onClick={onGoToNextStep} color="info" icon="end" disabled={!canGoNext}>
                  NEXT <i className="icon icon-sm">arrow_forward</i>
                </Button>
              )
            }

            {
              isEditable && isLastStep && (
                <Tooltip
                  labelId={canCreate ? "components.SafetyEditor.toolbar.saveOrCreate.ok" : "components.SafetyEditor.toolbar.saveOrCreate.ko"}
                  placement="top"
                >
                  <Button onClick={createOrSaveSafety} color="info" disabled={!canCreate}>
                    <FormattedMessage id={isDefined(safetyId) ? "components.SafetyEditor.toolbar.save" : "components.SafetyEditor.toolbar.create"}/>
                  </Button>
                </Tooltip>
              )
            }

            {
              !isEditable && isDefined(onEdit) && (
                <Button onClick={onEdit} variant="default" color="primary">
                  <FormattedMessage id="components.SafetyEditor.toolbar.edit"/>
                </Button>
              )
            }
          </HStack>
        </VStack>
      </OffcanvasBody>
    </Offcanvas>
  );
}


type UseInitSafetyEditorResult =
  | {
  safety: GuaranteeResponse | CollateralResponse
  safetyType: SafetyType
  assetTypeId?: string
}
  | {
  safetyType: SafetyType
  assetTypeId?: string
  rtc: string
}
  | undefined

const useInitSafetyEditor = (props: SafetyEditorProps, isAlreadyInitiated: boolean): AnyAction | undefined => {
  const { rootId, safetyId, manyAssetsSelectable: areManyAssetsSelectable, editable: isEditable } = props;

  const { data: counterparty } = useCounterparty({ rootId });

  const { data: allSafetyElementaryTypes } = useGetSafetyElementaryTypes();

  const { data: allSafeties } = useGetSafeties(
    {
      rootId,
      page: 0,
      size: 10000
    },
    { skip: isNotDefined(safetyId) }
  );

  if (isAlreadyInitiated) {
    return undefined;
  }

  const foundSafety = isDefined(safetyId)
    ? allSafeties?.safeties?.find(loadedSafety => loadedSafety.id === safetyId) as GuaranteeResponse | CollateralResponse | undefined
    : undefined;

  const defaultSafetyTypeCode = getSelectedSafetyTypeCode("71010", allSafetyElementaryTypes);
  const defaultSafetyType = getSafetyType(defaultSafetyTypeCode);

  const safetyTypeCode = isDefined(foundSafety)
    ? getSelectedSafetyTypeCode(foundSafety.safetyGroupType, allSafetyElementaryTypes) as string
    : undefined;

  const safetyType = getSafetyType(safetyTypeCode) ?? defaultSafetyType;

  const assetTypeId = isDefined(foundSafety) && "assetsResponse" in foundSafety
    ? foundSafety.assetsResponse?.assets?.[0]?.assetTypeCode
    : undefined;

  const rct = counterparty?.rctId ?? "";

  const isReadyToAction = (
    (
      (
        isDefined(safetyId) && isDefined(foundSafety)
      )
      || isNotDefined(safetyId)
    )
    && isDefined(safetyType)
  );

  if (!isReadyToAction) {
    return undefined; // not ready
  }

  const selectedAssetReferences = isDefined(foundSafety) && "assetsResponse" in foundSafety
    ? (
      foundSafety.assetsResponse?.assets ?? []
    ).map(asset => (
      { id: asset.id!, sourceProductId: asset.assetRef! }
    ))
    : [];


  const initForm: Parameters<typeof initSafetyForm>[0] = isDefined(foundSafety)
    ? {
      rootId,
      rct,
      safety:       {
        id:   foundSafety.id!,
        data: safetyToSafetyFormData(foundSafety, safetyType)
      },
      safetyTypeId: foundSafety.safetyGroupType!,
      safetyType,
      assetTypeId,
      selectedAssetReferences,
      isEditable,
      areManyAssetsSelectable
    }
    : {
      rootId,
      rct,
      safety:                   undefined,
      safetyTypeId:             DEFAULT_SAFETY_TYPE_ID,
      safetyType,
      assetTypeId:              DEFAULT_ASSET_TYPE_ID_BY_SAFETY_TYPE_ID[DEFAULT_SAFETY_TYPE_ID],
      selectedAssetReferences,
      thirdPartySafetyProvider: rct,
      isEditable,
      areManyAssetsSelectable
    };

  // init the form
  return initSafetyForm(initForm);
};

export default withInitReduxState(SafetyEditor, useInitSafetyEditor);