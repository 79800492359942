import styled from "@emotion/styled";
import { PropsWithChildren } from "react";



export type NewComponentProps = {
  className?: string
}


function InfoCard(props: PropsWithChildren<NewComponentProps>): React.JSX.Element {
  const { className, children } = props;

  return (
    <StyledCard
      className={`d-flex flex-row align-items-center card card-filling-info active py-2 px-3 ${className}`}>
      {children}
    </StyledCard>
  );
}


const StyledCard = styled.div`
  color: #2B6EFB;
`;

export default InfoCard; 