import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { isDefined, isNotDefined } from "@sgme/fp";
import { AuthorizationPrefillResponse, AuthorizationResponse } from "@credit-control-risk/common";
import VStack from "@/components/sg-ui/layout/VStack";
import Box from "@/components/sg-ui/layout/Box";
import { AppState } from "@/store";
import { authorizationEditorSlice, initAuthorizationForm, setEditable } from "@/components/AuthorizationEditor/store/slice";
import { AuthorizationEditorState, AuthorizationExposureType } from "@/components/AuthorizationEditor/store/state";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";
import { useCounterparty, useGetAuthorizations, useGetDefaultAuthorizationValues } from "@/store/api/webapi";
import { withInitReduxState } from "@/utils/withInitReduxState";
import HStack from "@/components/sg-ui/layout/HStack";
import Button from "@/components/sg-ui/form/Button";
import Tooltip from "@/components/Tooltip";
import { authorizationResponseToAuthorizationFormData, canSaveOrCreateAuthorization } from "@/components/AuthorizationEditor/store/api";
import FormRow from "@/components/sg-ui/form/FormRow";
import FormGroup from "@/components/sg-ui/form/FormGroup";
import NumberInput from "@/components/sg-ui/form/NumberInput";
import CurrencyPicker from "@/components/sg-ui/form/pickers/CurrencyPicker";
import { useAuthorizationEditorForm } from "@/components/AuthorizationEditor/store/hooks";
import DatePicker from "@/components/sg-ui/form/pickers/DatePicker";
import YesNoInput from "@/components/sg-ui/form/YesNoInput";
import SelectPicker, { SelectPickerOption } from "@/components/sg-ui/form/pickers/SelectPicker";
import TextareaInput from "@/components/sg-ui/form/TextareaInput";
import { useScrollIntoView } from "@/utils/hooks/useScrollIntoView";
import { SectionList } from "@/components/sg-ui/layout/SectionList";
import { Section } from "@/components/sg-ui/layout/SectionList/Section";



/**
 * The authorization editor has 3 modes:
 * - view an existing authorizaion
 * - edit an existing authorization
 * - create a new authorization
 */
export type AuthorizationEditorPropsBase = {
  rootId: string
  groupProductCode: string
  groupProductCodeLabel: string
  opened: boolean
  toggle: () => void
  onSaveOrCreate: (authorization: AuthorizationChange) => void
}

export type ExistingAuthorizationEditorProps = AuthorizationEditorPropsBase & {
  authorizationId: string
  editable: boolean // view or edit
  onEdit?: () => void

  productId?: undefined
  productType?: undefined
}

export type NewAuthorizationEditorProps = AuthorizationEditorPropsBase & {
  authorizationId?: undefined
  editable?: true // view or edit
  onEdit?: undefined

  productId: string // only used to create an authorization (authorizationId === undefined) TODO: how to simplify this context
  productType: string
}

export type AuthorizationEditorProps =
  | ExistingAuthorizationEditorProps
  | NewAuthorizationEditorProps;


function AuthorizationEditor(props: AuthorizationEditorProps): React.JSX.Element | null {
  const {
    rootId,
    groupProductCode,
    groupProductCodeLabel,
    opened: isOpened,
    toggle,
    onSaveOrCreate,

    // authorizationId,
    editable: editableProps,
    onEdit
  } = props;


  const dispatch = useDispatch();

  const data = useSelector((appState: AppState) => {
    const state = appState.ui[authorizationEditorSlice.name] as AuthorizationEditorState;

    const canSaveOrCreate = canSaveOrCreateAuthorization(state);

    return {
      authorizationId: state.authorizationId,

      canSaveOrCreate,

      form:       state.form,
      isEditable: state.isEditable
    };
  });

  const {
    authorizationId,

    canSaveOrCreate
  } = data;

  const [ form, registerFormField, registerFormTextArea, isEditable ] = useAuthorizationEditorForm();

  const [ authorizationTypeRef, scrollToAuthorizationType ] = useScrollIntoView();
  const [ datesRef, scrollToDates ] = useScrollIntoView();
  const [ amountRef, scrollToAmount ] = useScrollIntoView();
  const [ blancoRef, scrollToBlanco ] = useScrollIntoView();
  const [ indicatorsRef, scrollToIndicators ] = useScrollIntoView();
  const [ commentsRef, scrollToComments ] = useScrollIntoView();

  useEffect(() => {
    if (!isEditable && editableProps) {
      dispatch(setEditable());
    }
  }, [ dispatch, isEditable, editableProps ]);

  if (!isDefined(rootId)) {
    return null;
  }

  const createOrSaveAuthorization = () => {
    onSaveOrCreate({
      authorizationId,
      ...form
    });

    toggle();
  };

  // for cash account (00020), blanco = Y and the user can't change it
  // and the user can't change this value for an existing safety
  // (until a alert is displayed if safeties are ever linked to the authorization)
  const isBlancoFieldEditable = groupProductCode !== "00020" && isNotDefined(authorizationId);

  const titleLabelId = (
    authorizationId === undefined
      ? "components.AuthorizationEditor.title.create" :
      isEditable
        ? "components.AuthorizationEditor.title.edit"
        : "components.AuthorizationEditor.title.view"
  );

  return (
    <Offcanvas
      isOpen={isOpened}
      direction="end"
      toggle={toggle}
      backdrop={false}
      style={{ width: 1320 }}
    >
      <OffcanvasHeader toggle={toggle}>
        <h2>
          <FormattedMessage id={titleLabelId}/>
        </h2>
      </OffcanvasHeader>

      <OffcanvasBody className="p-0">
        <VStack fullHeight padding="3">
          <Box flex="expand">
            <SectionList>
              <Section labelId="components.AuthorizationEditor.tabs.authorizationType">
                <FormGroup labelId="components.AuthorizationEditor.authorizationType.title" ref={authorizationTypeRef}>
                  <FormRow labelId="components.AuthorizationEditor.authorizationType.groupProductCode" className="my-2">
                    {groupProductCode}
                  </FormRow>
                  <FormRow labelId="components.AuthorizationEditor.authorizationType.groupProductCodeLabel" className="my-2">
                    {groupProductCodeLabel}
                  </FormRow>
                </FormGroup>
              </Section>

              <Section labelId="components.AuthorizationEditor.tabs.date">
                <FormGroup labelId="components.AuthorizationEditor.date.title" ref={datesRef}>
                  <FormRow labelId="components.AuthorizationEditor.date.validityStartDate" className="my-2">
                    <DatePicker
                      {...registerFormField("validityStartDate")}
                      editable={isEditable}
                    />
                  </FormRow>

                  <FormRow labelId="components.AuthorizationEditor.date.administrativeValidityDate" className="my-2">
                    <DatePicker
                      {...registerFormField("administrativeValidityDate")}
                      editable={isEditable}
                    />
                  </FormRow>

                  <FormRow labelId="components.AuthorizationEditor.date.exposureMaturityDate" className="my-2">
                    <DatePicker
                      {...registerFormField("exposureMaturityDate")}
                      editable={isEditable}
                    />
                  </FormRow>
                </FormGroup>
              </Section>

              <Section labelId="components.AuthorizationEditor.tabs.amount">
                <FormGroup labelId="components.AuthorizationEditor.amount.title" ref={amountRef}>
                  <FormRow labelId="components.AuthorizationEditor.amount.amount" className="my-2">
                    <NumberInput
                      {...registerFormField("authorizationAmount")}
                      editable={isEditable}
                      placeholderLabelId="components.AuthorizationEditor.amount.amount.placeholder"
                    />
                  </FormRow>

                  <FormRow labelId="components.AuthorizationEditor.amount.amountCurrency">
                    <CurrencyPicker
                      {...registerFormField("authorizationAmountCurrency")}
                      editable={isEditable}
                    />
                  </FormRow>
                </FormGroup>
              </Section>

              <Section labelId="components.AuthorizationEditor.tabs.blanco">
                <FormGroup labelId="components.AuthorizationEditor.blanco.title" ref={blancoRef}>
                  <FormRow labelId="components.AuthorizationEditor.blanco.blanco" className="my-2">
                    <YesNoInput
                      {...registerFormField("protectionIndication")}
                      editable={isEditable && isBlancoFieldEditable}
                      yesLabelId="components.AuthorizationEditor.blanco.blanco.yes"
                      noLabelId="components.AuthorizationEditor.blanco.blanco.no"
                    />
                  </FormRow>
                </FormGroup>
              </Section>

              <Section labelId="components.AuthorizationEditor.tabs.indicators">
                <FormGroup labelId="components.AuthorizationEditor.indicators.title" ref={indicatorsRef}>
                  <FormRow labelId="components.AuthorizationEditor.indicators.exposureDefaultType" className="my-2">
                    <SelectPicker
                      {...registerFormField("exposureDefaultType")}
                      options={EXPOSURE_DEFAULT_TYPES}
                      editable={isEditable}
                      placeholderLabelId="components.AuthorizationEditor.indicators.exposureDefaultType.placeholder"
                    />
                  </FormRow>
                </FormGroup>
              </Section>

              <Section labelId="components.AuthorizationEditor.tabs.comments">
                <FormGroup labelId="components.AuthorizationEditor.comments.title" ref={commentsRef}>
                  <FormRow labelId="components.AuthorizationEditor.comments.comments" className="my-2">
                    <TextareaInput
                      {...registerFormTextArea("comments")}
                      editable={isEditable}
                      placeholderLabelId="components.AuthorizationEditor.comments.comments.placeholder"
                    />
                  </FormRow>
                </FormGroup>
              </Section>
            </SectionList>
          </Box>

          <HStack gap="2" className="mt-3">
            <Box flex="expand"/>

            <Button onClick={toggle} variant="flat" color="primary">
              <FormattedMessage id="components.AuthorizationEditor.toolbar.cancel"/>
            </Button>

            {
              isEditable && (
                <Tooltip
                  labelId={canSaveOrCreate ? "components.AuthorizationEditor.toolbar.saveOrCreate.ok" : "components.AuthorizationEditor.toolbar.saveOrCreate.ko"}
                  placement="top"
                >
                  <Button onClick={createOrSaveAuthorization} color="info" disabled={!canSaveOrCreate}>
                    <FormattedMessage id={isDefined(authorizationId) ? "components.AuthorizationEditor.toolbar.save" : "components.AuthorizationEditor.toolbar.create"}/>
                  </Button>
                </Tooltip>
              )
            }

            {
              !isEditable && isDefined(onEdit) && (
                <Button onClick={onEdit} variant="default" color="primary">
                  <FormattedMessage id="components.AuthorizationEditor.toolbar.edit"/>
                </Button>
              )
            }
          </HStack>
        </VStack>
      </OffcanvasBody>
    </Offcanvas>
  );
}


const EXPOSURE_DEFAULT_TYPES: SelectPickerOption<AuthorizationExposureType>[] = [
  { value: "SIN", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.SIN" },
  { value: "SIR", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.SIR" },
  { value: "SAR", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.SAR" },
  { value: "NSS", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.NSS" },
  { value: "NSR", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.NSR" },
  { value: "NSC", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.NSC" },
  { value: "DER", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.DER" },
  { value: "DEF", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.DEF" },
  { value: "DEC", labelId: "components.AuthorizationEditor.indicators.exposureDefaultType.DEC" }
];


type UseInitAuthorizationEditorResult =
  {
    authorization: undefined
    defaultAuthorizationValues: AuthorizationPrefillResponse
  }
  | {
  authorization: AuthorizationResponse
}

const useInitAuthorizationEditor = (props: AuthorizationEditorProps, isAlreadyInitiated: boolean): AnyAction | undefined => {
  const {
    rootId,
    productId,
    productType,
    groupProductCode,
    groupProductCodeLabel,
    opened: isOpened,

    authorizationId,
    editable: isEditable = true
  } = props;

  const { data: counterparty } = useCounterparty({ rootId });

  const { data: allAuthorizations } = useGetAuthorizations(
    { rootId, page: 0, size: 10000 },
    { skip: isNotDefined(authorizationId) }
  );

  const foundAuthorization = allAuthorizations?.authorizations?.find(
    loadedAuthorization => loadedAuthorization.authorizationRef === authorizationId
  ) as AuthorizationResponse | undefined;

  const isGetDefaultAuthorizationValuesEnabled = (
    // needed only for new authorization (so authorizationId === undefined)
    !isDefined(authorizationId)
    // selected product ID
    && isDefined(productId)
    // product type (not the final group product code)
    && isDefined(productType)
  );

  const { data: defaultAuthorizationValues } = useGetDefaultAuthorizationValues(
    {
      productId:   productId!,
      productType: productType!
    },
    { skip: !isGetDefaultAuthorizationValuesEnabled }
  );

  if (isAlreadyInitiated) {
    return undefined;
  }

  const canInit = (
    (
      isDefined(authorizationId) && isDefined(foundAuthorization)
    )
    || (
      isNotDefined(authorizationId) && isDefined(defaultAuthorizationValues)
    )
  );

  if (!canInit) {
    return undefined;
  }

  const initForm: Parameters<typeof initAuthorizationForm>[0] = isDefined(authorizationId)
    ? {
      rootId,
      authorization: {
        id:   authorizationId,
        data: authorizationResponseToAuthorizationFormData(foundAuthorization!)
      },
      isEditable
    }
    : {
      rootId,
      authorization:              undefined,
      defaultAuthorizationValues: isNotDefined(foundAuthorization) ? defaultAuthorizationValues : undefined,
      isEditable,
      // blanco default value - for product of type "cash account", the uneditable default value is "yes"
      defaultProtectionIndication: groupProductCode === "00020" ? "yes" : undefined

    };

  return initAuthorizationForm(initForm);
};

export default withInitReduxState(AuthorizationEditor, useInitAuthorizationEditor);