import { FormattedMessage } from "react-intl";



export type MainDataProps = {
  titleLocaleId: string,
  value: string
}


function MainData(props: MainDataProps): React.JSX.Element {
  const { titleLocaleId, value } = props;

  return (
    <div>
      <h6 className="text-secondary mb-2">
        <FormattedMessage id={titleLocaleId}/>
      </h6>
      <h3>{value}</h3>
    </div>
  );
}


export default MainData;