import React, { FocusEvent, FormEvent, ForwardedRef, forwardRef } from "react";
import { useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";
import { InputGroup } from "reactstrap";



export interface SearchInputProps {
  name: string;
  placeholderLabelId?: string;
  value?: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}


function SearchInput(props: SearchInputProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const { name, placeholderLabelId, value, onChange, onBlur } = props;

  const intl = useIntl();

  const placeholder = isDefined(placeholderLabelId)
    ? intl.formatMessage({ id: placeholderLabelId })
    : undefined;

  return (
    <InputGroup>
      <div className="input-icon-start">
        <i className="icon">search</i>
      </div>

      <input
        ref={externalRef}
        type="search"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="form-control"
      />
    </InputGroup>
  );
}


export default forwardRef(SearchInput);