import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { isDefined } from "@sgme/fp";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Button } from "reactstrap";
import { newLinkSlice, openPanel, selectProductId } from "@/routes/links/new-link/store/slice";
import Tooltip from "@/components/Tooltip";
import { AppState } from "@/store";
import { NewLinkState, Panel, Step } from "@/routes/links/new-link/store/state";
import { useSafetyElementaryByCode } from "@/utils/hooks/useSafetyElementaryByCode";
import { isNewAuthorizationId, isNewSafetyId } from "@/routes/links/new-link/store/api";


// TODO: FormattedNumber accepts currency, but not EUR

function Summary(): React.JSX.Element {
  const dispatch = useDispatch();

  const [ getSafetyTypeLabel ] = useSafetyElementaryByCode();


  const data = useSelector((appState: AppState) => {
    const state = appState.ui[newLinkSlice.name] as NewLinkState;

    return {
      currentStep:           state.currentStep,
      selectedProduct:       state.product.selected,
      selectedAuthorization: state.authorization.selected,
      newAuthorization:      state.newAuthorization,
      selectedSafeties:      state.safeties.selected,
      newSafeties:           state.newSafeties
    };
  });

  const {
    currentStep,
    selectedProduct,
    selectedAuthorization,
    newAuthorization,
    selectedSafeties,
    newSafeties
  } = data;

  const onOpenPanel = (panel: Panel) => () => {
    dispatch(openPanel(panel));
  };

  const resetLink = () => dispatch(selectProductId(undefined));

  return (
    <div>
      <h3>
        <FormattedMessage id="routes.new-link.summary.Summary"/>
      </h3>

      <DataGrid className="mt-3">
        {
          selectedProduct !== undefined && (
            <>
              <aside>
                <FormattedMessage id="routes.new-link.summary.Product"/>
              </aside>

              <div>
                <div>{selectedProduct.sourceProductId}</div>
                <div>{selectedProduct.typeLabel}</div>
                <div>
                  {
                    isDefined(selectedProduct.notionalAmount) && <>
                          <FormattedNumber
                              value={selectedProduct.notionalAmount}
                              minimumFractionDigits={2}
                          /> {selectedProduct.notionalAmountCurrency}
                      </>
                  }
                </div>
              </div>
              <div/>
            </>
          )
        }

        {
          selectedAuthorization !== undefined && (
            <>
              <aside>
                <FormattedMessage id="routes.new-link.summary.Authorization"/>
              </aside>

              <div>
                <div>
                  {
                    isNewAuthorizationId(selectedAuthorization.authorizationRef)
                      ? <div>
                        <FormattedMessage id="routes.new-link.summary.Authorization.new"/>
                      </div>
                      : <div>
                        {selectedAuthorization.authorizationRef}
                      </div>
                  }
                </div>
                <div>
                  {
                    // yes, contract limit type = group product code label in the API
                    isNewAuthorizationId(selectedAuthorization.authorizationRef)
                      ? selectedProduct?.typeLabel ?? ""
                      : selectedAuthorization.contractLimitType
                  }
                </div>

                <div>
                  {
                    isDefined(selectedAuthorization.amount)
                      ? <FormattedNumber
                        value={selectedAuthorization.amount}
                        minimumFractionDigits={2}
                      />
                      : ""
                  } {selectedAuthorization.currency}
                </div>
              </div>

              <div>
                {
                  currentStep !== Step.LINK_CREATION && (
                    <Tooltip labelId="routes.new-link.summary.authorization.edit">
                      <button type="button" className="btn btn-flat-secondary" onClick={onOpenPanel(Panel.AUTHORIZATION)}>
                        <em className="icon">edit</em>
                      </button>
                    </Tooltip>
                  )
                }
              </div>
            </>
          )
        }

        {
          selectedSafeties.map(safety => (
            <>
              <aside key={`label-${safety.id}-a`}>
                <FormattedMessage id="routes.new-link.summary.Safety"/>
              </aside>

              <div key={`type-${safety.id}-b`}>
                {
                  isNewSafetyId(safety.id)
                    ? <div>
                      <FormattedMessage id="routes.new-link.summary.Safety.new"/>
                    </div>
                    : <div>
                      {safety.id}
                    </div>
                }
                <div>{getSafetyTypeLabel(safety.safetyGroupType)}</div>
                <div>
                  {
                    isDefined(safety.fixedAmount)
                      ? <FormattedNumber
                        value={safety.fixedAmount}
                        minimumFractionDigits={2}
                      />
                      : ""
                  } {safety.fixedAmountCurrency}
                </div>
              </div>

              <div key={`actions-${safety.id}-c`}>
                {
                  currentStep !== Step.LINK_CREATION && (
                    <Tooltip labelId="routes.new-link.summary.safety.edit">
                      <button type="button" className="btn btn-flat-secondary" onClick={onOpenPanel(Panel.SAFETY)}>
                        <em className="icon">edit</em>
                      </button>
                    </Tooltip>
                  )
                }
              </div>
            </>
          ))
        }
      </DataGrid>

      {
        isDefined(newAuthorization) && newAuthorization?.response.authorizationRef !== selectedAuthorization?.authorizationRef && currentStep !== Step.LINK_CREATION && (
          <div className="alert alert-warning mt-4">
            <i className="icon icon-md line-height-1 mr-3">info_outline</i> <FormattedMessage id="routes.new-link.summary.unselectedNewAuthorizationWarning"/>
          </div>
        )
      }

      {
        newSafeties.length > 0 && newSafeties.some(safety => !selectedSafeties.includes(safety.response)) && currentStep !== Step.LINK_CREATION && (
          <div className="alert alert-warning mt-4">
            <i className="icon icon-md line-height-1 mr-3">info_outline</i> <FormattedMessage id="routes.new-link.summary.unselectedNewSafetyWarning"/>
          </div>
        )
      }

      {
        selectedProduct !== undefined && currentStep !== Step.LINK_CREATION && (
          <Button className="btn-light mt-4 w-100" onClick={resetLink}>
            <em className="icon">refresh</em>
            <FormattedMessage id="routes.new-link.summary.Reset"/>
          </Button>
        )
      }
    </div>
  );
}


const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 50% minmax(30px, 10%);
  grid-auto-rows: min-content;
  gap: 1em 0;
`;

export default Summary;