import { format, parse } from "date-fns";
import { isDefined } from "@sgme/fp";



export const parseDate = (value: string | undefined) => {
  if (!isDefined(value) || value === "") {
    return undefined;
  }

  return parse(value, "dd-MM-yyyy", new Date());
};

export const parseJSONDate = (value: string | undefined) => {
  if (!isDefined(value) || value === "") {
    return undefined;
  }

  const date = parse(value, "yyyy-MM-dd", new Date());

  return format(date, "dd-MM-yyyy");
};

export const formatJSONDate = (value: string | Date | undefined) => {
  const date = typeof value === "string"
    ? parseDate(value)
    : value;

  if (!isDefined(date)) {
    return undefined;
  }

  return format(date, "yyyy-MM-dd");
};