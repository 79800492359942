import { FocusEvent, FormEvent, ForwardedRef, forwardRef } from "react";
import { isDefined } from "@sgme/fp";
import { COUNTRIES, COUNTRY_BY_CODE, CountryCode } from "@/components/sg-ui/form/countries";
import SelectPicker from "@/components/sg-ui/form/pickers/SelectPicker";

// https://en.wikipedia.org/wiki/ISO_3166-1


//  ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗██████╗ ██╗   ██╗    ██████╗ ██╗ ██████╗██╗  ██╗███████╗██████╗
// ██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝██╔══██╗╚██╗ ██╔╝    ██╔══██╗██║██╔════╝██║ ██╔╝██╔════╝██╔══██╗
// ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║   ██████╔╝ ╚████╔╝     ██████╔╝██║██║     █████╔╝ █████╗  ██████╔╝
// ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║   ██╔══██╗  ╚██╔╝      ██╔═══╝ ██║██║     ██╔═██╗ ██╔══╝  ██╔══██╗
// ╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║   ██║  ██║   ██║       ██║     ██║╚██████╗██║  ██╗███████╗██║  ██║
//  ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝       ╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

export interface CountryPickerProps {
  name: string;
  placeholderLabelId?: string;
  value?: CountryCode;
  disabled?: boolean;
  editable?: boolean;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}


function CountryPicker(props: CountryPickerProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    placeholderLabelId,
    value:    defaultValue,
    disabled: isDisabled = false,
    editable: isEditable = true,
    onChange,
    onBlur
  } = props;

  const countryOptions = COUNTRIES.map(country => (
    {
      value: country.code,
      label: country.name
    }
  ));

  const renderValue = (code: string) => (
    <CountryView code={code as CountryCode}/>
  );

  return (
    <SelectPicker
      ref={externalRef}
      name={name}
      placeholderLabelId={placeholderLabelId}
      value={defaultValue}
      disabled={isDisabled}
      editable={isEditable}
      options={countryOptions}
      onChange={onChange}
      onBlur={onBlur}

      renderValue={renderValue}
    />
  );
}


export default forwardRef(CountryPicker);


//  ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗██████╗ ██╗   ██╗    ██╗   ██╗██╗███████╗██╗    ██╗
// ██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝██╔══██╗╚██╗ ██╔╝    ██║   ██║██║██╔════╝██║    ██║
// ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║   ██████╔╝ ╚████╔╝     ██║   ██║██║█████╗  ██║ █╗ ██║
// ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║   ██╔══██╗  ╚██╔╝      ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
// ╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║   ██║  ██║   ██║        ╚████╔╝ ██║███████╗╚███╔███╔╝
//  ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝         ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

interface CountryViewProps {
  code: CountryCode;
  onSelect?: (code: CountryCode) => void;
}


function CountryView(props: CountryViewProps) {
  const { code, onSelect } = props;

  const onClick = () => {
    if (isDefined(onSelect)) {
      onSelect(code);
    }
  };

  return (
    <div className="d-flex gap-2 my-1 w-100 align-items-center"
         onClick={onClick}>
      <img src={`/countries/${code}.svg`} alt="country flag" height="18"/>
      <span>{COUNTRY_BY_CODE[code]}</span>
    </div>
  );
}

