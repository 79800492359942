import RootIDSelector from "@/components/RootIDSelector";
import { useUser } from "@/store/api/webapi";



function HomeRoute(): React.JSX.Element {
  // TODO: use for authorization ?
  const { data, isLoading, error } = useUser(
    undefined,
    {
      pollingInterval:           2 * 60 * 1000, // every 2 minutes
      refetchOnMountOrArgChange: true,
      skip:                      false
    }
  );

  return (
    <div className="container-xl">
      <div style={{ height: 400, width: "100%" }}>
        <RootIDSelector isOpen/>
      </div>
    </div>
  );
}


export default HomeRoute;