import React from "react";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";
import VStack from "@/components/sg-ui/layout/VStack";
import HStack from "@/components/sg-ui/layout/HStack";



export interface ErrorPanelProps {
  titleId?: string;
  messageId?: string;
  tips?: "reload" | "go-home" | "reload+go-home";
}

const DEFAULT_TITLE_ID = "components.ErrorPanel.title";
const DEFAULT_MESSAFE_ID = "components.ErrorPanel.message";
const DEFAULT_TIPS_ID = "components.ErrorPanel.tips";


function ErrorPanel(props: ErrorPanelProps) {
  const {
    titleId = DEFAULT_TITLE_ID,
    messageId = DEFAULT_MESSAFE_ID,
    tips
  } = props;


  const reloadPage = () => {
    location.reload();
  };

  const goHome = () => {
    location.assign("/");
  };

  return (
    <VStack className="p-4" fullHeight verticalAlignment="center" horizontalAlignment="center">
      <div className="card card-bordered shadow">
        <div className="card-body">
          <HStack horizontalAlignment="center" verticalAlignment="center">
            <div className="rounded-circle bg-danger bg-opacity-10 d-flex align-items-center justify-content-center me-3" style={{ width: "3rem", height: "3rem" }}>
              <em className="icon icon-md text-danger line-height-1">
                warning
              </em>
            </div>
            <h1 className="text-danger">
              <FormattedMessage id={titleId}/>
            </h1>
          </HStack>

          <div className="mt-5">
            <FormattedMessage id={messageId}/>
          </div>

          {
            isDefined(tips) && (
              <>
                <div className="mt-2">
                  <FormattedMessage id="components.ErrorPanel.reload.message"/>
                </div>

                <HStack horizontalAlignment="center" className="mt-4" gap="2">
                  {
                    tips === "reload" || tips === "reload+go-home" && (
                      <button type="button" className="btn btn-lg btn-primary" onClick={reloadPage}>
                        <FormattedMessage id="components.ErrorPanel.reload.button"/>
                      </button>
                    )
                  }

                  {
                    tips === "go-home" || tips === "reload+go-home" && (
                      <button type="button" className="btn btn-lg btn-outline-primary" onClick={goHome}>
                        <FormattedMessage id="components.ErrorPanel.goHome.button"/>
                      </button>
                    )
                  }
                </HStack>
              </>
            )
          }


        </div>
      </div>
    </VStack>
  );
}


export default ErrorPanel;
