import { useDispatch } from "react-redux";
import { addListener, combineReducers, configureStore, createListenerMiddleware, ListenerEffectAPI, TypedAddListener, TypedStartListening } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { webapi } from "@/store/api/webapi";
import { newLinkSlice } from "@/routes/links/new-link/store/slice";
import { safetyEditorSlice } from "@/components/SafetyEditor/store/slice";
import { authorizationEditorSlice } from "@/components/AuthorizationEditor/store/slice";
import { versionApi } from "@/store/api/version";



/**
 * how to configure the store
 * - add reducer in the reducer store (in this file)
 * - add listener in the ProviderStore (in the ./provider.ts)
 */

const listenerMiddlewareInstance = createListenerMiddleware();

export const store = configureStore({
  reducer: combineReducers({
    ui:                       combineReducers({
      [newLinkSlice.name]:             newLinkSlice.reducer,
      [safetyEditorSlice.name]:        safetyEditorSlice.reducer,
      [authorizationEditorSlice.name]: authorizationEditorSlice.reducer
    }),
    [webapi.reducerPath]:     webapi.reducer,
    [versionApi.reducerPath]: versionApi.reducer
  }),

  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware()
    .prepend(listenerMiddlewareInstance.middleware)
    .concat(webapi.middleware, versionApi.middleware)
  )
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): AppDispatch => useDispatch();

export type AppStartListening = TypedStartListening<AppState, AppDispatch>;

export type AppListenerEffectAPI = ListenerEffectAPI<AppState, AppDispatch>

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<AppState, AppDispatch>;
