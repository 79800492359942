import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { isDefined } from "@sgme/fp";
import CollapsablePanel from "@/components/CollapsablePanel";
import InfoCard from "@/components/InfoCard";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import { formatDate, formatYesNo } from "@/utils/formatters";
import { AppState } from "@/store";
import { createAuthorization, newLinkSlice, selectAuthorizationId, togglePanel } from "@/routes/links/new-link/store/slice";
import { isSelectedInManySelectionSelection } from "@/utils/state/selection-many";
import { SelectableItemStatusType } from "@/utils/selectable";
import { NewLinkState, Panel, Step } from "@/routes/links/new-link/store/state";
import AuthorizationEditor from "@/components/AuthorizationEditor";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";
import { generateNewAuthorizationId, isNewAuthorizationId } from "@/routes/links/new-link/store/api";
import { GetProductNomenclatureLabel, useProductNumenclatureByCode } from "@/utils/hooks/useProductNumenclatureByCode";
import { productTypeLabelToProductType } from "@/routes/links/new-link/store/listeners";



function AuthorizationSelector(): React.JSX.Element {
  const intl = useIntl();

  const apiRef = useRef<GridApi>();

  const dispatch = useDispatch();

  const data = useSelector((appState: AppState) => {
    const state = appState.ui[newLinkSlice.name] as NewLinkState;

    const isEditable = state.currentStep !== Step.LINK_CREATION;

    return {
      rootId:                 state.rootId as string,
      isOpened:               isSelectedInManySelectionSelection(state.openedPanels, Panel.AUTHORIZATION),
      productId:              state.product.selected?.sourceProductId,
      groupProductCode:       state.product.selected?.type ?? "",
      groupProductCodeLabel:  state.product.selected?.typeLabel ?? "", // TODO: until macgda API to get the label
      rows:                   state.authorization.all.map(item => (
        // authorizationRef is hidden for new authorization item
        // so save the ID in ... ID ! ;-) used to select the authorization
        { ...item, id: item.authorizationRef, authorizationRef: isDefined(item.authorizationRef) && isNewAuthorizationId(item.authorizationRef) ? "" : item.authorizationRef }
      )),
      selectedAuthorization:  state.authorization.selected,
      isLoading:              state.authorization.status.type !== SelectableItemStatusType.LOADED,
      isEditable,
      hasError:               false,
      canCreateAuthorization: isEditable
    };
  });

  const {
    rootId,
    isOpened,
    productId,
    groupProductCode,
    groupProductCodeLabel,
    rows,
    selectedAuthorization,
    isEditable,
    isLoading,
    canCreateAuthorization
  } = data;

  const [ getProductNomenclatureLabel ] = useProductNumenclatureByCode();

  const [ isAuthorizationEditorVisible, setAuthorizationEditorVisibility ] = useState(false);

  const toggleAuthorizationEditorVisibility = () => setAuthorizationEditorVisibility(isVisible => !isVisible);


  const onTogglePanel = () => {
    dispatch(togglePanel(Panel.AUTHORIZATION));
  };

  const onGridReady = (event: GridReadyEvent) => {
    apiRef.current = event.api;

    if (isLoading) {
      apiRef.current?.showLoadingOverlay();
    }
  };

  useEffect(() => {
    if (!isLoading) {
      apiRef.current?.hideOverlay();
    }
  }, [ isLoading ]);

  useEffect(() => {
    apiRef.current?.forEachNode(node => node.setRowSelectable(isEditable));
  }, [ isEditable ]);

  useEffect(() => {
    apiRef.current?.forEachNode(node => {
      if (node.data.id === selectedAuthorization?.authorizationRef) {
        node.setSelected(true, false);
      }
      else {
        node.setSelected(false, false);
      }
    });
  }, [ selectedAuthorization ]);

  const onSelectionChanged = (event: RowSelectedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const authorizationRef = selectedNodes.length === 0 ? undefined : selectedNodes[0].data.id;

    dispatch(selectAuthorizationId(authorizationRef));
  };

  const onCreateAuthorization = (authorization: AuthorizationChange) => {
    dispatch(createAuthorization({ authorization, id: generateNewAuthorizationId() }));
  };

  return (
    <CollapsablePanel
      nameId="routes.new-link.authorization.Authorization"
      isOpened={isOpened}
      toggle={onTogglePanel}
    >
      <div style={{ height: 258, width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
        <AgGridReact
          rowData={rows}
          defaultColDef={{ ...DEFAULT_COLUMN_DEF, headerCheckboxSelection: false }}
          columnDefs={addHeaderIntlName("routes.new-link.authorization.table.column", getTableColumns(getProductNomenclatureLabel), intl)}
          modules={[ ClientSideRowModelModule ]}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          enableCellTextSelection
        />
      </div>

      <InfoCard className="mt-4">
        <main className="flex-fill">
          <FormattedMessage id="routes.new-link.authorization.create.label"/>
        </main>

        <aside>
          <Button className="btn-outline-info" onClick={toggleAuthorizationEditorVisibility} disabled={!canCreateAuthorization}>
            <FormattedMessage id="routes.new-link.authorization.create.button"/>
          </Button>

          {
            isAuthorizationEditorVisible && (
              <AuthorizationEditor
                rootId={rootId}
                productId={productId}
                productType={productTypeLabelToProductType(groupProductCodeLabel)}
                groupProductCode={groupProductCode}
                groupProductCodeLabel={getProductNomenclatureLabel(groupProductCode) ?? ""}
                opened={isAuthorizationEditorVisible}
                toggle={toggleAuthorizationEditorVisibility}
                editable
                onSaveOrCreate={onCreateAuthorization}
              />
            )
          }
        </aside>
      </InfoCard>
    </CollapsablePanel>
  );
}


// TODO: tempSelectedGroupProductCodeLabel is used until macgda provides a API to get the labels for all group product code
const getTableColumns = (getProductNomenclatureLabel: GetProductNomenclatureLabel) => (intl: IntlShape): ColDef[] => [
  {
    field:                  "groupProductCodeId", // yes, contract limit type = group product code label in the API
    minWidth:               150,
    checkboxSelection:      true,
    showDisabledCheckboxes: true,
    valueFormatter:         (params) => getProductNomenclatureLabel(params.value)
  },
  {
    field:        "authorizationRef",
    minWidth:     150,
    cellRenderer: (props: {
      value: string | undefined
    }) => !isDefined(props.value) || props.value === ""
      ? <i>{intl.formatMessage({ id: "routes.new-link.authorization.table.column.authorizationRef.new" })}</i>
      : props.value
  },
  {
    field:          "amount",
    type:           "rightAligned",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => intl.formatNumber(params.value, { minimumFractionDigits: 2 })
  },
  {
    field:    "currency",
    minWidth: 150
  },
  {
    field:          "exposureMaturityDate", // = endDate that is not defined for new authorization
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, intl)
  },
  {
    field:          "protectionIndication",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => formatYesNo(params.value, intl)
  }
];

export default AuthorizationSelector;