import { FormattedMessage, useIntl } from "react-intl";
import FormGroup from "@/components/sg-ui/form/FormGroup";
import FormRow from "@/components/sg-ui/form/FormRow";
import CurrencyPicker from "@/components/sg-ui/form/pickers/CurrencyPicker";
import SelectPicker, { SelectPickerOption } from "@/components/sg-ui/form/pickers/SelectPicker";
import NumberInput from "@/components/sg-ui/form/NumberInput";
import { useAssetType, useSafetyEditorForm, useSafetyType } from "@/components/SafetyEditor/store/hooks";



function AssetValuationFormGroup() {
  const intl = useIntl();

  const [ _, registerFormField, isEditable ] = useSafetyEditorForm();

  const { isCollateralMortgage } = useSafetyType();

  const assetType = useAssetType();

  return (
    <FormGroup labelId="components.SafetyEditor.assetValuationForm.title">
      <FormRow labelId="components.SafetyEditor.assetValuationForm.assetType.code" className="my-2">
        {assetType?.assetTypeCode ?? <i><FormattedMessage id="components.SafetyEditor.assetValuationForm.assetType.code.missing"/></i>}
      </FormRow>

      <FormRow labelId="components.SafetyEditor.assetValuationForm.assetType.label" className="my-2">
        {intl.locale === "fr" ? assetType?.assetTypeLongLabelFr : assetType?.assetTypeLongLabelEn ??
            <i><FormattedMessage id="components.SafetyEditor.assetValuationForm.assetType.code.missing"/></i>}
      </FormRow>

      <FormRow labelId="components.SafetyEditor.assetValuationForm.liquidityIndicator.label">
        <SelectPicker
          {...registerFormField("liquidityIndicator")}
          options={LIQUIDITY_INDICATOR_OPTIONS}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.assetValuationForm.liquidityIndicator.placeholder"
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.assetValuationForm.valuationMethod.label">
        <SelectPicker
          {...registerFormField("valuationMethod")}
          options={VALUATION_METHOD_OPTIONS}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.assetValuationForm.valuationMethod.placeholder"
        />
      </FormRow>

      {
        isCollateralMortgage && (
          <>
            <FormRow labelId="components.SafetyEditor.assetValuationForm.marketValue.label">
              <NumberInput
                {...registerFormField("marketValue")}
                editable={isEditable}
                placeholderLabelId="components.SafetyEditor.assetValuationForm.marketValue.placeholder"
              />
            </FormRow>

            <FormRow labelId="components.SafetyEditor.assetValuationForm.marketValueCurrency.label">
              <CurrencyPicker
                {...registerFormField("marketValueCurrency")}
                editable={isEditable}
                placeholderLabelId="components.SafetyEditor.assetValuationForm.marketValueCurrency.placeholder"
              />
            </FormRow>
          </>
        )
      }
    </FormGroup>
  );
}


const VALUATION_METHOD_OPTIONS: SelectPickerOption<"1" | "2" | "3" | "4" | "5" | "6" | "7" | "8">[] = [
  { value: "1", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.historical" },
  { value: "2", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.marketValue" },
  { value: "3", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.internalExpert" },
  { value: "4", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.externalExpert" },
  { value: "5", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.simulation" },
  { value: "6", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.accountingValuation" },
  { value: "7", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.internalIndex" },
  { value: "8", labelId: "components.SafetyEditor.assetValuationForm.valuationMethod.externalIndex" }
];


const LIQUIDITY_INDICATOR_OPTIONS: SelectPickerOption<"1" | "2" | "3" | "4">[] = [
  { value: "1", labelId: "components.SafetyEditor.assetValuationForm.liquidityIndicator.alreadySold" },
  { value: "2", labelId: "components.SafetyEditor.assetValuationForm.liquidityIndicator.easyToSell" },
  { value: "3", labelId: "components.SafetyEditor.assetValuationForm.liquidityIndicator.potentiallyDifficultToSell" },
  { value: "4", labelId: "components.SafetyEditor.assetValuationForm.liquidityIndicator.difficultToSell" }
];

export default AssetValuationFormGroup;