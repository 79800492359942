import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";
import { SgPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { SgPickerCustomEvent } from "@sg-bootstrap/components";
import { useCounterpartySuggestions } from "@/store/api/webapi";



export type RootIDSelectorProps = {
  isOpen: boolean
  onClose?: () => void
}


function RootIDSelector(props: RootIDSelectorProps): React.JSX.Element {
  const { isOpen, onClose } = props;

  const navigate = useNavigate();

  const intl = useIntl();
  const pickerRef = useRef<HTMLSgPickerElement>(null);
  const [ query, setQuery ] = useState("");
  const [ selectedRootItem, setSelectedRootItem ] = useState<RootItem | undefined>(undefined);

  const { isLoading, data, error } = useCounterpartySuggestions({ text: query }, {
    skip: query.length < 4
  });

  const placeholder = intl.formatMessage({ id: "components.RootIDSelector.search.placeholder" });

  useEffect(() => {
    const foundItems = query.length >= 4 && isDefined(data) && data.values.length > 0
      ? data.values.map(({ id, legalName }) => (
        {
          key:    id,
          label:  legalName,
          $$type: "item"
        }
      ))
      : getRootItemHistory()
      .map(({ id, label }) => (
        {
          key:    id,
          label,
          $$type: "item"
        }
      ));

    pickerRef.current?.setItems(foundItems);
  }, [ query, data ]);

  const canOpenRootId = isDefined(selectedRootItem);

  const initPicker = () => {
    pickerRef.current?.setItems(
      getRootItemHistory()
      .map(({ id, label }) => (
        {
          key:    id,
          label,
          $$type: "item"
        }
      ))
    );
  };

  const onQueryChange = (event: SgPickerCustomEvent<string>) => {
    const updatedQuery = event.detail;
    setQuery(updatedQuery);

    // TODO: search from macgda the root ids
  };

  const onQuerySelect = (event: SgPickerCustomEvent<any>) => {
    pickerRef.current?.setValue(event.detail.label);

    setSelectedRootItem({
      id:    event.detail.key,
      label: event.detail.label
    });
  };

  const onQueryClear = (event: SgPickerCustomEvent<string>) => {
    setQuery("");
    setSelectedRootItem(undefined);
  };

  const onLaunchManagementView = () => {
    if (!canOpenRootId) {
      return;
    }

    saveRootItem(selectedRootItem);

    navigate(`/authorizations/${selectedRootItem.id}`);

    if (onClose !== undefined) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="components.RootIDSelector.header"/>
      </ModalHeader>

      <ModalBody>
        <SgPicker
          ref={pickerRef}
          appendTo="body"
          singleSelect
          placeholder={placeholder}
          debounceTime={500}
          onInputChanged={onQueryChange}
          onClear={onQueryClear}
          onSelectItem={onQuerySelect}
          onReady={initPicker}
          dataE2e="rootId-input"
        />

        <ModalFooter>
          <Button color="primary" disabled={!canOpenRootId} onClick={onLaunchManagementView} data-e2e="open-rootId">
            <FormattedMessage id="components.RootIDSelector.startButtonLabel"/>
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}


export default RootIDSelector;


// ██████╗  ██████╗  ██████╗ ████████╗    ██╗██████╗     ██╗  ██╗██╗███████╗████████╗ ██████╗ ██████╗ ██╗   ██╗
// ██╔══██╗██╔═══██╗██╔═══██╗╚══██╔══╝    ██║██╔══██╗    ██║  ██║██║██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗╚██╗ ██╔╝
// ██████╔╝██║   ██║██║   ██║   ██║       ██║██║  ██║    ███████║██║███████╗   ██║   ██║   ██║██████╔╝ ╚████╔╝
// ██╔══██╗██║   ██║██║   ██║   ██║       ██║██║  ██║    ██╔══██║██║╚════██║   ██║   ██║   ██║██╔══██╗  ╚██╔╝
// ██║  ██║╚██████╔╝╚██████╔╝   ██║       ██║██████╔╝    ██║  ██║██║███████║   ██║   ╚██████╔╝██║  ██║   ██║
// ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝       ╚═╝╚═════╝     ╚═╝  ╚═╝╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝   ╚═╝

export type RootItem = {
  id: string
  label: string
}

const ROOT_ID_HISTORY_KEY = "rih";
const ROOT_ID_HISTORY_MAX = 8;

const getRootItemHistory = (): RootItem[] => {
  if (localStorage) {
    const history = JSON.parse(localStorage.getItem(ROOT_ID_HISTORY_KEY) ?? "[]");

    return history.filter(item => typeof item === "object" && isDefined(item.id) && isDefined(item.label));
  }

  return [];
};

const saveRootItem = (item: RootItem) => {
  const currentHistory = getRootItemHistory();

  const updatedHistory: RootItem[] = [
    item,
    ...currentHistory.filter(({ id }) => id !== item.id)
  ];

  if (localStorage) {
    localStorage.setItem(ROOT_ID_HISTORY_KEY, JSON.stringify(updatedHistory.slice(0, ROOT_ID_HISTORY_MAX)));
  }
};