import { FocusEvent, FormEvent, ForwardedRef, forwardRef, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { isDefined } from "@sgme/fp";
import { SgCurrencyPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { HiddenInput, useUpdateValue } from "@/components/sg-ui/form/_utils";
import { flagForCurrency } from "@/components/sg-ui/form/pickers/currencies";



export interface CurrencyPickerProps {
  name: string;
  placeholderLabelId?: string;
  value?: string;
  disabled?: boolean;
  editable?: boolean;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}


// it is awful, but the widget is VER VERY BAD ! :-(

function CurrencyPicker(props: CurrencyPickerProps, externalRef: ForwardedRef<HTMLInputElement>) {
  const {
    name,
    placeholderLabelId,
    value:    defaultValue,
    disabled: isDisabled = false,
    editable: isEditable = true,
    onChange,
    onBlur
  } = props;

  const pickerRef = useRef<HTMLSgCurrencyPickerElement>(null);
  const [ innerRef, value, updateDate ] = useUpdateValue(externalRef, defaultValue);

  useEffect(() => {
    if (isDefined(value) && value.length > 0) {
      pickerRef.current?.selectItemByKey(value);
    }
    else {
      pickerRef.current?.unselectAllItems();
    }
  }, [ isEditable, value ]); // when not editable, pickerRef is null. but when becoming editable, the pickerRef becomes available

  useEffect(() => {
    const picker = pickerRef.current;

    if (!isDefined(picker)) {
      // eslint-disable-next-line no-console
      console.warn("picker isn't defined");
      return;
    }

    const onSelectItem = (event: CustomEvent) => {
      updateDate(event.detail?.item.key ?? "");
    };

    // TODO: fix eslint errors
    // @ts-ignore
    picker.addEventListener("selectItem", onSelectItem);
    // @ts-ignore
    picker.addEventListener("unselectItem", onSelectItem);
    // @ts-ignore
    picker.addEventListener("clear", onSelectItem);

    // eslint-disable-next-line consistent-return
    return () => {
      // @ts-ignore
      picker.removeEventListener("clear", onSelectItem);
      // @ts-ignore
      picker.removeEventListener("unselectItem", onSelectItem);
      // @ts-ignore
      picker.removeEventListener("selectItem", onSelectItem);
    };
  }, [ updateDate ]);

  const intl = useIntl();

  const placeholder = isDefined(placeholderLabelId)
    ? intl.formatMessage({ id: placeholderLabelId })
    : undefined;

  if (!isEditable) {
    return (
      <>
        {/* like SgCurrencyPicker, not really nice */}
        {/* https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap/blob/bf12edda8b2ac663714237884740f3e6b3a60fe2/%40sg-bootstrap/components/src/components/sg-currency-picker/sg-currency-picker.tsx */}
        <link rel="stylesheet" href="https://sgwt-cdn-sgbs.sgmarkets.com/flag-icons/6.6.6/css/flag-icons.min.css"/>
        <SimpleFlag>
          <span className={`fi fi-${flagForCurrency(value)} currency__flag currency__flag-md`}/> {value}
        </SimpleFlag>
      </>
    );
  }

  return (
    <>
      <SgCurrencyPicker
        ref={pickerRef}
        disabled={isDisabled || !isEditable}
        placeholder={placeholder}
      />

      <HiddenInput
        ref={innerRef}
        name={name}
        type="text"
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
}


const SimpleFlag = styled.span`
  & > .currency__flag-md {
    font-size: 18px;
    margin-right: 7px;
  }
`;


export default forwardRef(CurrencyPicker);