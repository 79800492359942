import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";
import { SafetyElementaryTypeResponse } from "@credit-control-risk/common";
import DataTreeSelector from "@/components/DataTreeSelector";
import Loading from "@/components/sg-ui/Loading";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";
import { AppState } from "@/store";
import { safetyEditorSlice, setSafetyType, setSafetyTypeQuery } from "@/components/SafetyEditor/store/slice";
import { useGetSafetyElementaryTypes } from "@/store/api/webapi";
import { SafetyEditorState } from "@/components/SafetyEditor/store/state";
import { DataNode } from "@/components/DataTreeSelector/types";
import { getSafetyType, getSelectedSafetyTypeCode } from "@/components/SafetyEditor/store/api";



function SafetyGroupTypeSelector() {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { query, selectedSafetyTypeId } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    const canCreate = false;

    return {
      query:                state.safetyTypeQuery,
      selectedSafetyTypeId: state.selectedSafetyTypeId
    };
  });
  ;

  const { data: allSafetyElementaryTypes, isLoading: isSafetyElementaryTypesLoading, error: safetyElementTypesError } = useGetSafetyElementaryTypes();

  const safetyTypes = useMemo(
    () => findDataNodesFromSafetyElementaryTypes(query, intl.locale, allSafetyElementaryTypes?.types ?? []),
    [
      allSafetyElementaryTypes,
      query,
      intl
    ]
  );

  if (isSafetyElementaryTypesLoading) {
    return <Loading visibilityDelay={500}/>;
  }

  if (isDefined(safetyElementTypesError)) {
    return <ErrorPanel
      titleId="components.SafetyEditor.safetyTypeSelection.errorPanel.title"
      messageId="components.SafetyEditor.safetyTypeSelection.errorPanel.message"
      tips="reload+go-home"
    />;
  }

  const onToggleSelectedSafetyTypeId = (id: string) => {
    const safetyCode = getSelectedSafetyTypeCode(id, allSafetyElementaryTypes);
    const safetyType = getSafetyType(safetyCode);

    if (!isDefined(safetyType)) {
      return;
    }

    dispatch(setSafetyType({ id, type: safetyType }));
  };

  const onQueryChange = (value: string) => {
    dispatch(setSafetyTypeQuery(value));
  };

  return (
    <DataTreeSelector
      queryLabelId="components.SafetyEditor.safetyTypeSelection.queryLabel"
      query={query}
      onQueryChange={onQueryChange}
      data={safetyTypes}
      hasSectionLevel
      nameLabelId="components.SafetyEditor.safetyTypeSelection.nameLabel"
      idLabelId="components.SafetyEditor.safetyTypeSelection.idLabel"
      selectedId={selectedSafetyTypeId}
      toggleIdSelection={onToggleSelectedSafetyTypeId}
    />
  );
}


export default SafetyGroupTypeSelector;


// ███████╗ █████╗ ███████╗███████╗████████╗██╗   ██╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ██╔════╝██╔══██╗██╔════╝██╔════╝╚══██╔══╝╚██╗ ██╔╝    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
// ███████╗███████║█████╗  █████╗     ██║    ╚████╔╝        ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
// ╚════██║██╔══██║██╔══╝  ██╔══╝     ██║     ╚██╔╝         ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
// ███████║██║  ██║██║     ███████╗   ██║      ██║          ██║      ██║   ██║     ███████╗███████║
// ╚══════╝╚═╝  ╚═╝╚═╝     ╚══════╝   ╚═╝      ╚═╝          ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

const findDataNodesFromSafetyElementaryTypes = (rawQuery: string, locale: string, inputs: SafetyElementaryTypeResponse[]): DataNode[] => {
  const query = rawQuery.trim().toLowerCase();

  const selectedTypes = inputs.filter(type => (
    type.safetyElementaryTypeLabelFr!.toLowerCase().includes(query)
    || type.safetyElementaryTypeLabelEn!.toLowerCase().includes(query)
    || type.safetyElementaryTypeLabelCode!.includes(query)
  )); //
  const selectedTypeIds = selectedTypes.map(type => type.safetyElementaryTypeLabelCode!);

  const [ typeById, typeParentIdById, childrenTypesByParentId ] = inputs.reduce(
    (result, item) => {
      result[0][item.safetyElementaryTypeLabelCode!] = item;

      if (isDefined(item.parentCode)) {
        result[1][item.safetyElementaryTypeLabelCode!] = item.parentCode;

        const childIds = result[2][item.parentCode] ??= [];
        childIds.push(item.safetyElementaryTypeLabelCode!);
      }

      return result;
    },
    [ {}, {}, {} ] as [ Record<string, SafetyElementaryTypeResponse>, Record<string, string | undefined>, Record<string, string[]> ]
  );

  const filteredSelectableTypes = query === ""
    ? inputs
    : selectSafetyElementaryTypes(inputs, selectedTypeIds, typeById, typeParentIdById, childrenTypesByParentId);

  const rootTypes = filteredSelectableTypes.filter(type => !isDefined(type.parentCode));

  const selectedChildrenTypesByParentId = filteredSelectableTypes.reduce(
    (result, item) => {
      if (isDefined(item.parentCode)) {
        const childIds = result[item.parentCode] ??= [];
        childIds.push(item.safetyElementaryTypeLabelCode!);
      }

      return result;
    },
    {} as Record<string, string[]>
  );

  return rootTypes.map(safetyElementaryTypeToDataNode(locale, typeById, selectedChildrenTypesByParentId));
};


const safetyElementaryTypeToDataNode = (locale: string, typeById: Record<string, SafetyElementaryTypeResponse>, typesByParentId: Record<string, string[]>) => (type: SafetyElementaryTypeResponse): DataNode => {
  const subtypes = typesByParentId[type.safetyElementaryTypeLabelCode!]?.map(id => typeById[id]);

  if (isDefined(subtypes)) {
    return {
      id:       type.safetyElementaryTypeLabelCode!,
      name:     locale === "fr" ? type.safetyElementaryTypeLabelFr! : type.safetyElementaryTypeLabelEn!,
      children: subtypes.map(safetyElementaryTypeToDataNode(locale, typeById, typesByParentId))
    };
  }

  return {
    id:   type.safetyElementaryTypeLabelCode!,
    name: locale === "fr" ? type.safetyElementaryTypeLabelFr! : type.safetyElementaryTypeLabelEn!
  };
};

/**
 * criteria to find a type:
 * - the name of the time contains the query -> selectedIds
 * - all ancestors of selected types (selectedIds)
 * - all descendants of selected types (selectedIds if the elementary type contains other types)
 */
const selectSafetyElementaryTypes = (inputs: SafetyElementaryTypeResponse[], selectedIds: string[], itemById: Record<string, SafetyElementaryTypeResponse>, parentIdById: Record<string, string | undefined>, childIdsById: Record<string, string[]>): SafetyElementaryTypeResponse[] => {
  // TODO: to avoid to scan many times the branches of the tree, keep a record of added IDs, and check if already added
  // so extract the IDs from the record keys
  // performance in the reduce with query = "c" (big search)
  // -   2 ms : x.forEach(...push)
  // -  37 ms : [ ...result, ...xxx ]
  // - 800 ms : for () { push }
  const foundIds = [
    ...selectedIds,
    ...selectedIds.reduce(
      (result, id) => {
        getAllAncestors(id, parentIdById).forEach(ancestorId => result.push(ancestorId));
        getAllDescendants(id, childIdsById).forEach(descendantId => result.push(descendantId));

        return result;
      },
      [] as string[]
    )
  ];

  const uniqFoundIds = [
    ...new Set(foundIds)
  ];

  return uniqFoundIds.map(id => itemById[id]);
};


const getAllAncestors = (id: string, parentIdById: Record<string, string | undefined>): string[] => {
  const parentId = parentIdById[id];

  if (parentId === undefined) {
    return [];
  }

  return [
    parentId,
    ...getAllAncestors(parentId, parentIdById)
  ];
};


const getAllDescendants = (id: string, childIdsById: Record<string, string[]>): string[] => {
  const childIds = childIdsById[id] ?? [];

  return childIds.reduce(
    (result, childId) => {
      getAllDescendants(childId, childIdsById).forEach(descendantId => result.push(descendantId));

      return result;
      // return [
      //   ...result,
      //   ...getAllDescendants(childId, childIdsById)
      // ]
    },
    [ ...childIds ]
  );
};
