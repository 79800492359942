import { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { store } from "@/store/index";



function StoreProvider(props: PropsWithChildren) {
  const { children } = props;

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}


export default StoreProvider;