import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent, ValueFormatterParams } from "@ag-grid-community/core";
import { isDefined, isNotDefined } from "@sgme/fp";
import { TradeResponse } from "@credit-control-risk/common";
import StatusBadge from "@/components/StatusBadge";
import { search } from "@/utils/searcher";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import FormGroup from "@/components/sg-ui/form/FormGroup";
import SearchInput from "@/components/sg-ui/form/SearchInput";
import { useGetProducts } from "@/store/api/webapi";
import { safetyEditorSlice, setAssetIds } from "@/components/SafetyEditor/store/slice";
import { SafetyEditorState } from "@/components/SafetyEditor/store/state";
import { AppState } from "@/store";



export interface AssetFormGroupProps {
  rootId: string;
  excludedAssetSourceProductIds?: string[];
}


function AssetFormGroup(props: AssetFormGroupProps) {
  const { rootId, excludedAssetSourceProductIds = [] } = props;

  const dispatch = useDispatch();

  const {
    safetyId,
    areManyAssetsSelectable,
    selectedAssetSourceProductIds
  } = useSelector((appState: AppState) => {
    const state = appState.ui[safetyEditorSlice.name] as SafetyEditorState;

    return {
      safetyId:                      state.safetyId,
      areManyAssetsSelectable:       state.areManyAssetsSelectable,
      selectedAssetSourceProductIds: state.selectedAssetReferences.map(ref => ref.sourceProductId)
    };
  });

  const [ query, setQuery ] = useState("");

  const { data: allAssets, isLoading: isAssetLoading } = useGetProducts({
    rootId,
    scope:    "ASSET",
    unlinked: isNotDefined(safetyId) // for creation, only load unlinked assets
  });

  const intl = useIntl();

  const apiRef = useRef<GridApi>();

  const onGridReady = (event: GridReadyEvent) => {
    apiRef.current = event.api;

    apiRef.current.forEachNode(node => {
      if (selectedAssetSourceProductIds.includes(node.data.sourceProductId)) {
        node.setSelected(true);
      }
    });
  };

  const onSelectionChanged = (event: RowSelectedEvent) => {
    // selection can't be changed for existing safety
    if (isDefined(safetyId)) {
      return;
    }

    const selectedNodes = event.api.getSelectedNodes();
    const allSelectedAssetIds = selectedNodes.map(node => node.data.sourceProductId);

    dispatch(setAssetIds(allSelectedAssetIds));
  };

  const loadedRows: TradeResponse[] = allAssets?.trades?.filter(asset => isNotDefined(asset.sourceProductId) || !excludedAssetSourceProductIds.includes(asset.sourceProductId)) ?? [];

  // for an existing safety, only selected assets are visible, and they can't be changed
  const allVisibleAssets = isDefined(safetyId)
    ? loadedRows.filter(asset => isDefined(asset.sourceProductId) && selectedAssetSourceProductIds.includes(asset.sourceProductId))
    : loadedRows;

  const rows = search(query, allVisibleAssets, SEARCHER_OPTION_KEYS);

  // asset references for an existing safety can't be changed
  const canSelectAssets = !isDefined(safetyId);

  return (
    <FormGroup labelId={isDefined(safetyId) ? "components.SafetyEditor.assetForm.title.edit" : "components.SafetyEditor.assetForm.title.create"}>
      <div style={{ height: "200px", width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
        {
          !isAssetLoading && allVisibleAssets.length === 0 && (
            <div className="card my-3">
              {
                isDefined(safetyId)
                  ? <div className="card-body bg-warning text-white">
                    <FormattedMessage id="components.SafetyEditor.assetForm.noAssetFound"/>
                  </div>
                  : <div className="card-body bg-danger text-white">
                    <FormattedMessage id="components.SafetyEditor.assetForm.noAssetAvailable"/>
                  </div>
              }
            </div>
          )
        }

        <header className="mb-2 w-50">
          <SearchInput
            name="query"
            value={query}
            placeholderLabelId="components.SafetyEditor.assetForm.searchPlaceholder"
            onChange={event => {
              setQuery(event.currentTarget.value);
            }}
          />
        </header>

        <AgGridReact
          rowData={rows}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={addHeaderIntlName("components.SafetyEditor.assetForm.table.column", getTableColumns(canSelectAssets, areManyAssetsSelectable), intl)}
          modules={[ ClientSideRowModelModule ]}
          rowSelection={areManyAssetsSelectable ? "multiple" : "single"}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          enableCellTextSelection
        />
      </div>
    </FormGroup>
  );
}


export default AssetFormGroup;

const getTableColumns = (canSelect: boolean, areManyAssetsSelectable: boolean) => (intl: IntlShape): ColDef[] => [
  {
    field:                   "productName",
    minWidth:                200,
    headerCheckboxSelection: areManyAssetsSelectable,
    checkboxSelection:       canSelect,
    showDisabledCheckboxes:  canSelect
  },
  {
    field:    "sourceProductId",
    minWidth: 90
  },
  {
    field:          "notionalAmount",
    minWidth:       90,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "notionalAmountCurrency",
    minWidth: 90
  },
  {
    field:          "notionalAmountEur",
    minWidth:       90,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:          "interestRate",
    minWidth:       90,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "maturity",
    minWidth: 90
  },
  {
    field:          "interestAmount",
    minWidth:       90,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "pledgeIndicator",
    minWidth: 90
  },
  {
    field:        "status",
    minWidth:     90,
    cellRenderer: (props: {
      value: "VERIFIED" | undefined
    }) => props.value === "VERIFIED"
      ? <StatusBadge active labelId="components.SafetyEditor.assetForm.table.column.status.verified"/>
      : null
  }
];

const SEARCHER_OPTION_KEYS = [
  { name: "productName", weight: 2 },
  { name: "sourceProductId", weight: 2 },
  { name: "notionalAmount", weight: 2 },
  { name: "notionalAmountCurrency", weight: 1 },
  { name: "notionalAmountEur", weight: 1 },
  { name: "interestRate", weight: 1 },
  { name: "maturity", weight: 1 },
  { name: "interestAmount", weight: 1 },
  { name: "pledgeIndicator", weight: 2 },
  { name: "status", weight: 2 }
];