import { useIntl } from "react-intl";
import { isDefined } from "@sgme/fp";
import FormGroup from "@/components/sg-ui/form/FormGroup";
import FormRow from "@/components/sg-ui/form/FormRow";
import NumberInput from "@/components/sg-ui/form/NumberInput";
import Percentage from "@/components/sg-ui/form/PercentageInput";
import TextInput from "@/components/sg-ui/form/TextInput";
import YesNoInput from "@/components/sg-ui/form/YesNoInput";
import DatePicker from "@/components/sg-ui/form/pickers/DatePicker";
import CurrencyPicker from "@/components/sg-ui/form/pickers/CurrencyPicker";
import SelectPicker, { SelectPickerOption } from "@/components/sg-ui/form/pickers/SelectPicker";
import { useSafetyEditorForm, useSafetyType } from "@/components/SafetyEditor/store/hooks";
import { useGetSafetyElementaryTypes } from "@/store/api/webapi";
import { PROVIDER_PROPERTY_REF_FORMAT } from "@/components/SafetyEditor/store/state";



function SafetyFormGroup() {
  const intl = useIntl();

  const [ form, registerFormField, isEditable ] = useSafetyEditorForm();

  const hasFixeAmount = form.indicatorTypeCoverage === "F";
  const hasPourcentageExposure = form.indicatorTypeCoverage === "P" || form.indicatorTypeCoverage === "C";
  const hasMaxAmountCoverted = form.indicatorTypeCoverage === "C";

  const { selectedSafetyTypeId } = useSafetyType();

  const { data: allSafetyElementaryTypes } = useGetSafetyElementaryTypes();

  const safetyTypeLabel = isDefined(allSafetyElementaryTypes)
    ? allSafetyElementaryTypes.types?.find(type => type.safetyElementaryTypeLabelCode === selectedSafetyTypeId)
    : undefined;

  return (
    <FormGroup labelId="components.SafetyEditor.safetyForm.title">
      <FormRow labelId="components.SafetyEditor.safetyForm.safetyType.code" className="my-2">
        {selectedSafetyTypeId}
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.safetyType.label" className="my-2">
        {intl.locale === "fr" ? safetyTypeLabel?.safetyElementaryTypeLabelFr : safetyTypeLabel?.safetyElementaryTypeLabelEn}
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.indicatorTypeCoverage.label">
        <SelectPicker
          {...registerFormField("indicatorTypeCoverage")}
          options={SAFETY_COVERAGE_TYPES}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.safetyForm.indicatorTypeCoverage.placeholder"
        />
      </FormRow>

      {
        hasFixeAmount && (
          <>
            <FormRow labelId="components.SafetyEditor.safetyForm.fixeAmount.label">
              <NumberInput
                {...registerFormField("fixeAmount")}
                editable={isEditable}
                placeholderLabelId="components.SafetyEditor.safetyForm.fixeAmount.placeholder"
              />
            </FormRow>

            <FormRow labelId="components.SafetyEditor.safetyForm.fixeAmountCurrency.label">
              <CurrencyPicker
                {...registerFormField("fixeAmountCurrency")}
                editable={isEditable}
              />
            </FormRow>
          </>
        )
      }

      {
        hasPourcentageExposure && (
          <FormRow labelId="components.SafetyEditor.safetyForm.percentageExposureCovered.label">
            <Percentage
              {...registerFormField("percentageExposureCovered")}
              editable={isEditable}
              placeholderLabelId="components.SafetyEditor.safetyForm.percentageExposureCovered.placeholder"
            />
          </FormRow>
        )
      }

      {
        hasMaxAmountCoverted && (
          <>
            <FormRow labelId="components.SafetyEditor.safetyForm.maxAmountCovered.label">
              <NumberInput
                {...registerFormField("maxAmountCovered")}
                editable={isEditable}
                placeholderLabelId="components.SafetyEditor.safetyForm.maxAmountCovered.placeholder"
              />
            </FormRow>

            <FormRow labelId="components.SafetyEditor.safetyForm.maxAmountCoveredCurrency.label">
              <CurrencyPicker
                {...registerFormField("maxAmountCoveredCurrency")}
                editable={isEditable}
              />
            </FormRow>
          </>
        )
      }

      <FormRow labelId="components.SafetyEditor.safetyForm.validityStartDate.label">
        <DatePicker
          {...registerFormField("validityStartDate")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.administrativeValidityEndDate.label">
        <DatePicker
          {...registerFormField("administrativeValidityEndDate")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.validityEndDate.label">
        <DatePicker
          {...registerFormField("validityEndDate")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.legalValidityIndicator.label">
        <YesNoInput
          {...registerFormField("legalValidityIndicator")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.indicationCreditRiskMitigatoryEnforceability.label">
        <YesNoInput
          {...registerFormField("indicationCreditRiskMitigatoryEnforceability")}
          editable={isEditable}
        />
      </FormRow>

      <FormRow labelId="components.SafetyEditor.safetyForm.thirdPartySafetyProvider.label">
        <TextInput
          {...registerFormField("thirdPartySafetyProvider")}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.safetyForm.thirdPartySafetyProvider.placeholder"
          format={PROVIDER_PROPERTY_REF_FORMAT}
          textcase="uppercase"
        />
      </FormRow>
    </FormGroup>
  );
}


const SAFETY_COVERAGE_TYPES: SelectPickerOption<"F" | "P" | "C">[] = [
  { value: "F", labelId: "components.SafetyEditor.safetyForm.safetyindicatorTypeCoverages.fixed" },
  { value: "P", labelId: "components.SafetyEditor.safetyForm.safetyindicatorTypeCoverages.proportional" },
  { value: "C", labelId: "components.SafetyEditor.safetyForm.safetyindicatorTypeCoverages.cappedProportional" }

];

const LEGAL_VALIDITY_INDICATOR_OPTIONS: SelectPickerOption<"Yes" | "No">[] = [
  { value: "Yes", labelId: "components.SafetyEditor.safetyForm.legalValidityIndicatorOptions.yes" },
  { value: "No", labelId: "components.SafetyEditor.safetyForm.legalValidityIndicatorOptions.no" }
];

const ENFORCEABILITY_INDICATOR_OPTIONS: SelectPickerOption<"Yes" | "No">[] = [
  { value: "Yes", labelId: "components.SafetyEditor.safetyForm.indicationCreditRiskMitigatoryEnforceabilityOptions.yes" },
  { value: "No", labelId: "components.SafetyEditor.safetyForm.indicationCreditRiskMitigatoryEnforceabilityOptions.no" }
];

export default SafetyFormGroup;