import { Button } from "reactstrap";
import { useState } from "react";
import RootIDSelector from "@/components/RootIDSelector";



export type RootIDButtonProps = {
  rootId: string
}


function RootIDButton(props: RootIDButtonProps): React.JSX.Element {
  const { rootId } = props;

  const [ isSelectorOpened, setIsSelectorOpened ] = useState(false);

  const onClick = () => {
    setIsSelectorOpened(true);
  };

  const onClose = () => {
    setIsSelectorOpened(false);
  };

  return (
    <>
      <Button className="btn-outline-secondary" onClick={onClick}>
        Root ID : {rootId}
        <i className="icon ms-2">swap_horiz</i>
      </Button>

      <RootIDSelector isOpen={isSelectorOpened} onClose={onClose}/>
    </>
  );
}


export default RootIDButton;