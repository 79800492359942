import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { isDefined } from "@sgme/fp";
import { AuthorizationResponse, SafetyResponse, TradeResponse } from "@credit-control-risk/common";
import { addValueOnceIn, keepOnlyValuesFrom, toggleValueIn } from "@/utils/fp";
import {
  initManySelectable,
  initSingleSelectable,
  loadItems,
  SelectableItemError,
  SelectableItemStatusType,
  selectManyItems,
  selectSingleItem,
  startLoading,
  stopLoadingWithError
} from "@/utils/selectable";
import { SafetyChange } from "@/components/SafetyEditor/store/types";
import { initNewLinkState, NewLinkState, Panel, Step } from "@/routes/links/new-link/store/state";
import { canGoToNextStep, canGoToPreviousStep, canHaveSafeties } from "@/routes/links/new-link/store/api";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";
import { formatJSONDate } from "@/utils/date";
import { formatYNBoolean } from "@/utils/boolean";


// ███████╗██╗     ██╗ ██████╗███████╗
// ██╔════╝██║     ██║██╔════╝██╔════╝
// ███████╗██║     ██║██║     █████╗
// ╚════██║██║     ██║██║     ██╔══╝
// ███████║███████╗██║╚██████╗███████╗
// ╚══════╝╚══════╝╚═╝ ╚═════╝╚══════╝

export const newLinkSlice = createSlice({
  name:         "new-link",
  initialState: initNewLinkState(),
  reducers:     {
    setRootId(state, action: PayloadAction<string>) {
      return {
        ...state,

        rootId: action.payload
      };
    },

    reset(state) {
      return initNewLinkState();
    },

    startProductLoading(state) {
      return (
        {
          ...state,

          product: startLoading(Date.now(), state.product)
        }
      );
    },

    loadProducts(state, action: PayloadAction<TradeResponse[]>) {
      return {
        ...state,

        product: loadItems(action.payload, Date.now(), state.product)
      };
    },

    stopProductLoadingWithError(state, action: PayloadAction<{
      code: SelectableItemError,
      message: string,
      stack: string[]
    }>) {
      return {
        ...state,

        product: stopLoadingWithError(Date.now(), action.payload.code, action.payload.message, action.payload.stack, state.product)
      };
    },

    selectProductId(state, action: PayloadAction<string | undefined>) {
      const selectedProduct = action.payload !== undefined
        ? state.product.all.find(product => product.sourceProductId === action.payload)
        : undefined;

      return (
        {
          ...state,

          product:       selectSingleItem(selectedProduct, state.product),
          authorization: initSingleSelectable(),
          safeties:      initManySelectable(),

          currentStep:    Step.PRODUCT_SELECTION,
          lastActiveStep: Step.PRODUCT_SELECTION,
          openedPanels:   [ Panel.PRODUCT ],

          canHaveSafeties: canHaveSafeties(selectedProduct, undefined),

          newAuthorization: undefined,
          newSafeties:      []
        }
      );
    },

    startAuthorizationLoading(state) {
      return {
        ...state,

        authorization: startLoading(Date.now(), state.authorization)
      };
    },


    loadAuthorizations(state, action: PayloadAction<AuthorizationResponse[]>) {
      return {
        ...state,

        authorization: loadItems(action.payload, Date.now(), state.authorization)
      };
    },

    stopAuthorizationLoadingWithError(state, action: PayloadAction<{
      code: SelectableItemError,
      message: string,
      stack: string[]
    }>) {
      return {
        ...state,

        authorization: stopLoadingWithError(Date.now(), action.payload.code, action.payload.message, action.payload.stack, state.authorization)
      };
    },

    selectAuthorizationId(state, action: PayloadAction<string | undefined>) {
      if (state.product.selected === undefined) {
        return state;
      }

      const selectedAuthorization = action.payload !== undefined
        ? state.authorization.all.find(authorization => authorization.authorizationRef === action.payload)
        : undefined;

      return {
        ...state,

        authorization: selectSingleItem(selectedAuthorization, state.authorization),
        safeties:      initManySelectable(),

        currentStep:    Step.AUTHORIZATION_SELECTION,
        lastActiveStep: Step.AUTHORIZATION_SELECTION,
        openedPanels:   addValueOnceIn(
          Panel.AUTHORIZATION,
          keepOnlyValuesFrom([ Panel.PRODUCT ], state.openedPanels)
        ),

        canHaveSafeties: canHaveSafeties(state.product.selected, selectedAuthorization),

        newSafeties: []
      };
    },

    createAuthorization(state, action: PayloadAction<{ authorization: AuthorizationChange, id: string, productCode: string }>): NewLinkState {
      const previousNewAuthorization = state.newAuthorization;

      const authorizationResponse: AuthorizationResponse = {
        authorizationRef: action.payload.id,

        productCode: action.payload.productCode, // group product code

        validityStartDate:          formatJSONDate(action.payload.authorization.validityStartDate),
        exposureMaturityDate:       formatJSONDate(action.payload.authorization.exposureMaturityDate),
        administrativeValidityDate: formatJSONDate(action.payload.authorization.administrativeValidityDate),
        amount:                     Number(action.payload.authorization.authorizationAmount),
        currency:                   action.payload.authorization.authorizationAmountCurrency,
        exposureDefaultType:        action.payload.authorization.exposureDefaultType,
        protectionIndication:       formatYNBoolean(action.payload.authorization.protectionIndication),
        comment:                    action.payload.authorization.comments
      };

      const previousAllAuthorizations = isDefined(previousNewAuthorization)
        ? state.authorization.all.filter(authorization => authorization.authorizationRef !== previousNewAuthorization.response.authorizationRef)
        : state.authorization.all;

      return {
        ...state,

        newAuthorization: {
          change:   action.payload.authorization,
          response: authorizationResponse
        },

        authorization: {
          status:   state.authorization.status,
          all:      [
            ...previousAllAuthorizations,
            authorizationResponse
          ],
          selected: authorizationResponse
        }
      };
    },

    startSafetyLoading(state) {
      return {
        ...state,

        safeties: startLoading(Date.now(), state.safeties)
      };
    },

    loadSafeties(state, action: PayloadAction<SafetyResponse[]>) {
      const loadedSafeties = loadItems(action.payload, Date.now(), state.safeties);

      return {
        ...state,

        safeties: loadedSafeties
      };
    },

    stopSafetyLoadingWithError(state, action: PayloadAction<{
      code: SelectableItemError,
      message: string,
      stack: string[]
    }>) {
      return {
        ...state,

        safeties: stopLoadingWithError(Date.now(), action.payload.code, action.payload.message, action.payload.stack, state.safeties)
      };
    },

    startSelectingSafeties(state) {
      if (state.product.selected === undefined || state.authorization.selected === undefined) {
        return state;
      }

      return {
        ...state,

        safeties: {
          ...state.safeties,

          status: state.safeties.status.type === SelectableItemStatusType.LOADED
                    ? {
              type:     SelectableItemStatusType.LOADED,
              loadedAt: Date.now()
            }
                    : {
              type:      SelectableItemStatusType.PENDING,
              startedAt: Date.now()
            }
        },

        currentStep:              Step.SAFETY_SELECTION,
        lastActiveStep:           Step.SAFETY_SELECTION,
        isSafetySelectionStopped: false,
        openedPanels:             addValueOnceIn(
          Panel.SAFETY,
          keepOnlyValuesFrom([ Panel.PRODUCT, Panel.AUTHORIZATION ], state.openedPanels)
        )
      };
    },

    stopSelectingSafeties(state) {
      if (state.product.selected === undefined || state.authorization.selected === undefined) {
        return state;
      }

      return {
        ...state,

        currentStep:              Step.SAFETY_SELECTION,
        lastActiveStep:           Step.SAFETY_SELECTION,
        isSafetySelectionStopped: true,
        openedPanels:             []
      };
    },

    selectSafetyIds(state, action: PayloadAction<string[]>) {
      if (state.product.selected === undefined || state.authorization.selected === undefined || state.currentStep !== Step.SAFETY_SELECTION) {
        return state;
      }

      const selectedSafeties = (
        action.payload
        .map(id => state.safeties.all.find(safety => safety.id === id))
        .filter(safety => safety !== undefined) as SafetyResponse[]
      );

      return {
        ...state,

        safeties: selectManyItems(selectedSafeties, state.safeties),

        currentStep:              Step.SAFETY_SELECTION,
        lastActiveStep:           Step.SAFETY_SELECTION,
        isSafetySelectionStopped: false,
        openedPanels:             addValueOnceIn(
          Panel.SAFETY,
          keepOnlyValuesFrom([ Panel.PRODUCT, Panel.AUTHORIZATION ], state.openedPanels)
        )
      };
    },

    createSafety(state, action: PayloadAction<{ safety: SafetyChange, id: string }>): NewLinkState {
      const safetyResponse: SafetyResponse = {
        id:                   action.payload.id,
        lastModificationDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        rootId:               state.rootId!,

        applicableLawCountryCode: action.payload.safety.applicableLawCountryCode,
        clientCodeType:           "",
        fixedAmount:              Number(action.payload.safety.fixeAmount),
        fixedAmountCurrency:      action.payload.safety.fixeAmountCurrency,

        grossAmountEuro:                               0,
        guaranteeOrderNew:                             "",
        indicationCreditRiskMitigatoryEnforceability:  action.payload.safety.indicationCreditRiskMitigatoryEnforceability,
        indicatorCreditRiskMitigatoryActLegalValidity: action.payload.safety.legalValidityIndicator,
        indicatorTypeCoverage:                         action.payload.safety.indicatorTypeCoverage,
        maxAmountCovered:                              Number(action.payload.safety.maxAmountCovered),
        maxAmountCoveredCurrency:                      action.payload.safety.maxAmountCoveredCurrency,
        nomenclatureId:                                "TODO: ",
        percentageExposureCovered:                     Number(action.payload.safety.percentageExposureCovered),
        safetyGroupType:                               action.payload.safety.safetyTypeId,
        safetySequenceNo:                              "0",
        securitySubject:                               "",
        thirdPartySafetyRecipient:                     "",
        type:                                          "",
        validityEndDate:                               action.payload.safety.validityEndDate,
        validityStartDate:                             action.payload.safety.validityStartDate
      };

      return {
        ...state,

        newSafeties: [
          ...state.newSafeties,

          {
            change:   action.payload.safety,
            response: safetyResponse
          }
        ],

        safeties: {
          status:   state.safeties.status,
          all:      [
            ...state.safeties.all,
            safetyResponse
          ],
          selected: [
            ...state.safeties.selected,
            safetyResponse
          ]
        }
      };
    },

    deleteNewSafety(state, action: PayloadAction<SafetyChange>) {
      return {
        ...state,

        newSafety: undefined
      };
    },

    goToPreviousStep(state) {
      const previousStep: Step = state.currentStep - 1;
      const previousOpenedPanels = PANELS_TO_OPENED_FOR_STEP[previousStep];

      return canGoToPreviousStep(state)
        ? {
          ...state,

          currentStep:  previousStep,
          openedPanels: previousOpenedPanels
        }
        : state;
    },

    goToNextStep(state) {
      const nextStep: Step = state.currentStep + 1;
      const nextOpenedPanels = PANELS_TO_OPENED_FOR_STEP[nextStep];

      return canGoToNextStep(state)
        ? {
          ...state,

          currentStep:    nextStep,
          lastActiveStep: state.lastActiveStep < nextStep ? nextStep : state.lastActiveStep,
          openedPanels:   nextOpenedPanels
        }
        : state;
    },

    togglePanel(state, action: PayloadAction<Panel>) {
      return {
        ...state,

        openedPanels: toggleValueIn(action.payload, state.openedPanels)
      };
    },

    openPanel(state, action: PayloadAction<Panel>) {
      return {
        ...state,

        openedPanels: addValueOnceIn(action.payload, state.openedPanels)
      };
    },

    linksSaved(state) {
      return {
        ...state,

        currentStep:    Step.LINK_CREATION,
        lastActiveStep: Step.LINK_CREATION,
        openedPanels:   []
      };
    }
  }
});

// ██████╗  █████╗ ████████╗ █████╗
// ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
// ██║  ██║███████║   ██║   ███████║
// ██║  ██║██╔══██║   ██║   ██╔══██║
// ██████╔╝██║  ██║   ██║   ██║  ██║
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

const PANELS_TO_OPENED_FOR_STEP = {
  [Step.PRODUCT_SELECTION]:       [ Panel.PRODUCT ],
  [Step.AUTHORIZATION_SELECTION]: [ Panel.AUTHORIZATION ],
  [Step.SAFETY_SELECTION]:        [ Panel.SAFETY ],
  [Step.LINK_CREATION]:           []
};

//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

export const {
  setRootId,
  reset,
  startProductLoading,
  loadProducts,
  stopProductLoadingWithError,
  selectProductId,
  startAuthorizationLoading,
  loadAuthorizations,
  stopAuthorizationLoadingWithError,
  selectAuthorizationId,
  createAuthorization,
  startSafetyLoading,
  loadSafeties,
  stopSafetyLoadingWithError,
  startSelectingSafeties,
  stopSelectingSafeties,
  selectSafetyIds,
  createSafety,
  deleteNewSafety,
  goToPreviousStep,
  goToNextStep,
  togglePanel,
  openPanel,
  linksSaved

} = newLinkSlice.actions;
