import styled from "@emotion/styled";
import { PropsWithChildren } from "react";



export const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageHeader = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <StyledPageHeader className="border-bottom">
      <div>
        {children}
      </div>
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled.header`
  flex-shrink: 0;

  & > div {
    padding: 0 24px;
  }
`;

export const PageMain = (props: PropsWithChildren<{ className?: string }>) => {
  const { className, children } = props;

  return (
    <StyledPageMain className={`h-100 pt-3 bg-light bg-opacity-10 ${className}`}>
      {children}
    </StyledPageMain>
  );
};

const StyledPageMain = styled.main`
  position: relative;

  flex: 1 1 auto;

  padding: 0 24px;
  overflow: hidden;
`;

export const PageFooter = styled.footer`
  flex-shrink: 0;

  padding: 16px 24px;
`; 
