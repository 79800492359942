import { FormattedMessage } from "react-intl";
import VStack from "@/components/sg-ui/layout/VStack";
import HStack from "@/components/sg-ui/layout/HStack";
import { useEffect, useState } from "react";
import { isDefined } from "@sgme/fp";



export interface LoadingProps {
  messageId?: string;
  // delai in ms
  visibilityDelay?: number;
}

const DEFAULT_MESSAFE_ID = "components.LoadingPage.message";


function Loading(props: LoadingProps) {
  const { messageId, visibilityDelay = 1000 } = props;

  const [ isVisible, setVisibility ] = useState(visibilityDelay === 0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibility(true);
    }, visibilityDelay);

    return () => {
      clearTimeout(timer);
    };
  });

  if (!isVisible) {
    return undefined;
  }

  return (
    // TODO: don't work
    // SG-bootstrap: https://developer.sgmarkets.com/docs/design-system/components/loaders/code.html
    //
    // <div className="spinner-grow" role="status">
    //   <FormattedMessage id={messageId}/>
    // </div>

    <VStack verticalAlignment="center" horizontalAlignment="center" fullHeight>
      <HStack gap="2">
        <span className="spinner blinker-sm me-2" role="status"/>

        {
          isDefined(messageId) && (
            <FormattedMessage id={messageId}/>
          )
        }
      </HStack>
    </VStack>
  );
}


export default Loading;