import { useEffect, useRef, useState } from "react";
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent, ValueFormatterParams } from "@ag-grid-community/core";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isDefined } from "@sgme/fp";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import CollapsablePanel from "@/components/CollapsablePanel";
import InfoCard from "@/components/InfoCard";
import SafetyEditor from "@/components/SafetyEditor";
import { AppState } from "@/store";
import { createSafety, newLinkSlice, selectSafetyIds, startSelectingSafeties, stopSelectingSafeties, togglePanel } from "@/routes/links/new-link/store/slice";
import { SafetyChange } from "@/components/SafetyEditor/store/types";
import { NewLinkState, Panel, Step } from "@/routes/links/new-link/store/state";
import { SelectableItemStatusType } from "@/utils/selectable";
import { formatDate } from "@/utils/formatters";
import { useSafetyElementaryByCode } from "@/utils/hooks/useSafetyElementaryByCode";
import { generateNewSafetyId } from "@/routes/links/new-link/store/api";
import { isSelectedInManySelectionSelection } from "@/utils/state/selection-many";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import { mapAndMerge } from "@/utils/fp";
import { isSafetyCreation } from "@/components/SafetyEditor/store/api";



function SafetySelector(): React.JSX.Element {
  const intl = useIntl();

  const [ getSafetyTypeLabel ] = useSafetyElementaryByCode();

  const dispatch = useDispatch();

  const data = useSelector((appState: AppState) => {
    const state = appState.ui[newLinkSlice.name] as NewLinkState;

    const isEditable = state.currentStep !== Step.LINK_CREATION;

    return {
      rootId:                   state.rootId as string,
      areManyAssetsSelectable:  state.product.selected?.typeLabel === "CreditCard",
      isOpened:                 isSelectedInManySelectionSelection(state.openedPanels, Panel.SAFETY),
      safeties:                 state.safeties.all,
      isSafetySelectionStopped: state.isSafetySelectionStopped,
      selectedSafeties:         state.safeties.selected,
      newSafeties:              state.newSafeties,
      isLoading:                state.safeties.status.type !== SelectableItemStatusType.LOADED,
      isEditable,
      hasError:                 false,
      canCreateSafety:          isEditable && state.authorization.selected?.protectionIndication === "N"
    };
  });

  const {
    rootId,
    areManyAssetsSelectable,
    isOpened,
    safeties,
    isSafetySelectionStopped,
    selectedSafeties,
    newSafeties,
    isEditable,
    isLoading,
    canCreateSafety
  } = data;

  const rows = safeties.map(safety => (
    {
      safetyGroupType:         safety.safetyGroupType,
      safetyGroupTypeLabel:    getSafetyTypeLabel(safety.safetyGroupType),
      amount:                  safety.fixedAmount,
      currency:                safety.fixedAmountCurrency,
      lastModificationDate:    safety.lastModificationDate,
      riskMonitoringIndicator: safety.indicatorTypeCoverage,
      safetySequenceNo:        safety.safetySequenceNo,
      safetyId:                safety.id
    }
  ));

  const [ isSafetyEditorVisible, setSafetyEditorVisibility ] = useState(false);

  const toggleSafetyEditorVisibility = () => setSafetyEditorVisibility(isVisible => !isVisible);

  const apiRef = useRef<GridApi>();

  const onTogglePanel = () => {
    dispatch(togglePanel(Panel.SAFETY));
  };

  const onGridReady = (event: GridReadyEvent) => {
    apiRef.current = event.api;

    if (isLoading) {
      apiRef.current?.showLoadingOverlay();
    }
  };

  useEffect(() => {
    if (!isDefined(selectedSafeties) || selectedSafeties.length === 0) {
      return;
    }

    apiRef.current?.forEachNode(node => {
      if (selectedSafeties.some(safety => node.data.safetyId === safety.id)) {
        node.setSelected(true, false);
      }
      else {
        node.setSelected(false, false);
      }
    });
  }, [ selectedSafeties ]);

  useEffect(() => {
    if (!isLoading) {
      apiRef.current?.hideOverlay();
    }
  }, [ isLoading ]);

  useEffect(() => {
    apiRef.current?.forEachNode(node => node.setRowSelectable(isEditable));
  }, [ isEditable ]);


  const onSelectionChanged = (event: RowSelectedEvent) => {
    // TODO: confirm to delete new safety is unselected

    const selectedNodes = event.api.getSelectedNodes();
    const safetyIds = selectedNodes.map(node => node.data.safetyId);

    dispatch(selectSafetyIds(safetyIds));
  };

  const onStartSelectingSafeties = () => {
    dispatch(startSelectingSafeties());
  };

  const onStopSelectingSafeties = () => {
    dispatch(stopSelectingSafeties());
  };

  const onCreateSafety = (safety: SafetyChange) => {
    dispatch(createSafety({ safety, id: generateNewSafetyId() }));
  };

  const reservedUnlinkedAssetSourceProductIds = mapAndMerge(
    newSafeties,
    (safety): string[] => isSafetyCreation(safety.change) ? safety.change.assetSourceProductIds : []
  );

  return (
    <CollapsablePanel
      nameId="routes.new-link.safety.Safety"
      isOpened={isOpened}
      toggle={onTogglePanel}
    >
      <>
        {
          isEditable && (
            <div className="mb-3">
              <FormattedMessage id="routes.new-link.safety.moreSafeties"/>

              <Button className="btn btn-outline-primary ms-3" active={isSafetySelectionStopped === false}
                      onClick={onStartSelectingSafeties}>YES</Button>
              <Button className="btn btn-outline-primary ms-1" active={isSafetySelectionStopped === true}
                      onClick={onStopSelectingSafeties}>NO</Button>
            </div>
          )
        }

        <div style={{ height: 258, width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
          <AgGridReact
            rowData={rows}
            defaultColDef={DEFAULT_COLUMN_DEF}
            columnDefs={addHeaderIntlName("routes.new-link.safety.table.column", getTableColumns, intl)}
            modules={[ ClientSideRowModelModule ]}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
            enableCellTextSelection
          />
        </div>

        <InfoCard className="mt-4">
          <main className="flex-fill">
            <FormattedMessage id="routes.new-link.safety.create.label"/>
          </main>

          <aside>
            <Button className="btn-outline-info" onClick={toggleSafetyEditorVisibility} disabled={!canCreateSafety}>
              <FormattedMessage id="routes.new-link.safety.create.button"/>
            </Button>

            {
              isSafetyEditorVisible && (
                <SafetyEditor
                  rootId={rootId}
                  manyAssetsSelectable={areManyAssetsSelectable}
                  excludedAssetSourceProductIds={reservedUnlinkedAssetSourceProductIds}
                  opened={isSafetyEditorVisible}
                  toggle={toggleSafetyEditorVisibility}
                  editable
                  onSaveOrCreate={onCreateSafety}
                />
              )
            }
          </aside>
        </InfoCard>
      </>
    </CollapsablePanel>
  );
}


const getTableColumns = (intl: IntlShape): ColDef[] => [
  {
    field:                   "safetyGroupType",
    minWidth:                150,
    headerCheckboxSelection: true,
    checkboxSelection:       true,
    showDisabledCheckboxes:  true
  },
  {
    field:    "safetyGroupTypeLabel",
    minWidth: 150
  },
  {
    field:          "amount",
    type:           "rightAligned",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "currency",
    minWidth: 150
  },
  {
    field:          "lastModificationDate",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, intl)
  },

  {
    field:    "safetySequenceNo",
    minWidth: 100
  },
  {
    field:    "riskMonitoringIndicator",
    minWidth: 150
  }
];

export default SafetySelector;