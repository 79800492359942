import { useRouteError } from "react-router-dom";
import Header from "@/routes/_layout/Header";
import Footer from "@/routes/_layout/Footer";
import { IntlProvider } from "@/core/i18n";
import { AppLayoutContainer } from "@/routes/_layout";
import React from "react";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";



function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  return (
    <IntlProvider>
      <AppLayoutContainer className="bg-primary-alt">
        <Header/>

        <main className="m-8">
          <ErrorPanel tips="reload+go-home"/>
        </main>

        <Footer/>
      </AppLayoutContainer>
    </IntlProvider>
  );
}


export default ErrorBoundary;