import { getAppConfig } from "@/core/config";
import { SgwtConnectHTMLElement } from "@/core/types/sgwt-widgets";
import { setupSGWTConnect } from "@sgwt/connect-core";

export const sgwtConnect = setupSGWTConnect(getAppConfig().sgconnect);

export function setupSgwtConnectWidget() {
  const widget = document.querySelector<SgwtConnectHTMLElement>("sgwt-connect");

  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === "undefined") {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener("sgwt-connect--ready", handleSgwtConnectReady);
      };

      widget.addEventListener("sgwt-connect--ready", handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }

  return sgwtConnect;
}