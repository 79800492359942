import { MutableRefObject, useRef } from "react";



export type UseScrollIntoViewOptions = {
  ref?: MutableRefObject<HTMLElement>
  scrollBehaviors?: ScrollBehavior
}

export const useScrollIntoView = (options?: UseScrollIntoViewOptions) => {
  const ref = useRef<HTMLElement>(null);

  const elementRef = options?.ref ?? ref;

  const scrollToRef = () => {
    elementRef.current?.scrollIntoView({ behavior: options?.scrollBehaviors ?? "smooth" });
  };

  return [ elementRef, scrollToRef ];
};