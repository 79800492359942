import { AgGridReact } from "@ag-grid-community/react";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Navigate, useParams } from "react-router-dom";
import { isDefined } from "@sgme/fp";

import RootIDHeader from "@/components/RootIDHeader";
import StatusBadge from "@/components/StatusBadge";
import { search } from "@/utils/searcher";
import { PageHeader, PageMain, PageRoot } from "@/routes/_layout/page-styles";
import RouteTabs from "@/components/RouteTabs";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import SafetyFilters from "@/routes/safeties/components/SafetyFilters";
import { useGetSafeties } from "@/store/api/webapi";
import Loading from "@/components/sg-ui/Loading";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";
import ActionCell from "@/routes/safeties/components/ActionCell";
import { useSafetyElementaryByCode } from "@/utils/hooks/useSafetyElementaryByCode";



function SafetiesRoute(): React.JSX.Element {
  const { rootId } = useParams() as {
    rootId: string
  };
  const intl = useIntl();

  const { data: safetiesResponse, error, isLoading } = useGetSafeties(
    { rootId, size: 200 },
    {
      // pollingInterval:           2 * 60 * 1000, // every 2 minutes
      refetchOnMountOrArgChange: true,
      skip:                      false
    }
  );

  const [ getSafetyTypeLabel ] = useSafetyElementaryByCode();

  const [ query, setQuery ] = useState("");

  const [ selectedStatus, selectStatus ] = useState<string[]>([]);

  const loadedRows = safetiesResponse?.safeties ?? [];

  const filteredRows = selectedStatus.length > 0
    ? loadedRows.filter(row => selectedStatus.includes((
      row as any
    ).safetyStatus))
    : loadedRows;

  const rows = useMemo(() => search(
      query,
      filteredRows
      .map(row => {
        const safetyGroupTypeLabel = getSafetyTypeLabel(row.safetyGroupType);

        return (
          {
            ...row,
            safetyGroupTypeLabel
          }
        );
      })
      , SEARCHER_OPTION_KEYS
    ), [ query, filteredRows, getSafetyTypeLabel ]
  );

  if (rootId === undefined) {
    return <Navigate to="/"/>;
  }

  if (isLoading) {
    return <Loading/>;
  }

  if (error) {
    return <ErrorPanel tips="reload+go-home"/>;
  }

  return (
    <PageRoot>
      <PageHeader>
        {/* TODO: update client name */}
        <RootIDHeader rootId={rootId} clientName="Dummy client "/>
        <RouteTabs/>
      </PageHeader>

      <PageMain className="vstack">
        <SafetyFilters
          query={query}
          onQueryChange={setQuery}
          status={selectedStatus}
          onStatusChange={selectStatus}
        />

        <div className="flex-fill">
          <div style={{ height: "100%", width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
            <AgGridReact
              rowData={rows}
              defaultColDef={DEFAULT_COLUMN_DEF}
              columnDefs={addHeaderIntlName("routes.safeties.table.column", getTableColumns(rootId), intl)}
              modules={[ ClientSideRowModelModule ]}
              enableCellTextSelection
            />
          </div>
        </div>
      </PageMain>
    </PageRoot>
  );
}


const getTableColumns = (rootId: string) => (intl: IntlShape): ColDef[] => [
  {
    field:    "safetyGroupType",
    minWidth: 210
  },
  {
    field:    "safetyGroupTypeLabel",
    minWidth: 300
  },
  {
    field:          "fixedAmount",
    type:           "rightAligned",
    minWidth:       120,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatNumber(params.value) : ""
  },
  {
    field:    "fixedAmountCurrency",
    minWidth: 120
  },
  {
    field:          "lastModificationDate",
    minWidth:       200,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatDate(new Date(params.value), {
      day:   "2-digit",
      month: "short",
      year:  "2-digit"
    }) : ""
  },
  {
    field:    "safetySequenceNo",
    minWidth: 100
  },
  {
    field:    "safetyStatus",
    minWidth: 150,
    // eslint-disable-next-line react/no-unstable-nested-components
    cellRenderer: (props: {
      value: "INACTIVE" | "ACTIVE"
    }) => <StatusBadge active={props.value === "ACTIVE"}/>
  },
  {
    field:        "actions",
    minWidth:     190,
    cellRenderer: (props: {
      data: {
        id: string,
        safetyStatus: "INACTIVE" | "ACTIVE"
      }
    }) => <ActionCell rootId={rootId} safetyId={props.data.id} active={props.data.safetyStatus === "ACTIVE"}/>
  }
];

const SEARCHER_OPTION_KEYS = [
  { name: "safetyGroupType", weight: 2 },
  { name: "safetyGroupTypeLabel", weight: 2 },
  { name: "fixedAmount", weight: 1 },
  { name: "fixedAmountCurrency", weight: 1 },
  { name: "lastModificationDate", weight: 1 },
  { name: "safetySequenceNo", weight: 1 },
  { name: "safetyStatus", weight: 1 }
];

export default SafetiesRoute;


