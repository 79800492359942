import { isDefined, isNotDefined } from "@sgme/fp";



export const parseNumber = (value: string | undefined) => {
  if (!isDefined(value) || value === "") {
    return undefined;
  }

  return Number(value);
};

export const numberToString = (value: number | undefined) => {
  return isNotDefined(value) ? "" : String(value); // TODO: format with the language of the user ?
};