import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useCancelAuthorization } from "@/store/api/webapi";
import ConfirmCancelAuthorizationModal from "@/routes/authorizations/components/ConfirmCancelAuthorizationModal";
import Button from "@/components/sg-ui/form/Button";
import { useAppDispatch } from "@/store";
import AuthorizationEditor from "@/components/AuthorizationEditor";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";
import { saveAuthorization } from "@/components/AuthorizationEditor/store/api";



export type ActionCellProps = {
  rootId: string
  authorizationId: string
  groupProductCode: string
  groupProductCodeLabel: string
  active: boolean
}


function ActionCell(props: ActionCellProps): React.JSX.Element {
  const { rootId, authorizationId, groupProductCode, groupProductCodeLabel, active: isActive } = props;

  const dispatch = useAppDispatch();

  const [ cancelAuthorization ] = useCancelAuthorization();

  const [ isConfirmationModalVisible, setConfirmationModalVisibility ] = useState(false);
  const [ isAuthorizationEditorVisible, setAuthorizationEditorVisibility ] = useState(false);
  const [ isEditable, setEditable ] = useState(true);

  const toggleConfirmationModalVisibility = () => setConfirmationModalVisibility(isVisible => !isVisible);
  const toggleAuthorizationEditorVisibility = () => setAuthorizationEditorVisibility(isVisible => !isVisible);

  const edit = () => {
    setEditable(true);
    setAuthorizationEditorVisibility(true);
  };

  const seeDetails = () => {
    setEditable(false);
    setAuthorizationEditorVisibility(true);
  };

  const cancel = () => {
    cancelAuthorization({ authorizationId });
    setConfirmationModalVisibility(false);
  };

  const onSaveAuthorization = async (authorizationChange: AuthorizationChange) => {
    await saveAuthorization(rootId, "", authorizationChange, dispatch, authorizationId);
  };

  return (
    <div className="d-flex justify-content-end align-items-center h-100">
      {
        isActive && (
          <>
            <Button size="small" variant="link" onClick={toggleConfirmationModalVisibility}>
              <i className="icon icon-sm">cancel</i>
            </Button>

            <ConfirmCancelAuthorizationModal
              authorizationId={authorizationId}
              isVisible={isConfirmationModalVisible}
              onToggleVisibility={toggleConfirmationModalVisibility}
              onConfirm={cancel}
            />

            <Button size="small" variant="link" onClick={edit}>
              <i className="icon icon-sm">edit</i>
            </Button>
          </>
        )
      }


      <Button size="small" variant="link" onClick={seeDetails}>
        <span className="me-2">
          <FormattedMessage id="routes.authorizations.actions.seeDetails"/>
        </span> <i className="icon icon-sm">arrow_forward</i>
      </Button>

      {
        isAuthorizationEditorVisible && (
          <AuthorizationEditor
            rootId={rootId}
            authorizationId={authorizationId}
            groupProductCode={groupProductCode}
            groupProductCodeLabel={groupProductCodeLabel}
            opened={isAuthorizationEditorVisible}
            editable={isEditable}
            toggle={toggleAuthorizationEditorVisibility}
            onEdit={isActive ? edit : undefined}
            onSaveOrCreate={onSaveAuthorization}
          />
        )
      }
    </div>
  );
}


export default ActionCell;


