import { Category } from "@/components/CategoryFilter";

export enum LinkType {
  CONTRACT_TO_SAFETY = "CTS",
  SAFETY_TO_ASSET = "STA",
  CONTRACT_TO_AUTHORIZATION = "CTA"
}

export const ALL_CATEGORIES: Category[] = [
  {
    labelId: "routes.links.filters.type.contract_to_safety",
    id: LinkType.CONTRACT_TO_SAFETY
  },
  {
    labelId: "routes.links.filters.type.safety_to_asset",
    id: LinkType.SAFETY_TO_ASSET
  },
  {
    labelId: "routes.links.filters.type.contract_to_authorization",
    id: LinkType.CONTRACT_TO_AUTHORIZATION
  }
]

export const DEFAULT_SELECTED_CATEGORIES = ALL_CATEGORIES.map(category => category.id)

export type Timestamp = ReturnType<typeof Date.now>
