import React, { ChangeEvent } from "react";
import { useIntl } from "react-intl";

import { Input, InputGroup } from "reactstrap";
import { ALL_STATUS_CATEGORIES } from "@/routes/shared";
import { CategorySelectionMode } from "@/components/CategoryFilter/types";
import CategoryFilter from "@/components/CategoryFilter";



export type SafetyFiltersProps = {
  query: string
  onQueryChange: (value: string) => void
  status: string[]
  onStatusChange: (value: string[]) => void
}


function SafetyFilters(props: SafetyFiltersProps): React.JSX.Element {
  const { query, onQueryChange, status, onStatusChange } = props;

  const intl = useIntl();

  const placeholder = intl.formatMessage({ id: "routes.safeties.filters.search.placeholder" });

  const updateQuery = (event: ChangeEvent<HTMLInputElement>) => {
    onQueryChange(event.target.value);
  };

  return (
    <div className="row g-3 mb-3">
      {/*search*/}
      <div className="col-md-2">
        <InputGroup>
          <div className="input-icon-start">
            <i className="icon">search</i>
          </div>

          <Input
            type="search"
            placeholder={placeholder}
            value={query}
            onChange={updateQuery}
          />
        </InputGroup>
      </div>

      <div className="col-md-8 d-flex align-items-center gap-3">
        <CategoryFilter
          labelId="routes.shared.status.label"
          categories={ALL_STATUS_CATEGORIES}
          selectedCategoryIds={status}
          mode={CategorySelectionMode.ONLY_ONE_ALLOWED}
          onChange={onStatusChange}
        />
      </div>


      <div className="col-md-2 d-flex justify-content-end">

      </div>
    </div>
  );
}


export default SafetyFilters;