import { ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";
import { DataFolder, DataNode } from "@/components/DataTreeSelector/types";
import FormRow from "@/components/sg-ui/form/FormRow";
import Header from "@/components/DataTreeSelector/Header";
import RootLevel from "@/components/DataTreeSelector/RootLevel";
import FolderLevel from "@/components/DataTreeSelector/FolderLevel";
import LevelContainer from "@/components/DataTreeSelector/LevelContainer";
import ScrollView from "@/components/sg-ui/layout/ScrollView";
import VStack from "@/components/sg-ui/layout/VStack";



export type DataTreeSelectorProps = {
  queryLabelId?: string
  queryPlaceholderId?: string

  query: string,
  onQueryChange: (value: string) => void

  nameLabelId: string
  idLabelId: string

  data: DataNode[]
  hasSectionLevel?: boolean

  selectedId: string | undefined
  toggleIdSelection: (id: string) => void

  className?: string
}


function DataTreeSelector(props: DataTreeSelectorProps): React.JSX.Element {
  const {
    queryLabelId,
    queryPlaceholderId,
    query,
    onQueryChange,
    data,
    hasSectionLevel = false,
    nameLabelId,
    idLabelId,
    selectedId,
    toggleIdSelection,
    className
  } = props;

  const allFolders = data.filter(node => "children" in node) as DataFolder[];

  const setQuery = (event: ChangeEvent<HTMLInputElement>) => {
    onQueryChange(event.target.value);
  };

  return (
    <VStack className={className}>
      {
        isDefined(queryLabelId) && (
          // <FormGroup labelId="Safety">
          <FormRow labelId={queryLabelId}>
            <div className="input-group">
              <div className="input-icon-start">
                <i className="icon">search</i>
              </div>

              <input type="text" className="form-control" placeholder="Search type" value={query} onChange={setQuery}/>
            </div>

            <div className="my-2 text-light">
              <FormattedMessage id="components.DataTreeSelector.query.hint"/>
            </div>
          </FormRow>
          // </FormGroup>
        )
      }

      <Header nameLabelId={nameLabelId} idLabelId={idLabelId}/>

      <ScrollView flex="expand" className="mb-2">
        {
          allFolders.map((node) => (
            hasSectionLevel
              ? <RootLevel key={node.id} folder={node} selectedId={selectedId} toggleIdSelection={toggleIdSelection}/>
              : <LevelContainer key={node.id}>
                <FolderLevel folder={node} selectedId={selectedId} toggleIdSelection={toggleIdSelection}/>
              </LevelContainer>
          ))
        }
      </ScrollView>
    </VStack>
  );
}


export default DataTreeSelector;