import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import clsx from "clsx";
import { SpaceSize } from "@/components/sg-ui/layout/types";



/**
 * Example of use:
 *
 * <VStack horizontalAlignment="center" verticalAlignment="center" fullWidth fullHeight margin="2" padding="2" gap="1">
 *
 * </VStack>
 */

export interface VStackProps {
  horizontalAlignment?: "start" | "center" | "full" | "end";
  verticalAlignment?: "start" | "center" | "full" | "end";
  fullWidth?: boolean;
  fullHeight?: boolean;
  flex?: "min" | "expand";
  gap?: SpaceSize;
  margin?: SpaceSize;
  padding?: SpaceSize;
  className?: string;
}


function VStack(props: PropsWithChildren<VStackProps>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    horizontalAlignment = "full",
    verticalAlignment = "full",
    fullWidth = false,
    fullHeight = false,
    flex = "min",
    gap = "0",
    margin = "0",
    padding = "0",
    className,
    children
  } = props;

  return (
    <div ref={ref} className={clsx([ "d-flex flex-column flex-nowrap overflow-hidden", className ], {
      "align-items-start":         horizontalAlignment === "start",
      "align-items-center":        horizontalAlignment === "center",
      "align-items-between":       horizontalAlignment === "full",
      "align-items-end":           horizontalAlignment === "end",
      "justify-content-start":     verticalAlignment === "start",
      "justify-content-center":    verticalAlignment === "center",
      "justify-content-stretch":   verticalAlignment === "full",
      "justify-content-end":       verticalAlignment === "end",
      "flex-grow-0 flex-shrink-0": flex === "min",
      "flex-fill":                 flex === "expand",
      "w-100":                     fullWidth,
      "h-100":                     fullHeight,
      "m-0":                       margin === "0",
      "m-1":                       margin === "1",
      "m-2":                       margin === "2",
      "m-3":                       margin === "3",
      "m-4":                       margin === "4",
      "m-5":                       margin === "5",
      "p-0":                       padding === "0",
      "p-1":                       padding === "1",
      "p-2":                       padding === "2",
      "p-3":                       padding === "3",
      "p-4":                       padding === "4",
      "p-5":                       padding === "5",
      "gap-0":                     gap === "0",
      "gap-1":                     gap === "1",
      "gap-2":                     gap === "2",
      "gap-3":                     gap === "3",
      "gap-4":                     gap === "4",
      "gap-5":                     gap === "5"
    })}>
      {children}
    </div>
  );
}


export default forwardRef(VStack);