import { FormattedMessage } from "react-intl";
import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "@/store";
import { newLinkSlice } from "@/routes/links/new-link/store/slice";
import { NewLinkState } from "@/routes/links/new-link/store/state";



export type NewComponentProps = {}


function LinkCreationResult(props: NewComponentProps): React.JSX.Element {
  const { rootId } = useSelector((appState: AppState) => {
    const state = appState.ui[newLinkSlice.name] as NewLinkState;

    return {
      rootId: state.rootId as string
    };
  });

  // TODO: manage error and retry ?
  return (
    <StyledContainer className="card card-bordered p-4">
      <aside className="d-flex justify-content-center">
        <span>
          <em className="icon">check</em>
        </span>
      </aside>

      <h3 className="d-flex justify-content-center my-5">
        <FormattedMessage id="routes.new-link.create.successfulTitle"/>
      </h3>

      <div className="d-flex justify-content-center">
        <Link to={`/links/${rootId}`} className="btn btn-discreet-success btn-icon-start ">
          <em className="icon">arrow_back</em>
          <FormattedMessage id="routes.new-link.create.back"/>
        </Link>
      </div>
    </StyledContainer>
  );
}


const StyledContainer = styled.div`
  color: #18A820;

  & > aside > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #18A82038;
  }
`;

export default LinkCreationResult;