import StatusBadge from "@/components/StatusBadge";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";



export type MainBadgeProps = {
  titleLocaleId: string,
  value: string,
  color?: "primary" | "secondary" | "success" | "warning" | "danger"
}


function MainBadge(props: MainBadgeProps): React.JSX.Element {
  const { titleLocaleId, value, color = "secondary" } = props;

  return (
    <StyledContainer>
      <h6 className="text-secondary mb-2">
        <FormattedMessage id={titleLocaleId}/>
      </h6>

      <aside>
        <StatusBadge active/>
      </aside>
    </StyledContainer>
  );
}


const StyledContainer = styled.div`
  & > aside {
    display: flex;
    height: 29px;

    & > span {
      margin: auto 0;
    }
  }
`;

export default MainBadge;