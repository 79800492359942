// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

import { isDefined } from "@sgme/fp";
import { PayloadAction } from "@reduxjs/toolkit";

export type SelectableItem<Type> = {
  status: SelectableItemStatus
  all: Type[]
}

export type SelectableSingleItem<Type> = SelectableItem<Type> & {
  selected?: Type
}

export type SelectableManyItems<Type> = SelectableItem<Type> & {
  selected: Type[]
}

export type SelectableItemStatus
  = { type: SelectableItemStatusType.NOT_LOADED }
  | { type: SelectableItemStatusType.PENDING, startedAt: Timestamp }
  | { type: SelectableItemStatusType.LOADED, loadedAt: Timestamp }
  | {
  type: SelectableItemStatusType.ERROR,
  failedAt: Timestamp,
  code: SelectableItemError,
  message: string,
  stack: string[]
}

export enum SelectableItemStatusType {
  NOT_LOADED,
  PENDING,
  LOADED,
  ERROR
}

export type Timestamp = ReturnType<typeof Date.now>

export enum SelectableItemError {
  CAN_NOT_BE_LOAD
}


//  █████╗ ██████╗ ██╗
// ██╔══██╗██╔══██╗██║
// ███████║██████╔╝██║
// ██╔══██║██╔═══╝ ██║
// ██║  ██║██║     ██║
// ╚═╝  ╚═╝╚═╝     ╚═╝

export const initSingleSelectable = <Type>(): SelectableSingleItem<Type> => ({
  status: {
    type: SelectableItemStatusType.NOT_LOADED
  },
  all: [],
  selected: undefined
});

export const initManySelectable = <Type>(): SelectableManyItems<Type> => ({
  status: {
    type: SelectableItemStatusType.NOT_LOADED
  },
  all: [],
  selected: []
});

export const startLoading = <Type, Selectable extends SelectableSingleItem<Type> | SelectableManyItems<Type>>(startedAt: Timestamp, selectable: Selectable): Selectable => {
  return {
    ...selectable,

    status: {
      type: SelectableItemStatusType.PENDING,
      startedAt
    }
  };
};

export const loadItems = <Type, Selectable extends SelectableSingleItem<Type> | SelectableManyItems<Type>>(values: Type[], loadedAt: Timestamp, selectable: Selectable): Selectable => {
  return {
    ...selectable,

    all: values,

    status: {
      type: SelectableItemStatusType.LOADED,
      loadedAt
    }
  };
};

export const stopLoadingWithError = <Type, Selectable extends SelectableSingleItem<Type> | SelectableManyItems<Type>>(failedAt: Timestamp, code: SelectableItemError, message: string, stack: string[], selectable: Selectable): Selectable => {
  return {
    ...selectable,

    all: [],

    status: {
      type: SelectableItemStatusType.ERROR,
      failedAt,
      code,
      message,
      stack
    }
  };
};

export const selectSingleItem = <Type>(item: Type | undefined, selectable: SelectableSingleItem<Type>): SelectableSingleItem<Type> => {
  if (selectable.status.type !== SelectableItemStatusType.LOADED) {
    return selectable;
  }

  return {
    ...selectable,

    selected: item
  };
};

export const selectManyItems = <Type>(allItems: Type[], selectable: SelectableManyItems<Type>): SelectableManyItems<Type> => {
  if (selectable.status.type !== SelectableItemStatusType.LOADED) {
    return selectable;
  }

  return {
    ...selectable,

    selected: allItems
  };
};

// ██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗███████╗██████╗
// ██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗
// ██████╔╝█████╗  ██║  ██║██║   ██║██║     █████╗  ██████╔╝
// ██╔══██╗██╔══╝  ██║  ██║██║   ██║██║     ██╔══╝  ██╔══██╗
// ██║  ██║███████╗██████╔╝╚██████╔╝╚██████╗███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝╚══════╝╚═╝  ╚═╝

export const createSingleSelectableApiReducer = <PropertyName extends string, State extends Record<PropertyName, SelectableSingleItem<Item>>, Item>(selectableName: PropertyName, predicate?: (state: State) => boolean) => {

  return {
    [`request${selectableName}` as `request${PropertyName}`]: (state: State) => {
      if (isDefined(predicate) && !predicate(state)) {
        return state;
      }

      return {
        ...state,
        [selectableName]: startLoading(Date.now(), state[selectableName])
      }
    },

    [`load${selectableName}` as `load${PropertyName}`]: (state: State, action: PayloadAction<Item[]>) => {
      if (isDefined(predicate) && !predicate(state)) {
        return state;
      }

      return {
        ...state,
        [selectableName]: loadItems(action.payload, Date.now(), state[selectableName])
      }
    },

    [`cancelRequest${selectableName}` as `cancelRequest${PropertyName}`]: (state: State, action: PayloadAction<{
      code: SelectableItemError,
      message: string,
      stack: string[]
    }>) => {
      if (isDefined(predicate) && !predicate(state)) {
        return state;
      }

      return {
        ...state,
        [selectableName]: stopLoadingWithError(Date.now(), action.payload.code, action.payload.message, action.payload.stack, state[selectableName])
      }
    },

    [`select${selectableName}` as `select${PropertyName}`]: (state: State, action: PayloadAction<Item>) => {
      if (isDefined(predicate) && !predicate(state)) {
        return state;
      }

      return {
        ...state,
        [selectableName]: selectSingleItem(action.payload, state[selectableName])
      }
    }
  } as SingleSelectableApiReducer<PropertyName, State, Item>
}

export type SingleSelectableApiReducer<PropertyName extends string, State extends Record<PropertyName, SelectableSingleItem<Item>>, Item> = (
  SelectableReducerWithoutPayload<`request${Capitalize<PropertyName>}`, PropertyName, State, Item>
  & SelectableReducerWithPayload<`load${Capitalize<PropertyName>}`, PropertyName, State, Item, Item[]>
  & SelectableReducerWithPayload<`cancelRequest${Capitalize<PropertyName>}`, PropertyName, State, Item, {
  code: SelectableItemError,
  message: string,
  stack: string[]
}>
  & SelectableReducerWithPayload<`select${Capitalize<PropertyName>}`, PropertyName, State, Item, Item>
  )

export type SelectableReducerWithoutPayload<MethodName extends string, PropertyName extends string, State extends Record<PropertyName, SelectableSingleItem<Item>>, Item> = {
  [K in MethodName]: (state: State) => State
}

export type SelectableReducerWithPayload<MethodName extends string, PropertyName extends string, State extends Record<PropertyName, SelectableSingleItem<Item>>, Item, Payload> = {
  [K in MethodName]: (state: State, action: PayloadAction<Payload>) => State
}
