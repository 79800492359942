import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { LEVEL_CONTAINER_PADDING } from "@/components/DataTreeSelector/types";



function LevelContainer(props: PropsWithChildren): React.JSX.Element {
  const { children } = props;

  return (
    <Styles className="border my-2">
      {children}
    </Styles>
  );
}


export default LevelContainer;

const Styles = styled.div`
  padding: ${LEVEL_CONTAINER_PADDING}px;
`;