import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store";
import { authorizationEditorSlice, updateFormField } from "@/components/AuthorizationEditor/store/slice";
import { AuthorizationEditorState, AuthorizationFormData } from "@/components/AuthorizationEditor/store/state";



export const useAuthorizationEditorForm = () => {
  const dispatch = useDispatch() as AppDispatch;

  const { form, formErrors, isEditable } = useSelector((appState: AppState) => {
    const state = appState.ui[authorizationEditorSlice.name] as AuthorizationEditorState;

    return {
      form:       state.form,
      formErrors: state.formErrors,
      isEditable: state.isEditable
    };
  });

  const registerFormField = (name: keyof AuthorizationFormData) => {
    const onChange = (event: FormEvent<HTMLInputElement>) => {
      dispatch(updateFormField({ name, value: event.currentTarget.value }));
    };

    return {
      name,
      value:        form[name],
      errorLabelId: formErrors[name],
      onChange,
      onBlur:       onChange
    };
  };

  const registerTextArea = (name: keyof AuthorizationFormData) => {
    const onChange = (event: FormEvent<HTMLTextAreaElement>) => {
      dispatch(updateFormField({ name, value: event.currentTarget.value }));
    };

    return {
      name,
      value:  form[name],
      onChange,
      onBlur: onChange
    };
  };

  return [ form, registerFormField, registerTextArea, isEditable ] as const;
};