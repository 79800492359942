import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isDefined, isNotDefined } from "@sgme/fp";
import { useGetSafetyElementaryTypes } from "@/store/api/webapi";
import { groupById } from "@/utils/fp";



export const useSafetyElementaryByCode = () => {
  const { data: safetyElementaryTypeResponse } = useGetSafetyElementaryTypes();

  const intl = useIntl();

  const safetyElementaryTypeByCode = useMemo(() => {
    const allTypes = safetyElementaryTypeResponse?.types ?? [];

    return groupById(allTypes, type => type.safetyElementaryTypeLabelCode!);
  }, [ safetyElementaryTypeResponse ]);

  const getSafetyTypeLabel = (code: string | undefined) => {
    if (!isDefined(code)) {
      return undefined;
    }

    const elementaryType = safetyElementaryTypeByCode[code];

    if (isNotDefined(elementaryType)) {
      return "";
    }

    return intl.locale === "fr"
      ? elementaryType.safetyElementaryTypeLabelFr
      : elementaryType.safetyElementaryTypeLabelEn;
  };

  return [ getSafetyTypeLabel, safetyElementaryTypeByCode ] as const;
};