import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { ID_COLUMN_WIDTH, LEVEL_CONTAINER_PADDING } from "@/components/DataTreeSelector/types";
import { SCROLLBAR_WITH } from "@/components/sg-ui/layout/ScrollView";



export type HeaderProps = {
  nameLabelId: string
  idLabelId: string
}


function Header(props: HeaderProps): React.JSX.Element {
  const { nameLabelId, idLabelId } = props;

  return (
    <Styles>
      <div>
        <FormattedMessage id={nameLabelId}/>
      </div>

      <aside>
        <FormattedMessage id={idLabelId}/>
      </aside>
    </Styles>
  );
}


export default Header;

const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ID_COLUMN_WIDTH + LEVEL_CONTAINER_PADDING + SCROLLBAR_WITH}px;
  margin: 16px 0;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--interface-secondary, #6E777A);
`;