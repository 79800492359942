import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";



export interface FormGroupProps {
  labelId: string;
}


function FormGroup({ labelId, children }: PropsWithChildren<FormGroupProps>, ref?: ForwardedRef<HTMLDivElement>) {
  return (
    <div className="my-4" ref={ref}>
      <h6 className="mb-3">
        <FormattedMessage id={labelId}/>
      </h6>

      {children}
    </div>
  );
}


export default forwardRef(FormGroup);