// ███████╗████████╗ █████╗ ████████╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
// ███████╗   ██║   ███████║   ██║   █████╗  
// ╚════██║   ██║   ██╔══██║   ██║   ██╔══╝  
// ███████║   ██║   ██║  ██║   ██║   ███████╗
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝

export type ManySelection<Item> = Item[]

export const initManySelection = <Item>(initialSelectedItems: Item[] = []): ManySelection<Item> => initialSelectedItems;


// ██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗███████╗██████╗ ███████╗
// ██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
// ██████╔╝█████╗  ██║  ██║██║   ██║██║     █████╗  ██████╔╝███████╗
// ██╔══██╗██╔══╝  ██║  ██║██║   ██║██║     ██╔══╝  ██╔══██╗╚════██║
// ██║  ██║███████╗██████╔╝╚██████╔╝╚██████╗███████╗██║  ██║███████║
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝


export const selectItemInManySelection = <Item>(state: ManySelection<Item>, item: Item): ManySelection<Item> => {
  if (isSelectedInManySelectionSelection(state, item)) {
    return state;
  }

  return [ ...state, item ];
};

export const selectOnlyItemInManySelection = <Item>(state: ManySelection<Item>, item: Item): ManySelection<Item> => {
  return [ item ];
};

export const unselectItemInManySelection = <Item>(state: ManySelection<Item>, item: Item): ManySelection<Item> => {
  if (!isSelectedInManySelectionSelection(state, item)) {
    return state;
  }

  return state.filter(selection => selection !== item);
};

export const toggleItemInManySelection = <Item>(state: ManySelection<Item>, item: Item): ManySelection<Item> => {
  return isSelectedInManySelectionSelection(state, item)
    ? state.filter(selection => selection !== item)
    : [ ...state, item ];
};

export const cleanManySelection = <Item>(state: ManySelection<Item>): ManySelection<Item> => {
  return initManySelection();
};

// ███████╗███████╗██╗     ███████╗ ██████╗████████╗ ██████╗ ██████╗ ███████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
// ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║   ██║██████╔╝███████╗
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║   ██║██╔══██╗╚════██║
// ███████║███████╗███████╗███████╗╚██████╗   ██║   ╚██████╔╝██║  ██║███████║
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝

export const isSelectedInManySelectionSelection = <Item>(state: ManySelection<Item>, item: Item) => {
  return state.includes(item);
};