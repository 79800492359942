import FormGroup from "@/components/sg-ui/form/FormGroup";
import FormRow from "@/components/sg-ui/form/FormRow";
import CountryPicker from "@/components/sg-ui/form/pickers/CountryPicker";
import { useSafetyEditorForm } from "@/components/SafetyEditor/store/hooks";



function PropertyFormGroup() {
  const [ _, registerFormField, isEditable ] = useSafetyEditorForm();


  return (
    <FormGroup labelId="components.SafetyEditor.propertyForm.title">
      <FormRow
        labelId="components.SafetyEditor.propertyForm.applicableLawCountryCode.label">
        <CountryPicker
          {...registerFormField("applicableLawCountryCode")}
          editable={isEditable}
          placeholderLabelId="components.SafetyEditor.propertyForm.applicableLawCountryCode.placeholder"
        />
      </FormRow>
    </FormGroup>
  );
}


export default PropertyFormGroup;