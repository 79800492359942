import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Navigate, useParams } from "react-router-dom";
import { isDefined } from "@sgme/fp";

import RootIDHeader from "@/components/RootIDHeader";
import { DEFAULT_SELECTED_CATEGORIES } from "@/routes/links/shared";
import { useGetLinks } from "@/store/api/webapi";
import { search } from "@/utils/searcher";
import LinkFilters from "./components/LinkFilters";
import { PageHeader, PageMain, PageRoot } from "@/routes/_layout/page-styles";
import RouteTabs from "@/components/RouteTabs";
import { addHeaderIntlName, DEFAULT_COLUMN_DEF } from "@/utils/ag-grid";
import StatusBadge from "@/components/StatusBadge";
import ActionCell from "@/routes/links/components/ActionCell";
import Loading from "@/components/sg-ui/Loading";
import ErrorPanel from "@/components/sg-ui/ErrorPanel";



function LinksRoute(): React.JSX.Element {
  const { rootId } = useParams() as {
    rootId: string
  };

  const intl = useIntl();

  const { data, error, isLoading } = useGetLinks(
    { rootId, size: 200 },
    {
      // pollingInterval:           2 * 60 * 1000, // every 2 minutes
      refetchOnMountOrArgChange: true,
      skip:                      false
    }
  );
  const [ query, setQuery ] = useState("");
  const [ selectedLinkTypes, selectLinkTypes ] = useState(DEFAULT_SELECTED_CATEGORIES);

  const [ selectedStatus, selectStatus ] = useState<string[]>([]);

  const loadedRows = data?.linkObjects ?? [];
  const selectedLinkStatus = selectedStatus.map(status => LINK_STATUS_BY_STATUS[status]);

  const filtedRows = loadedRows.filter(row => (
    isDefined(row.linkType) && selectedLinkTypes.includes(row.linkType)
    && (
      selectedLinkStatus.length === 0 || (
        isDefined(row.status) && selectedLinkStatus.includes(row.status)
      )
    )
  ));

  const rows = search(
    query,
    filtedRows
    .map(row => {
      const linkType = isDefined(row.linkType) ? intl.formatMessage({ id: `link.types.${row.linkType}` }) : "";

      return {
        ...row,
        linkType
      };
    }),
    SEARCHER_OPTION_KEYS
  );

  if (rootId === undefined) {
    return <Navigate to="/"/>;
  }

  if (isLoading) {
    return <Loading/>;
  }

  if (error) {
    return <ErrorPanel tips="reload+go-home"/>;
  }

  return (
    <PageRoot>
      <PageHeader>
        <RootIDHeader rootId={rootId} clientName="Dummy client"/>
        <RouteTabs/>
      </PageHeader>

      <PageMain className="vstack">
        <LinkFilters
          rootId={rootId}
          query={query}
          onQueryChange={setQuery}
          selectedLinkTypes={selectedLinkTypes}
          onSelectLinkTypes={selectLinkTypes}
          status={selectedStatus}
          onStatusChange={selectStatus}
        />

        <div className="flex-fill">
          <div style={{ height: "100%", width: "100%" }} className="ag-theme-sg-bootstrap-condensed">
            <AgGridReact
              rowData={rows}
              defaultColDef={DEFAULT_COLUMN_DEF}
              columnDefs={addHeaderIntlName("routes.links.table.column", getTableColumns, intl)}
              modules={[ ClientSideRowModelModule ]}
              enableCellTextSelection
            />
          </div>
        </div>
      </PageMain>
    </PageRoot>
  );
}


const getTableColumns = (intl: IntlShape): ColDef[] => [
  {
    field:    "productId",
    minWidth: 110,
    sort:     "asc"
  },
  {
    field:    "linkId",
    minWidth: 280
  },
  {
    field:    "linkType",
    minWidth: 190
  },
  {
    field:    "status",
    minWidth: 120,
    // eslint-disable-next-line react/no-unstable-nested-components
    cellRenderer: (props: {
      value: "INACTIVE" | "ACTIVE"
    }) => <StatusBadge active={props.value === "ACTIVE"}/>
  },
  {
    field:    "createdBy",
    minWidth: 190
  },
  {
    field:          "creationDate",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatDate(new Date(params.value), {
      day:   "2-digit",
      month: "short",
      year:  "2-digit"
    }) : ""
  },
  {
    field:    "cancelledBy",
    minWidth: 190
  },
  {
    field:          "cancellationDate",
    minWidth:       150,
    valueFormatter: (params: ValueFormatterParams) => isDefined(params.value) ? intl.formatDate(new Date(params.value), {
      day:   "2-digit",
      month: "short",
      year:  "2-digit"
    }) : ""
  },
  {
    field:    "safetyId",
    minWidth: 170
  },
  {
    field:    "authorizationId",
    minWidth: 170
  },
  {
    field:    "assetId",
    minWidth: 170
  },
  {
    field:        "actions",
    minWidth:     110,
    cellRenderer: (props: {
      data: {
        linkId: string,
        status: "INACTIVE" | "ACTIVE"
      }
    }) => props.data.status === "ACTIVE" ?
      <ActionCell linkId={props.data.linkId}/> : undefined
  }
];

const SEARCHER_OPTION_KEYS = [
  { name: "productId", weight: 2 },
  { name: "linkId", weight: 2 },
  { name: "linkType", weight: 2 },
  { name: "status", weight: 1 },
  { name: "createdBy", weight: 1 },
  { name: "creationDate", weight: 1 },
  { name: "cancelledBy", weight: 1 },
  { name: "cancellationDate", weight: 1 },
  { name: "safetyId", weight: 2 },
  { name: "authorizationId", weight: 2 },
  { name: "assetId", weight: 2 }
];

const LINK_STATUS_BY_STATUS: Record<string, string> = {
  ACTIVE:   "ACTIVE",
  INACTIVE: "CANCELED"
};

export default LinksRoute;


