import styled from "@emotion/styled";
import { ForwardedRef, useImperativeHandle, useRef, useState } from "react";
import { isDefined } from "@sgme/fp";



export const HiddenInput = styled.input`
  display: none;
`;

/**
 * When an input is updated programmatically, use this when onChange call is needed.
 *
 * const [ value, setValue ] = useState("")
 *
 * <input value={value} onChange={onChangeFromProps} />
 *
 * ...
 *
 * setValue("new value")
 * // here, onChangeFromProps isn't called
 * // and if the event is needed (ChangeEvent<HTMLInputElement>), we can't just call onChangeFromProps with the new value
 *
 * To fix the onChange:
 *
 * const [ innerRef, value, setValue ] = useUpdateValue<Value | "">(externalRef, defaultValue ?? "");
 * // externalRef provided from forwardRef(MyComponent)
 *
 * now, when setValue is used, onChange is called on the input
 *
 * @param externalRef
 * @param defaultValue
 */
export const useUpdateValue = <Value extends string | undefined | "">(externalRef: ForwardedRef<HTMLInputElement>, defaultValue: Value | undefined) => {
  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(externalRef, () => (
    innerRef.current as HTMLInputElement
  ));

  const [ value, setValue ] = useState<Value | undefined>(defaultValue);

  const updateValue = (newValue: Value | undefined) => {
    if (!isDefined(innerRef.current)) {
      return;
    }

    // why so complex ?
    // the response is here
    // https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
    // https://www.appsloveworld.com/reactjs/100/1/what-is-the-best-way-to-trigger-onchange-event-in-react-js
    const nativeInputValueSetter = isDefined(window.HTMLInputElement.prototype) ? Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set : undefined;

    nativeInputValueSetter?.call(innerRef.current, newValue);

    const changeEvent = new Event("change", { bubbles: true });

    innerRef.current.dispatchEvent(changeEvent);

    setValue(newValue);
  };

  return [ innerRef, value, updateValue ] as const;
};