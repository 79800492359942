import { isDefined } from "@sgme/fp";
import { AppListenerEffectAPI, AppStartListening } from "@/store";
import {
  loadAuthorizations,
  loadProducts,
  loadSafeties,
  newLinkSlice,
  selectAuthorizationId,
  selectProductId,
  setRootId,
  startAuthorizationLoading,
  startProductLoading,
  startSafetyLoading,
  stopAuthorizationLoadingWithError,
  stopProductLoadingWithError,
  stopSafetyLoadingWithError
} from "@/routes/links/new-link/store/slice";
import { webapi } from "@/store/api/webapi";
import { SelectableItemError } from "@/utils/selectable";
import { NewLinkState } from "@/routes/links/new-link/store/state";



export const setupNewLinkListeners = (startListening: AppStartListening) => [
  startListening({
    actionCreator: setRootId,
    effect:        afterSetRootId
  }),

  startListening({
    actionCreator: selectProductId,
    effect:        afterSelectProduct
  }),

  startListening({
    actionCreator: selectAuthorizationId,
    effect:        afterSelectAuthorization
  })
];

// ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
// ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
// █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
// ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
// ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
// ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

const afterSetRootId = async (action: ReturnType<typeof setRootId>, api: AppListenerEffectAPI) => {
  const state = api.getState().ui[newLinkSlice.name] as NewLinkState;

  if (!isDefined(state.rootId)) {
    return;
  }

  api.dispatch(startProductLoading);

  api.dispatch(webapi.endpoints.getProducts.initiate({
    rootId:   state.rootId,
    scope:    "COMMITMENT",
    unlinked: true
  }, {
    forceRefetch: true
  })).unwrap()
  .then(response => {
    api.dispatch(loadProducts(response.trades!));
  })
  .catch(error => {
    api.dispatch(stopProductLoadingWithError({ code: SelectableItemError.CAN_NOT_BE_LOAD, message: error.message ?? "", stack: error.stack ?? [] }));
  });
};

const afterSelectProduct = async (action: ReturnType<typeof selectProductId>, api: AppListenerEffectAPI) => {
  const state = api.getState().ui[newLinkSlice.name] as NewLinkState;

  if (!isDefined(state.rootId) || !isDefined(state.product.selected)) {
    return;
  }

  api.dispatch(startAuthorizationLoading);

  api.dispatch(webapi.endpoints.getEligibleAuthorizations.initiate({
    rootId:      state.rootId,
    devlCode:    state.product.selected.type,
    productType: productTypeLabelToProductType(state.product.selected.typeLabel),
    activeOnly:  true,
    page:        0,
    size:        500
  })).unwrap()
  .then(response => {
    api.dispatch(loadAuthorizations(response.authorizations!));
  })
  .catch(error => {
    api.dispatch(stopAuthorizationLoadingWithError({ code: SelectableItemError.CAN_NOT_BE_LOAD, message: error.message ?? "", stack: error.stack ?? [] }));
  });
};


const afterSelectAuthorization = async (action: ReturnType<typeof selectAuthorizationId>, api: AppListenerEffectAPI) => {
  const state = api.getState().ui[newLinkSlice.name] as NewLinkState;

  if (!isDefined(state.rootId) || !isDefined(state.product.selected) || !isDefined(state.authorization.selected)) {
    return;
  }

  api.dispatch(startSafetyLoading);

  api.dispatch(webapi.endpoints.getUnlinkedSafeties.initiate({
    rootId:     state.rootId,
    activeOnly: "true",
    page:       0,
    size:       500
  })).unwrap()
  .then(response => {
    api.dispatch(loadSafeties(response.safeties!));
  })
  .catch(error => {
    api.dispatch(stopSafetyLoadingWithError({ code: SelectableItemError.CAN_NOT_BE_LOAD, message: error.message ?? "", stack: error.stack ?? [] }));
  });
};


export const productTypeLabelToProductType = (value: string | undefined): "WARRANTY" | "LOAN" | "CASH_ACCOUNT" | "CREDIT_CARD" | "OVERDRAFT" | "DEPOSIT" | "PROPERTY" => {
  switch (value) {
    case "Warranty":
      return "WARRANTY";
    case "Loan":
      return "LOAN";
    case "Cash account":
      return "CASH_ACCOUNT";
    case "CreditCard":
      return "CREDIT_CARD";
    case "Overdraft":
      return "OVERDRAFT";
    case "Deposit":
      return "DEPOSIT";
    case "Property":
      return "PROPERTY";
  }

  throw new Error(`unknown product type label: ${value}`);
};