import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";



export type RouteTabsProps = {}


function RouteTabs(props: RouteTabsProps): React.JSX.Element {
  const { rootId } = useParams();

  return (
    <Nav pills>
      <NavItem>
        <NavLink to={`/authorizations/${rootId}`} className="nav-link">
          <FormattedMessage id="components.RouteTabs.authorizationManagement"/>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink to={`/safeties/${rootId}`} className="nav-link">
          <FormattedMessage id="components.RouteTabs.safetyManagement"/>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink to={`/links/${rootId}`} className="nav-link">
          <FormattedMessage id="components.RouteTabs.linkManagement"/>
        </NavLink>
      </NavItem>
    </Nav>
  );
}


export default RouteTabs;