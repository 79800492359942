import { FormattedMessage } from "react-intl";
import React from "react";
import CategoryButton from "@/components/CategoryFilter/CategoryButton";
import { CategorySelectionMode } from "@/components/CategoryFilter/types";



export type CategoryFilterProps = {
  labelId?: string
  categories: Category[]
  selectedCategoryIds: string[]
  // default STANDARD
  mode?: CategorySelectionMode
  onChange: (selectedCategoryIds: string[]) => void
}

export type Category = {
  labelId: string
  id: string
}


function CategoryFilter(props: CategoryFilterProps): React.JSX.Element {
  const {
    labelId = "components.CategoryFilter.label",
    categories,
    selectedCategoryIds,
    mode = CategorySelectionMode.STANDARD,
    onChange
  } = props;

  return (
    <div className="d-flex gap-1 flex-center">
      <label className="me-2">
        <FormattedMessage id={labelId}/>
      </label>

      {
        categories.map(category => (
          <CategoryButton
            key={category.id}
            labelId={category.labelId}
            categoryId={category.id}
            selectedCategoryIds={selectedCategoryIds}
            mode={mode}
            onChange={onChange}
          />
        ))
      }
    </div>
  );
}


export default CategoryFilter;