import { initManySelectable, initSingleSelectable, SelectableManyItems, SelectableSingleItem } from "@/utils/selectable";
import { AuthorizationResponse, SafetyResponse, TradeResponse } from "@credit-control-risk/common";
import { SafetyChange } from "@/components/SafetyEditor/store/types";
import { AuthorizationChange } from "@/components/AuthorizationEditor/store/types";



export type NewLinkState = {
  rootId?: string

  product: SelectableSingleItem<TradeResponse>

  authorization: SelectableSingleItem<AuthorizationResponse>

  newAuthorization?: {
    change: AuthorizationChange
    response: AuthorizationResponse
  }

  safeties: SelectableManyItems<SafetyResponse>

  canHaveSafeties: boolean
  isSafetySelectionStopped: boolean

  newSafeties: {
    change: SafetyChange
    response: SafetyResponse
  }[]

  currentStep: Step
  lastActiveStep: Step

  openedPanels: Panel[]
}

export enum RequestStatus {
  IDLE,
  PENDING,
  ERROR
}

export enum Step {
  PRODUCT_SELECTION,
  AUTHORIZATION_SELECTION,
  SAFETY_SELECTION,
  LINK_CREATION
}

export enum Panel {
  PRODUCT,
  AUTHORIZATION,
  SAFETY
}

export const initNewLinkState = (): NewLinkState => (
  {
    rootId: undefined,

    product:       initSingleSelectable(),
    authorization: initSingleSelectable(),
    safeties:      initManySelectable(),

    currentStep:    Step.PRODUCT_SELECTION,
    lastActiveStep: Step.PRODUCT_SELECTION,

    openedPanels: [ Panel.PRODUCT ],

    canHaveSafeties:          true,
    isSafetySelectionStopped: false,

    newSafeties: []
  }
);