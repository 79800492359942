import Fuse from "fuse.js";



export const search = <Item>(query: string, rows: Item[], optionKeys: Fuse.FuseOptionKey<Item>[]) => {
  if (query === "") {
    return rows;
  }

  const fuse = new Fuse(
    rows,
    {
      keys:      optionKeys,
      threshold: 0.3
    }
  );

  return (
    fuse
    .search(query)
    .map(({ item }) => item)
  );
};