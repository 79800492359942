import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";
import classnames from "classnames";



export type StatusBadgeProps = {
  active?: boolean
  labelId?: string
}


function StatusBadge(props: StatusBadgeProps): React.JSX.Element {
  const { active = false, labelId } = props;


  const realLabelId = (
    isDefined(labelId)
      ? labelId :
      active
        ? "components.StatusBadge.active"
        : "components.StatusBadge.inactive"
  );

  return (
    <StyledContainer className={classnames("bg-opacity-10", {
      "text-success": active,
      "bg-success":   active,

      "text-primary": !active,
      "bg-primary":   !active
    })}>
      <FormattedMessage id={realLabelId}/>
    </StyledContainer>
  );
}


const StyledContainer = styled.span`
  height: 24px;
  border-radius: 12px;

  padding: 2px 6.5px;
`;

export default StatusBadge;