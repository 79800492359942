import { createBrowserRouter } from "react-router-dom";
import AppLayout from "@/routes/_layout";
import AuthorizationsRoute from "@/routes/authorizations";
import HomeRoute from "@/routes/home";
import LinksRoute from "@/routes/links";
import NewLinkRoute from "@/routes/links/new-link";
import SafetiesRoute from "@/routes/safeties";
import TestRoute from "@/routes/test";
import NoMatch from "@/components/sg-ui/NoMatch";
import ErrorBoundary from "@/routes/ErrorBoundary";



export const createAllRouter = () => createBrowserRouter([
  {
    path:         "/",
    element:      <AppLayout/>,
    errorElement: <ErrorBoundary/>,
    children:     [
      {
        path:    "/",
        element: <HomeRoute/>
      },
      {
        path:    "/test",
        element: <TestRoute/>
      },
      {
        path:    "/links/:rootId/new",
        element: <NewLinkRoute/>
      },
      {
        path:    "/links/:rootId",
        element: <LinksRoute/>
      },
      {
        path:    "/authorizations/:rootId",
        element: <AuthorizationsRoute/>
      },
      {
        path:    "/safeties/:rootId",
        element: <SafetiesRoute/>
      },
      {
        path:    "*",
        element: <NoMatch/>
      }
    ]
  }
]);