import { isMatch } from "date-fns";
import { isDefinedAndNonEmpty } from "@sgme/fp/src/predicates";
import { isDefined, isNonEmpty } from "@sgme/fp";



export type FieldMatcher = (value: string | undefined) => boolean

export const stringNotEmpty: FieldMatcher = (value) => isDefinedAndNonEmpty(value);

export const numberNotEmpty: FieldMatcher = (value) => isDefinedAndNonEmpty(value) && !Number.isNaN(Number(value));

export const FORM_DATE_FORMAT = "dd-MM-yyyy";
export const FORM_DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/;
export type FormDate = string

export const dateNotEmpty: FieldMatcher = (value) => isDefinedAndNonEmpty(value) && isMatch(value, FORM_DATE_FORMAT);

export const yesOrNoNotEmpty: FieldMatcher = (value) => isDefinedAndNonEmpty(value) && (
  value === "yes" || value === "no"
);

export const checkMatchers = <ObjectToCheck extends object>(allMatchers: Partial<Record<keyof ObjectToCheck, FieldMatcher>>, objectToCheck: ObjectToCheck) => {
  const allFields = Object.keys(allMatchers) as (keyof ObjectToCheck)[];

  return allFields.every(field => allMatchers[field]?.(objectToCheck[field]));
};

export const hasError = (formErrors: Record<string, string | undefined>) => Object.keys(formErrors).some(field => {
  const value = formErrors[field];

  return isDefined(value) && isNonEmpty(value);
});