import { AuthorizationPrefillResponse } from "@credit-control-risk/common";
import { isDefined } from "@sgme/fp";
import { parseJSONDate } from "@/utils/date";



export type AuthorizationEditorState = {
  rootId: string | undefined
  authorizationId: string | undefined
  groupProductCode: string | undefined
  status: "ACTIVE" | "INACTIVE" | undefined

  isEditable: boolean

  form: AuthorizationFormData
  formErrors: Partial<Record<keyof AuthorizationFormData, string>>
}

export const FORM_DATE_FORMAT = "dd-MM-yyyy";
export const FORM_DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/;
export type FormDate = string

export type AuthorizationFormData = {
  validityStartDate: FormDate
  exposureMaturityDate: FormDate
  administrativeValidityDate: FormDate

  authorizationAmount: string
  authorizationAmountCurrency: string

  exposureDefaultType: AuthorizationExposureType

  protectionIndication: "yes" | "no" // blanco

  comments: string

  countryRegistration: string // not editable
}

export type AuthorizationExposureType =
  | "SIN"
  | "SIR"
  | "SAR"
  | "NSS"
  | "NSR"
  | "NSC"
  | "DER"
  | "DEF"
  | "DEC"

export const initAuthorizationEditorState = (): AuthorizationEditorState => (
  {
    rootId:           undefined,
    authorizationId:  undefined,
    groupProductCode: undefined,
    status:           undefined,

    isEditable: true,

    // TODO: the default values can be global, or depend on the safety type
    form:       initAuthorizationFormData(),
    formErrors: {}
  }
);

export const initAuthorizationFormData = (defaultValues?: AuthorizationPrefillResponse, defaultProductIndication?: "yes" | "no"): AuthorizationFormData => (
  {
    validityStartDate:           parseJSONDate(defaultValues?.validityStartDate) ?? "",
    exposureMaturityDate:        parseJSONDate(defaultValues?.exposureMaturityDate) ?? "",
    administrativeValidityDate:  parseJSONDate(defaultValues?.administrativeValidityDate) ?? "",
    authorizationAmount:         isDefined(defaultValues) && isDefined(defaultValues?.amount) ? String(defaultValues.amount) : "",
    authorizationAmountCurrency: defaultValues?.currency ?? "",
    exposureDefaultType:         "SIN",
    protectionIndication:        defaultProductIndication ?? "no", // blanco
    comments:                    "",
    countryRegistration:         defaultValues?.countryRegistration ?? "LU"
  }
);

