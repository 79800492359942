import { OptionPredicator } from "typia/lib/programmers/helpers/OptionPredicator";
import undefined = OptionPredicator.undefined;



export const toggleValueIn = <Value>(value: Value, currentValues: Value[], keepAtLeastOneValue: KeepAtLeastOnValue = KeepAtLeastOnValue.DISABLED): Value[] => {
  const valueIndex = currentValues.indexOf(value);

  if (valueIndex === -1) {
    return [ ...currentValues, value ];
  }

  if (currentValues.length === 1 && keepAtLeastOneValue === KeepAtLeastOnValue.ENABLED) {
    return currentValues;
  }

  const newValues = [ ...currentValues ];
  newValues.splice(valueIndex, 1);

  return newValues;
};

export enum KeepAtLeastOnValue {
  ENABLED,
  DISABLED
}

export const addValueOnceIn = <Value>(value: Value, currentValues: Value[], keepAtLeastOneValue: KeepAtLeastOnValue = KeepAtLeastOnValue.DISABLED): Value[] => {
  const valueIndex = currentValues.indexOf(value);

  if (valueIndex === -1) {
    return [ ...currentValues, value ];
  }

  return currentValues;
};

export const removeValueFrom = <Value>(value: Value, currentValues: Value[], keepAtLeastOneValue: KeepAtLeastOnValue = KeepAtLeastOnValue.DISABLED): Value[] => {
  const valueIndex = currentValues.indexOf(value);

  if (valueIndex !== -1) {
    const newValues = [ ...currentValues ];

    newValues.splice(valueIndex, 1);
  }

  return currentValues;
};

export const keepOnlyValuesFrom = <Value>(keeptValues: Value[], currentValues: Value[], keepAtLeastOneValue: KeepAtLeastOnValue = KeepAtLeastOnValue.DISABLED): Value[] => currentValues.filter(value => keeptValues.includes(value));

export const groupById = <Item>(list: Item[], getItemId: (item: Item) => string) => list.reduce(
  (result, item) => {
    result[getItemId(item)] = item;
    return result;
  },
  {} as Record<string, Item>
);

export const first = <Item>(array: Item[]) => array.length > 0 ? array[0] : undefined;
export const last = <Item>(array: Item[]) => array.length > 0 ? array[array.length - 1] : undefined;

export const mapAndMerge = <Item, Result>(allItems: Item[], mapper: (item: Item) => Result[]): Result[] => {
  const allResults: Result[] = [];

  // for best performance
  for (const item of allItems) {
    const allItemResults = mapper(item);

    for (const result of allItemResults) {
      allResults.push(result);
    }
  }

  return allResults;
};