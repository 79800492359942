import { MouseEvent, PropsWithChildren } from "react";
import classnames from "classnames";



export type ButtonProps = {
  type?: "submit" | "reset" | "button"

  size?: "small" | "regular" | "large" | "xlarge"
  color?: "primary" | "info" | "success" | "socgen" | "secondary" | "warning" | "danger" | "black"
  variant?: "default" | "outlined" | "discret" | "flat" | "link"
  icon?: "start" | "end"

  loading?: boolean
  disabled?: boolean

  onClick?: (event: MouseEvent<HTMLButtonElement>) => void

  className?: string
}


function Button(props: PropsWithChildren<ButtonProps>) {
  const {
    type = "button",
    size = "regular",
    color = "primary",
    variant = "default",
    icon,
    loading:  isLoading = false,
    disabled: isDisabled = false,
    onClick,
    className,
    children
  } = props;

  return (
    <button
      className={classnames(
        "btn",
        className,
        {
          "btn-sm": size === "small",
          "btn-lg": size === "large",
          "btn-xl": size === "xlarge",

          "btn-primary":   color === "primary" && variant === "default",
          "btn-info":      color === "info" && variant === "default",
          "btn-success":   color === "success" && variant === "default",
          "btn-socgen":    color === "socgen" && variant === "default",
          "btn-secondary": color === "secondary" && variant === "default",
          "btn-warning":   color === "warning" && variant === "default",
          "btn-danger":    color === "danger" && variant === "default",
          "btn-black":     color === "black" && variant === "default",

          "btn-outline-primary":   color === "primary" && variant === "outlined",
          "btn-outline-info":      color === "info" && variant === "outlined",
          "btn-outline-success":   color === "success" && variant === "outlined",
          "btn-outline-socgen":    color === "socgen" && variant === "outlined",
          "btn-outline-secondary": color === "secondary" && variant === "outlined",
          "btn-outline-warning":   color === "warning" && variant === "outlined",
          "btn-outline-danger":    color === "danger" && variant === "outlined",
          "btn-outline-black":     color === "black" && variant === "outlined",

          "btn-discret-primary":   color === "primary" && variant === "discret",
          "btn-discret-info":      color === "info" && variant === "discret",
          "btn-discret-success":   color === "success" && variant === "discret",
          "btn-discret-socgen":    color === "socgen" && variant === "discret",
          "btn-discret-secondary": color === "secondary" && variant === "discret",
          "btn-discret-warning":   color === "warning" && variant === "discret",
          "btn-discret-danger":    color === "danger" && variant === "discret",
          "btn-discret-black":     color === "black" && variant === "discret",

          "btn-flat-primary":   color === "primary" && variant === "flat",
          "btn-flat-info":      color === "info" && variant === "flat",
          "btn-flat-success":   color === "success" && variant === "flat",
          "btn-flat-socgen":    color === "socgen" && variant === "flat",
          "btn-flat-secondary": color === "secondary" && variant === "flat",
          "btn-flat-warning":   color === "warning" && variant === "flat",
          "btn-flat-danger":    color === "danger" && variant === "flat",
          "btn-flat-black":     color === "black" && variant === "flat",

          "btn-link": variant === "link",

          "btn-icon-start": icon === "start",
          "btn-icon-end":   icon === "end",
          "btp-icon-text":  icon !== undefined
        }
      )}
      disabled={isDisabled || isLoading}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      {
        isLoading && <span className="spinner blinker-sm me-2" role="status"/>
      }
      {children}
    </button>
  );
}


export default Button;