import { IntlProvider } from "@/core/i18n";

import Footer from "@/routes/_layout/Footer";
import Header from "@/routes/_layout/Header";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";



function AppLayout(): React.JSX.Element {
  return (
    <IntlProvider>
      <AppLayoutContainer className="bg-primary-alt">
        <Header/>

        <main>
          <Outlet/>
        </main>

        <Footer/>
      </AppLayoutContainer>
    </IntlProvider>
  );
}


export const AppLayoutContainer = styled.div`
  & {
    display: grid;
    height: 100%;
    grid-template-areas:
    'header'
    'main'
    'footer';
    grid-template-rows: auto 1fr auto;

    //background: #F5F5F5;
  }

  & > header {
    display: flex;
    justify-content: flex-end;
    grid-area: header;

    //background: #FFFFFF;
  }

  & > main {
    grid-area: main;
    overflow: hidden;
  }

  & > footer {
    grid-area: footer;

    //background: #FFFFFF;
  }
`;


export default AppLayout;