import styled from "@emotion/styled";
import classnames from "classnames";



export type ChevronProps = {
  isOpen: boolean
  className?: string
}


function Chevron(props: ChevronProps): React.JSX.Element {
  const { isOpen, className } = props;

  return (
    <StyledChevron className={classnames([ "icon", className ], { closed: !isOpen })}>expand_more</StyledChevron>
  );
}


export default Chevron;

const StyledChevron = styled.i`
  transition: all 0.3s ease;

  &.closed {
    transform: rotate(-90deg);
  }
`;