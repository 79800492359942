import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isDefined } from "@sgme/fp";
import Stepper from "@/components/Stepper";
import Summary from "@/routes/links/new-link/components/Summary";
import { PageFooter, PageHeader, PageMain, PageRoot } from "@/routes/_layout/page-styles";
import RootIDHeader from "@/components/RootIDHeader";
import { goToNextStep, goToPreviousStep, newLinkSlice, reset, setRootId } from "@/routes/links/new-link/store/slice";
import { AppState } from "@/store";
import AuthorizationSelector from "@/routes/links/new-link/components/AuthorizationSelector";
import ProductSelector from "@/routes/links/new-link/components/ProductSelector";
import SafetySelector from "@/routes/links/new-link/components/SafetySelector";
import LinkCreationResult from "@/routes/links/new-link/components/LinkCreationResult";
import { saveLinks } from "@/routes/links/new-link/store/thunks";
import { NewLinkState, Step } from "@/routes/links/new-link/store/state";
import { canGoToNextStep, canGoToPreviousStep } from "@/routes/links/new-link/store/api";
import { useGetProducts } from "@/store/api/webapi";
import Button from "@/components/sg-ui/form/Button";
import ScrollView from "@/components/sg-ui/layout/ScrollView";



function NewLinkRoute(): React.JSX.Element | null {
  const { rootId } = useParams() as {
    rootId: string
  };

  const dispatch = useDispatch();

  const [ isSaving, setSaving ] = useState(false);

  const { data: allAssets } = useGetProducts({
    rootId,
    scope:    "ASSET",
    unlinked: true
  });

  const data = useSelector((appState: AppState) => {
    const state = appState.ui[newLinkSlice.name] as NewLinkState;

    if (!isDefined(state.rootId)) {
      return undefined;
    }

    return {
      currentStep:     state.currentStep,
      lastActiveStep:  state.lastActiveStep,
      canHaveSafeties: state.canHaveSafeties,
      canGoPrevious:   canGoToPreviousStep(state),
      canGoNext:       canGoToNextStep(state)
    };
  });

  useEffect(() => {
    dispatch(setRootId(rootId));

    return () => {
      dispatch(reset());
    };
  }, [ dispatch, rootId ]);

  if (!isDefined(data)) {
    return null;
  }

  const {
    currentStep,
    lastActiveStep,
    canHaveSafeties,
    canGoPrevious,
    canGoNext
  } = data;

  const next = () => dispatch(goToNextStep());
  const previous = () => dispatch(goToPreviousStep());

  const onSaveLinks = () => {
    const allTrades = allAssets?.trades;

    if (!isDefined(allTrades)) {
      return;
    }

    setSaving(true);
    dispatch(saveLinks(allTrades)); // TODO: fix TS error
  };

  return (
    <PageRoot>
      <PageHeader>
        <div className="hstack gap-5">
          <Link to={`/links/${rootId}`}>
            <button type="button" className="btn btn-flat-secondary btn-icon-start ">
              <em className="icon">arrow_back</em>
              <FormattedMessage id="routes.new-link.navigation.Back"/>
            </button>
          </Link>

          <h1>
            <FormattedMessage id="routes.new-link.title"/>
          </h1>

          <RootIDHeader rootId={rootId} clientName="Dummy client" isRootIdHidden/>
        </div>
      </PageHeader>

      <PageMain>
        <div className="row h-100">
          <div className="col-2">
            <div style={{ height: 285 }}>
              <Stepper steps={STEPS} currentStepIndex={currentStep}
                       lastActiveStepIndex={lastActiveStep} vertical/>
            </div>
          </div>

          <ScrollView className="col-7 h-100 pt-1">
            <ProductSelector/>

            {
              (
                lastActiveStep >= Step.AUTHORIZATION_SELECTION
              ) && (
                <AuthorizationSelector/>
              )
            }

            {
              (
                canHaveSafeties && lastActiveStep >= Step.SAFETY_SELECTION
              ) && (
                <SafetySelector/>
              )
            }

            {
              (
                lastActiveStep === Step.LINK_CREATION
              ) && (
                <LinkCreationResult/>
              )
            }
          </ScrollView>

          <div className="col-3">
            <Summary/>
          </div>
        </div>
      </PageMain>

      {
        currentStep !== Step.LINK_CREATION && (
          <PageFooter className="border-top">
            <div className="row">
              <div className="col-2"/>

              <div className="col-7">
                <div className="d-flex">
                  <Button className="btn btn-outline-primary btn-icon-start" disabled={!canGoPrevious}
                          onClick={previous}>
                    <em className="icon">arrow_back</em>
                    <FormattedMessage id="routes.new-link.navigation.Previous"/>
                  </Button>

                  <div className="flex-fill"/>

                  {
                    (
                      currentStep === Step.AUTHORIZATION_SELECTION && !canHaveSafeties
                    ) || currentStep === Step.SAFETY_SELECTION
                      ? <Button
                        type="button"
                        color="primary"
                        icon="end"
                        loading={isSaving}
                        disabled={!canGoNext}
                        onClick={onSaveLinks}
                      >
                        <FormattedMessage id="routes.new-link.navigation.Create"/>
                        <em className="icon">arrow_forward</em>
                      </Button>
                      : <Button
                        type="button"
                        color="info"
                        icon="end"
                        disabled={!canGoNext}
                        onClick={next}
                      >
                        <FormattedMessage id="routes.new-link.navigation.Next"/>
                        <em className="icon">arrow_forward</em>
                      </Button>
                  }

                </div>
              </div>

              <div className="col-3"/>
            </div>
          </PageFooter>
        )
      }
    </PageRoot>
  );
}


const STEPS = [
  {
    labelId: "routes.new-link.steppers.selectProduct"
  },
  {
    labelId: "routes.new-link.steppers.linkToAuthorization"
  },
  {
    labelId: "routes.new-link.steppers.LinkToSafety"
  },
  {
    labelId: "routes.new-link.steppers.ProductLinkCreated"
  }
];

export default NewLinkRoute;