import Step from "@/components/Stepper/Step";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";



export type StepperProps = {
  steps: StepperStep[]
  currentStepIndex: number
  lastActiveStepIndex: number
  vertical?: boolean
}

export type StepperStep = {
  labelId: string
}


function Stepper(props: StepperProps): React.JSX.Element {
  const { steps, currentStepIndex, lastActiveStepIndex, vertical = false } = props;

  return (
    <ul className={classnames("stepper", { "stepper-vertical": vertical })}>
      {
        steps.map(({ labelId }, stepIndex) => (
          <Step key={labelId} status={getStepStatus(stepIndex, currentStepIndex, lastActiveStepIndex)}>
            <FormattedMessage id={labelId}/>
          </Step>
        ))
      }
    </ul>
  );
}


const getStepStatus = (stepIndex: number, currentStepIndex: number, lastActiveStepIndex: number): "checked" | "current" | "inactive" | "idle" => {
  if (currentStepIndex === stepIndex) {
    return "current";
  }

  if (stepIndex < currentStepIndex) {
    return "checked";
  }

  if (stepIndex <= lastActiveStepIndex) {
    return "idle";
  }

  return "inactive";
};

export default Stepper;