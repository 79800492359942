import styled from "@emotion/styled";
import React, { ChangeEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Input, InputGroup } from "reactstrap";

import { ALL_CATEGORIES } from "@/routes/links/shared";
import CategoryFilter from "@/components/CategoryFilter";
import { CategorySelectionMode } from "@/components/CategoryFilter/types";
import { ALL_STATUS_CATEGORIES } from "@/routes/shared";



export type LinkFiltersProps = {
  rootId: string
  query: string
  onQueryChange: (value: string) => void
  selectedLinkTypes: string[]
  onSelectLinkTypes: (linkTypeIds: string[]) => void
  status: string[]
  onStatusChange: (value: string[]) => void
}


function LinkFilters(props: LinkFiltersProps): React.JSX.Element {
  const { rootId, query, onQueryChange, selectedLinkTypes, onSelectLinkTypes, status, onStatusChange } = props;

  const intl = useIntl();

  const placeholder = intl.formatMessage({ id: "routes.links.filters.search.placeholder" });

  const updateQuery = (event: ChangeEvent<HTMLInputElement>) => {
    onQueryChange(event.target.value);
  };

  return (
    <div className="row g-3 mb-3">
      {/*search*/}
      <div className="col-md-2">
        <InputGroup>
          <div className="input-icon-start">
            <i className="icon">search</i>
          </div>

          <Input
            type="search"
            placeholder={placeholder}
            value={query}
            onChange={updateQuery}
          />
        </InputGroup>
      </div>

      <div className="col-md-8 d-flex align-items-center gap-3">
        <CategoryFilter
          labelId="routes.links.filters.label"
          categories={ALL_CATEGORIES}
          selectedCategoryIds={selectedLinkTypes}
          mode={CategorySelectionMode.EMPTY_NOT_ALLOWED}
          onChange={onSelectLinkTypes}
        />

        <CategoryFilter
          labelId="routes.shared.status.label"
          categories={ALL_STATUS_CATEGORIES}
          selectedCategoryIds={status}
          mode={CategorySelectionMode.ONLY_ONE_ALLOWED}
          onChange={onStatusChange}
        />
      </div>


      <div className="col-md-2 d-flex justify-content-end">
        <Link to={`/links/${rootId}/new`}>
          <Button color="primary">
            <i className="icon">add</i>
            <FormattedMessage id="routes.links.filters.new_link"/>
          </Button>
        </Link>
      </div>
    </div>
  );
}


// override the SG Bootstrap 5 styles to avoid after a toggle
// to not see the change until the user click elsewhere to change
// the focused element
const StyledButton = styled(Button)`
  &:focus {
    color: #000;
    background: transparent;
    box-shadow: inset 0 0 0 1px #000 !important;
  }

  &.active:focus {
    color: #FFF;
    background: #000;
  }
`;

export default LinkFilters;


