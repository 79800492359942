import { FormattedMessage } from "react-intl";

import SafetyFormGroup from "@/components/SafetyEditor/SafetyInternalEditor/components/SafetyFormGroup";
import AssetValuationFormGroup from "@/components/SafetyEditor/SafetyInternalEditor/components/AssetValuationFormGroup";
import PropertyFormGroup from "@/components/SafetyEditor/SafetyInternalEditor/components/PropertyFormGroup";
import AssetFormGroup from "@/components/SafetyEditor/SafetyInternalEditor/components/AssetFormGroup";
import VStack from "@/components/sg-ui/layout/VStack";
import Box from "@/components/sg-ui/layout/Box";
import ScrollView from "@/components/sg-ui/layout/ScrollView";
import CollateralFormGroup from "@/components/SafetyEditor/SafetyInternalEditor/components/CollateralFormGroup";
import { useSafetyType } from "@/components/SafetyEditor/store/hooks";



export interface SafetyGroupTypeSelectorProps {
  rootId: string;
  excludedAssetSourceProductIds?: string[];
}


function SafetyGroupTypeSelector(props: SafetyGroupTypeSelectorProps) {
  const { rootId, excludedAssetSourceProductIds } = props;

  const {
    isCollateralAsset,
    isCollateralMortgage,
    isCollateral
  } = useSafetyType();

  return (
    <VStack fullHeight>
      <Box>
        <ul className="nav nav-pills nav-hover bg-white border-bottom mx-3">
          <li className="nav-item">
                <span className="nav-link active">
                  <FormattedMessage id="Safety"/>
                </span>
          </li>

          {
            isCollateral && <>
                  <li className="nav-item">
                    <span className="nav-link">
                      <FormattedMessage id="Collateral"/>
                    </span>
                  </li>

                  <li className="nav-item">
                    <span className="nav-link">
                      <FormattedMessage id="Safety valuation"/>
                    </span>
                  </li>
              </>
          }

          {
            isCollateralMortgage && (
              <li className="nav-item">
                <span className="nav-link">
                  <FormattedMessage id="Property"/>
                </span>
              </li>
            )
          }

          {
            isCollateralAsset && (
              <li className="nav-item">
                <span className="nav-link">
                  <FormattedMessage id="Asset"/>
                </span>
              </li>
            )
          }
        </ul>
      </Box>

      <ScrollView padding="3" flex="expand">
        <SafetyFormGroup/>

        {
          isCollateral && <>
                <CollateralFormGroup/>
                <AssetValuationFormGroup/>
            </>
        }

        {
          isCollateralMortgage && (
            <PropertyFormGroup/>
          )
        }

        {
          isCollateralAsset && (
            <AssetFormGroup rootId={rootId} excludedAssetSourceProductIds={excludedAssetSourceProductIds}/>
          )
        }
      </ScrollView>
    </VStack>
  );
}


export default SafetyGroupTypeSelector;