import { PropsWithChildren } from "react";



export type SectionProps = {
  labelId: string
}

export const Section = (props: PropsWithChildren<SectionProps>) => {
  const { children } = props;

  return (
    <div>
      {children}
    </div>
  );
};