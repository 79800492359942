import { Switcher } from "@/components/sg-ui/form/Switcher";
import { getTheme, setTheme, Theme } from "@/core/theme";
import { useCallback, useState } from "react";



function ThemeSwitcher(): React.JSX.Element {
  const [ currentTheme, setCurrentTheme ] = useState(getTheme);

  const changeTheme = useCallback((checked: boolean) => {
    const theme: Theme = checked ? "DARK" : "LIGHT";
    setTheme(theme);
    setCurrentTheme(theme);
  }, []);

  return (
    <Switcher id="dark" label="Dark" checked={currentTheme === "DARK"} onChange={changeTheme}/>
  );
}


export default ThemeSwitcher;