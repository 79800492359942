import MainBadge from "@/components/RootIDHeader/MainBadge";
import MainData from "@/components/RootIDHeader/MainData";
import RootIDButton from "@/components/RootIDHeader/RootIDButton";
import { useCounterparty } from "@/store/api/webapi";



export type RootIDHeaderProps = {
  rootId: string
  isRootIdHidden?: boolean
  clientName: string
}


function RootIDHeader(props: RootIDHeaderProps): React.JSX.Element {
  const { rootId, isRootIdHidden = false, clientName } = props;

  const { data: counterparty } = useCounterparty({ rootId });

  return (
    <div className="d-flex gap-5 mb-3">
      <MainData titleLocaleId="components.RootIDHeader.rootID" value={rootId}/>
      <MainData titleLocaleId="components.RootIDHeader.clientName" value={counterparty?.name ?? ""}/>
      <MainData titleLocaleId="components.RootIDHeader.RCT" value={counterparty?.rctId ?? ""}/>
      <MainData titleLocaleId="components.RootIDHeader.SLM" value={counterparty?.subBusinessLine ?? ""}/>
      <MainData titleLocaleId="components.RootIDHeader.CRM" value={counterparty?.mainAccountManager ?? ""}/>

      <MainBadge titleLocaleId="components.RootIDHeader.status"
                 value={counterparty?.statusCode === "1" ? "Active" : "Inactive"} color={"success"}/>

      <MainData titleLocaleId="components.RootIDHeader.legalEntityCode" value={counterparty?.legalEntityCode ?? ""}/>
      <MainData titleLocaleId="components.RootIDHeader.legalEntityLabel"
                value={counterparty?.legalEntityLabel ?? ""}/>

      {
        !isRootIdHidden && (
          <>
            <div className="mx-auto"/>

            <RootIDButton rootId={rootId}/>
          </>
        )
      }
    </div>
  );
}


export default RootIDHeader;